/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: errorAlerts.js
Description: This file has the model,functions  of errorAlerts object used in the pondlogs customer site
*/
const defaultErrorCodes = {
  NO_COMMUNICATION: "NO_COMMUNICATION",
  LOW_BATTERY: "LOW_BATTERY",
  LOW_DO: "LOW_DO",
  CRITICAL_DO: "CRITICAL_DO",
  NO_SCHEDULES: "NO_SCHEDULES",
  SCHEDULE_NOT_UPDATED: "SCHEDULE_NOT_UPDATED",
  DO_calib_error_code_1: "CALIBRATION_ERROR",
  DO_calib_error_code_2: "CALIBRATION_ERROR",
  NO_DO_SENSOR_COMMUNICATION: "NO_DO_SENSOR_COMMUNICATION",
  LOW_TEMPERATURE: "LOW_TEMPERATURE",
  HIGH_TEMPERATURE: "HIGH_TEMPERATURE",
  DO_SENSOR_DISCONNECTED: "DO_SENSOR_DISCONNECTED",
  DO_SENSOR_NO_RESPONSE: "DO_SENSOR_NO_RESPONSE",
  DO_INVALID_READING: "DO_INVALID_READING",
  SOLAR_DISCONNECTED: "SOLAR_DISCONNECTED",
  NO_INTAKE_RESPONSE: "NO_INTAKE_RESPONSE"
};
const errorAlertsPriorityToUser = {
  NO_COMMUNICATION: 1,
  NO_DO_SENSOR_COMMUNICATION: 2,
  CALIBRATION_ERROR: 3,
  CRITICAL_DO: 4,
  LOW_DO: 5,
  NO_FEED_OR_FEED_STUCK: 6,
  MOTOR_RELAY_ISSUE: 7,
  SCHEDULE_NOT_UPDATED: 8,
  NO_SCHEDULES: 9,
  LOW_TEMPERATURE: 15,
  HIGH_TEMPERATURE: 16,
  NO_INTAKE_RESPONSE: 10,
  DO_SENSOR_DISCONNECTED: 11,
  DO_SENSOR_NO_RESPONSE: 12,
  DO_INVALID_READING: 13,
  SOLAR_DISCONNECTED: 14
};
function pgErrorMessage(errorCode) {
  const pgErrorCodes = {
    0: "SENSOR_DISCONNECTED",
    1: "BATTERY_DISCONNECTED",
    2: "MOTOR_DISCONNECTED",
    3: "MOTOR_BLOCKED",
    4: "ELECTROLYTE_CONTAMINATION",
    5: "CALIBRATION_FAILED",
    6: "INVALID_TEMPERATURE"
  };
  const errors = [];
  const errorCodeLength = Math.ceil(Math.log10(errorCode));
  for (let i = 0; i < errorCodeLength; i++) {
    if (errorCode & (1 << i)) {
      errors.push(pgErrorCodes[i]);
    }
  }
  return errors.length > 1 ? errors : errors[0];
}
function pmErrorMessage(errorCode) {
  const errorMessages = {
    1: "NO_FEED_OR_FEED_STUCK",
    3: "SENSOR_FAILURE",
    6: "SENSOR_DISCONNECTED",
    7: "MOTOR_RELAY_ISSUE"
  };
  errorMessages[8] = [errorMessages[3], errorMessages[7]];
  errorMessages[9] = [errorMessages[6], errorMessages[7]];
  errorMessages[10] = [errorMessages[3], errorMessages[7]];
  errorMessages[11] = [errorMessages[3], errorMessages[6]];
  errorMessages[12] = [errorMessages[3], errorMessages[6], errorMessages[7]];
  return errorMessages[errorCode] || "UN_IDENTIFIED_ERROR";
}
function getErrors(errorCode, deviceType) {
  if (defaultErrorCodes[errorCode]) {
    return defaultErrorCodes[errorCode];
  }
  if (deviceType === "POND_MOTHER") {
    const errors = pmErrorMessage(errorCode);
    if (!Array.isArray(errors)) return errors;
    let maxPriorityErrorCode = 15;
    return errors.reduce((acc, curr) => {
      if (
        errorAlertsPriorityToUser[curr] &&
        maxPriorityErrorCode > errorAlertsPriorityToUser[curr]
      ) {
        maxPriorityErrorCode = errorAlertsPriorityToUser[curr];
        acc = curr;
      }
      return acc;
    }, "NO_ERROR");
  }
  if (deviceType === "POND_GUARD") {
    const errors = pgErrorMessage(errorCode);
    if (!Array.isArray(errors)) return errors;
    let maxPriorityErrorCode = 17;
    return errors.reduce((acc, curr) => {
      if (
        errorAlertsPriorityToUser[curr] &&
        maxPriorityErrorCode > errorAlertsPriorityToUser[curr]
      ) {
        maxPriorityErrorCode = errorAlertsPriorityToUser[curr];
        acc = curr;
      }
      return acc;
    }, "NO_ERROR");
  }
}
export function reduceAllAlertsToValidAlerts(
  arrErrorAlerts,
  error_code_key_name,
  deviceType
) {
  return arrErrorAlerts.reduce((acc, error) => {
    const errorCode = getErrors(error[error_code_key_name], deviceType);
    if (["LOW_BATTERY"].indexOf(errorCode) > -1 || errorCode === undefined) {
      return acc;
    }
    const errorAlert = {};
    errorAlert.error_code = errorCode;
    if (error.utc_start_date) {
      errorAlert.utc_start_date = error.utc_start_date || "";
      errorAlert.utc_end_date = error.utc_end_date || "";
      errorAlert.utc_updated_date = error.utc_updated_date || "";
      errorAlert.duration = error.duration || 0;
      errorAlert.resolved_by = error.resolved_by;
      errorAlert.resolved_date = error.resolved_date;
      errorAlert.resolved_notes = error.resolved_notes;
      errorAlert.error_id = error._id;
      errorAlert.raw_device_type = error.raw_device_type;
    }
    if (!["NO_SCHEDULES"].includes(errorCode)) {
      errorAlert.date = error.start_date;
    }
    if (["NO_FEED_OR_FEED_STUCK", "MOTOR_RELAY_ISSUE"].includes(errorCode)) {
      errorAlert.date = error.updated_date;
    }
    errorAlert.user_priority = errorAlertsPriorityToUser[errorCode];
    acc.push(errorAlert);
    return acc;
  }, []);
}
export function errorAlertsFactory(pond, deviceAlerts, device) {
  const deviceCode = device.device_code;
  const deviceTitle = device.device_title;
  const deviceType = device.device_type;
  const arrNormalizedAlerts = reduceAllAlertsToValidAlerts(
    deviceAlerts,
    "error_code",
    deviceType
  );
  const extendAlert = alert => {
    return [
      { prop: "pond_name", value: pond.title },
      { prop: "pond_id", value: pond._id },
      { prop: "device_type", value: deviceType },
      { prop: "device_code", value: deviceCode },
      { prop: "device_title", value: deviceTitle },
      {
        prop: "sort_device_title",
        value: [pond.title, deviceType, deviceTitle].join("|")
      },
      {
        prop: "sort_device_code",
        value: [pond.title, deviceType, deviceCode].join("|")
      },
      { prop: "device_id", value: device.device_id },
      {
        prop: "battery_voltage",
        value: device.battery_voltage
      }
    ].reduce((errorAlertObj, currItem) => {
      errorAlertObj[currItem.prop] = currItem.value;
      return errorAlertObj;
    }, alert);
  };
  return arrNormalizedAlerts.map(eachAlert => {
    return extendAlert(eachAlert);
  });
}
export function errorHistoryAlertsFactory(pond, device) {
  const deviceCode = device.device_code;
  const deviceTitle = device.device_title;
  const deviceType = device.device_type;
  const arrNormalizedAlerts = reduceAllHistoryAlertsToValidAlerts(
    pond,
    "error_code",
    deviceType
  );
  const extendAlert = alert => {
    return [
      { prop: "pond_name", value: pond.pond_title },
      { prop: "pond_id", value: pond.pond_id },
      { prop: "device_type", value: deviceType },
      { prop: "device_code", value: deviceCode },
      { prop: "device_title", value: deviceTitle },
      {
        prop: "sort_device_title",
        value: [pond.pond_title, deviceType, deviceTitle].join("|")
      },
      {
        prop: "sort_device_code",
        value: [pond.pond_title, deviceType, deviceCode].join("|")
      },
      { prop: "device_id", value: device.device_id },
      {
        prop: "battery_voltage",
        value: device.battery_voltage
      }
    ].reduce((errorAlertObj, currItem) => {
      errorAlertObj[currItem.prop] = currItem.value;
      return errorAlertObj;
    }, alert);
  };
  return arrNormalizedAlerts.map(eachAlert => {
    return extendAlert(eachAlert);
  });
}
export function reduceAllHistoryAlertsToValidAlerts(
  arrErrorAlerts,
  error_code_key_name,
  deviceType
) {
  // return arrErrorAlerts.reduce((acc, error) => {
  const errorCode = getErrors(arrErrorAlerts[error_code_key_name], deviceType);
  if (["LOW_BATTERY"].indexOf(errorCode) > -1 || errorCode === undefined) {
    return [];
  }
  const errorAlert = {};
  errorAlert.error_code = errorCode;
  if (arrErrorAlerts.utc_start_date) {
    errorAlert.utc_start_date = arrErrorAlerts.utc_start_date || "";
    errorAlert.utc_end_date = arrErrorAlerts.utc_end_date || "";
    errorAlert.utc_updated_date = arrErrorAlerts.utc_updated_date || "";
    errorAlert.duration = arrErrorAlerts.duration || 0;
    errorAlert.resolved_by = arrErrorAlerts.resolved_by;
    errorAlert.resolved_date = arrErrorAlerts.resolved_date;
    errorAlert.resolved_notes = arrErrorAlerts.resolved_notes;
    errorAlert.error_id = arrErrorAlerts._id;
    errorAlert.raw_device_type = arrErrorAlerts.raw_device_type;
  }
  if (!["NO_SCHEDULES"].includes(errorCode)) {
    errorAlert.date = arrErrorAlerts.start_date;
  }
  if (["NO_FEED_OR_FEED_STUCK", "MOTOR_RELAY_ISSUE"].includes(errorCode)) {
    errorAlert.date = arrErrorAlerts.updated_date;
  }
  errorAlert.user_priority = errorAlertsPriorityToUser[errorCode];
  // acc.push(errorAlert);
  return [errorAlert];
  // }, []);
}
export default {
  reduceAllAlertsToValidAlerts,
  errorAlertsFactory,
  reduceAllHistoryAlertsToValidAlerts,
  errorHistoryAlertsFactory
};
