<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erSelect.vue
Description: This file contains the select component which is the extension of the element ui select component
-->
<template>
  <el-select
    :class="['er-select', $attrs.styleType]"
    v-bind="$attrs"
    :popper-class="popperClass"
    v-on="inputListeners"
    ref="erSelect"
  >
    <template slot="prefix">
      <slot name="prefix"></slot>
    </template>
    <slot name="option-prefix">
      <li
        v-if="showSelectAll"
        :class="`el-select-dropdown__item select-all ${hoverSelectAll} ${clickedSelectAll}`"
        @click="handleSelectAll"
        @mouseenter="hoverSelectAll = 'hover'"
        @mouseleave="hoverSelectAll = ''"
      >
        <span class="item-name">{{ $t("select_all") }}</span>
      </li>
    </slot>
    <slot></slot>
  </el-select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    showSelectAll: {
      default: false
    }
  },
  data: function () {
    return {
      isIndeterminate: false,
      clickedSelectAll: "",
      hoverSelectAll: "",
      selectAll: false
    };
  },
  computed: {
    popperClass: function () {
      return this.$attrs["popper-class"] && this.$attrs["popper-class"] === ""
        ? "er-select-dropdown"
        : "er-select-dropdown " + this.$attrs["popper-class"];
    },
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.showSelectAll) {
        if (this.$refs.erSelect.options.length > 0) {
          this.selectAll =
            this.$attrs.value.length === this.$refs.erSelect.options.length;
          if (this.selectAll) {
            this.clickedSelectAll = "selected";
          } else {
            this.clickedSelectAll = "";
          }
        } else {
          this.selectAll = false;
          this.clickedSelectAll = "";
        }
      }
    });
  },
  methods: {
    handleSelectAll() {
      this.selectAll = !this.selectAll;
      let selectedValues = [];
      if (this.selectAll) {
        this.clickedSelectAll = "selected";
        selectedValues = this.$refs.erSelect.options.map((x) => x.value);
      } else {
        this.clickedSelectAll = "";
        selectedValues = [];
      }
      this.$emit("input", selectedValues);
      this.$emit("change", selectedValues);
      this.$emit("selected-all", selectedValues);
    }
  }
};
</script>

<style lang="scss">
.er-select-dropdown {
  &.is-multiple .el-select-dropdown__item.selected,
  .el-select-dropdown__item.selected {
    color: $selected-color;
    text-transform: capitalize;
  }

  .el-select-dropdown__item {
    @include select-drop-down-item-style;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    height: 25px;
    // text-transform: capitalize;
    &.select-all:not(:hover) {
      // color: #1966a1;
      top: 0px;
      position: sticky;
      background: white;
      z-index: 1;
    }
    padding: 0 15px;
  }
}
.er-select {
  // width: 100%;
  .el-input__inner {
    width: 100%;
    // text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-input .el-select__caret {
    color: #606266;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    font-weight: bolder;
  }
  &.no-border {
    .el-input__inner {
      border: none;
    }
    .el-input__suffix-inner i {
      color: $primary-color;
    }
  }
}
</style>
