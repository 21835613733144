/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: labTests.js
Description: This file contains all the vuex store functions used in relation to Lab Tests
*/
import ResourceManagmentService from "@/services/ResourceManagementService"; // Include the labtest, resources operation

const labTestStore = {
  namespaced: true,
  state: {
    date: "",
    labTests: [],
    locationLabTests: []
  },
  getters: {
    getDate: function(state) {
      return state.date;
    },
    getLabTests: function(state) {
      return state.labTests;
    },
    getLocationLabTests: function(state) {
      return state.locationLabTests;
    }
  },
  mutations: {
    SET_DATE(state, date) {
      state.date = date;
    },
    SET_LAB_TESTS: function(state, tests) {
      state.labTests = tests;
    },
    SET_LOCATION_LAB_TESTS: function(state, tests) {
      state.locationLabTests = tests;
    }
  },
  actions: {
    changeDate({ commit }, date) {
      commit("SET_DATE", date);
    },
    fetchAllLabTests: async (context, params = {}) => {
      try {
        const response = await ResourceManagmentService.fetchLabTests(params);
        context.commit("SET_LAB_TESTS", response.data);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    fetchLabTestsByLocation: async (context, params = {}) => {
      try {
        const response = await ResourceManagmentService.fetchLabTestsByLocation(
          params
        );
        context.commit("SET_LOCATION_LAB_TESTS", response.data.lab_tests);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    addLabTests: async (context, labTests) => {
      try {
        const response = await ResourceManagmentService.addLabTestsToPond(
          labTests
        );
        console.log(response);
        return response.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    deleteLabTest: async (context, record_id) => {
      console.log(record_id);
      try {
        const response = await ResourceManagmentService.deleteLabTestFromPond(
          record_id
        );
        return response.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }
};

export default labTestStore;
