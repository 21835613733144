/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to ShrimpGrowth i.e. ABW, ..etc
*/
import { $axios } from "../config/axios";

export default {
  fetchABW(params) {
    return $axios.get("/ponds/ABW-summary-with-multiple-ponds", { params });
  },
  fetchInactivePondABW(params) {
    return $axios.get("/ponds/inactive/latest-ABW-values", { params });
  },
  fetchLatestNABWValues(params) {
    return $axios.get("/ponds/latest-ABW-values", { params });
  },
  fetchFeedConsumption(params) {
    return $axios.get("/ponds/feed-consumption-summary", { params });
  },
  fetchSelectedPondLatestNABWValue(pondId, params) {
    return $axios.get(`/ponds/${pondId}/latest-ABW-values`, { params });
  },
  fetchFeedConsumptionSummary(pondId, params) {
    return $axios.get(`/ponds/${pondId}/feed-consumption-summary`, { params });
  },

  fetchAbwVsBiomassGraphData(pondId, params) {
    return $axios.get(`/ponds/${pondId}/growth-vs-biomass`, { params });
  },

  fetchHourlyFeedGrowthStat(params) {
    return $axios.get("/ponds/hourly-feed-growth", { params });
  },
  fetchAbwAndBiomass(params) {
    return $axios.get("/ponds/abw-vs-biomass", { params });
  },
  fetchWeightGrowth(params) {
    return $axios.get("/ponds/weight-growth", { params });
  },
  fetchAverageWeightGrowthLastNWeeks(params) {
    return $axios.get("/ponds/weekly-weight-growth", { params });
  },
  fetchFeedConsumptionGrowth(params) {
    return $axios.get("/ponds/feed-consumption-growth", { params });
  }
};
