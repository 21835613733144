/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: resource.js
Description: This file contains all the vuex store functions used in resources page
*/
import ResourceManagementService from "../../services/ResourceManagementService";
import { alphaNumericComparator } from "@/utils/commonUtils";
import { cloneDeep } from "lodash";
// Include the labtest, resources operation

const state = {
  resources: [],
  vendors: [],
  labs: [],
  selectedResourceId: ""
};
const getters = {
  getArrResources: function(state) {
    return state.resources;
  },
  getFeedResources: function(state) {
    const feedresource = cloneDeep(state.resources).reduce((acc, curr) => {
      if (curr.type === "FEED") {
        acc.push(curr);
      }
      return acc;
    }, []);

    return feedresource.sort((a, b) => alphaNumericComparator(a.name, b.name));
  },
  getMedicineResources: function(state) {
    const medicineresource = cloneDeep(state.resources).reduce((acc, curr) => {
      if (curr.type !== "FEED") {
        acc.push(curr);
      }
      return acc;
    }, []);

    return medicineresource.sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
  },
  getObjResources: function(state) {
    const resourcesObj = {};
    cloneDeep(state.resources).forEach(resource => {
      resourcesObj[resource._id] = resource;
    });
    return resourcesObj;
  },
  getFeedObjResources: function(state) {
    const resourcesObj = {};
    const feedresources = cloneDeep(state.resources).sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
    feedresources.forEach(resource => {
      if (resource.type === "FEED") {
        resourcesObj[resource._id] = resource;
      }
    });
    return resourcesObj;
  },
  getMedicineObjResources: function(state) {
    const resourcesObj = {};
    const medicalresources = cloneDeep(state.resources).sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
    medicalresources.forEach(resource => {
      if (resource.type !== "FEED") {
        resourcesObj[resource._id] = resource;
      }
    });
    return resourcesObj;
  },

  getArrVendors: function(state) {
    return cloneDeep(state.vendors).sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
  },
  getArrLabs: function(state) {
    return state.labs;
  },
  getObjVendors: function(state) {
    const vendorsObj = {};
    const vendorList = cloneDeep(state.vendors).sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
    vendorList.forEach(vendor => {
      vendorsObj[vendor._id] = vendor;
    });
    return vendorsObj;
  },
  resourcesGroupByResourceType: function(state) {
    const resourceTypeResources = {};
    state.resources.forEach(resource => {
      if (resourceTypeResources[resource.type] === undefined) {
        resourceTypeResources[resource.type] = [];
      }
      resourceTypeResources[resource.type].push(resource);
    });
    return resourceTypeResources;
  },
  getSelectedResourceId: function(state) {
    return state.selectedResourceId;
  }
};
const mutations = {
  SET_RESOURCES: function(state, resources) {
    state.resources = resources;
  },
  SET_VENDORS: function(state, vendors) {
    state.vendors = vendors;
  },
  SET_LABS: function(state, labs) {
    state.labs = labs;
  },

  SET_SELECTED_RESOURCE_ID: function(state, resourceId) {
    state.selectedResourceId = resourceId;
  }
};
const actions = {
  fetchAllLabs: async (context, params = {}) => {
    const response = await ResourceManagementService.fetchAllLabs(params);
    context.commit("SET_LABS", response.data.labs || []);
    return response.data;
  },
  deleteLab: async (context, labId) => {
    const response = await ResourceManagementService.deleteLab(labId);
    return response.data;
  },

  // resources actions
  fetchAllResources: async (context, params = {}) => {
    const response = await ResourceManagementService.fetchResources(params);
    context.commit("SET_RESOURCES", response.data.resources);
    return response.data;
  },

  fetchAResource: async (context, resourceId) => {
    const response = await ResourceManagementService.fetchAResource(resourceId);
    return response.data;
  },
  fetchResourceQuantityObj(context, params) {
    console.log(params.from_date, params.to_date);
  },
  changeResourceSelected(context, resourceId) {
    context.commit("SET_SELECTED_RESOURCE_ID", resourceId);
  },
  addResources: async (context, resource) => {
    const response = await ResourceManagementService.addResources(resource);
    return response.data;
  },
  updateResources: async (context, resource) => {
    const response = await ResourceManagementService.updateResources(resource);
    return response.data;
  },
  deleteResource: async (context, resourceIds) => {
    const response = await ResourceManagementService.deleteResource(
      resourceIds
    );
    return response.data;
  },
  // resource vendor actions
  fetchAllVendors: async (context, params) => {
    const response = await ResourceManagementService.fetchAllVendors(params);
    context.commit("SET_VENDORS", response.data.resource_vendors);
    return response.data;
  },
  fetchAVendor: async (context, vendorId) => {
    const response = await ResourceManagementService.fetchAVendor(vendorId);
    return response.data;
  },
  addVendor: async (context, vendor) => {
    const response = await ResourceManagementService.addVendor(vendor);

    return response.data;
  },
  AddLab: async (context, lab) => {
    const response = await ResourceManagementService.AddLab(lab);

    return response.data;
  },
  updateLab: async (context, lab) => {
    const response = await ResourceManagementService.updateLab(lab);
    return response.data;
  },

  updateVendor: async (context, vendor) => {
    const response = await ResourceManagementService.updateVendor(vendor);

    return response.data;
  },
  deleteVendor: async (context, vendorIds) => {
    const response = await ResourceManagementService.deleteVendor(vendorIds);
    return response.data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
