/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Thrird Party Devices i.e. Aquasim, ...etc
*/
import { $axios } from "../config/axios";

export default {
  getThirdParties() {
    return $axios.get("/third-parties");
  },
  updateAquasimDetails(params) {
    console.log("hit post", params);
    return $axios.post("/ponds/aqua-sims", { data: params });
  },
  getLunarPhasesDetails() {
    return $axios.get("/lunar-phases");
  }
};
