/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: accountManagerRouters.js
Description: This file contains the router configuration useful for handling the navigation for account manager site.
*/
import indexAccountManager from "@/views/indexAccountManager";
import view404 from "@/views/view404";
export const AccountManagerUsers = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/CustomerDetailsTable"
  );
export const SkrettingTechnicianTable = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/skrettingTechnicianTable"
  );
export const SkrettingTechnicianIndividualView = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/skrettingTechnicianIndividualView"
  );

export const SubscriptionsManage = () =>
  import(
    "@/components/accountManager/subscriptionTab/subscritptionManager.vue"
  );

export const SubscriptionsManageCustomer = () =>
  import(
    "@/components/accountManager/subscriptionTab/subscritptionManagerCustomer.vue"
  );

export const CustomerPondSummary = () =>
  import("@/components/accountManager/subscriptionTab/customerPondSummary.vue");

export const PondDetails = () =>
  import("@/components/accountManager/subscriptionTab/pondDetails.vue");

export const SubscriptionsYearwise = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/subscriptionTab/yearWiseCustomer.vue"
  );
const doesUserCanAccess = function(to, from, next) {
  return next();
};
export const PondMothers = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/devicesTab/PondMotherTab.vue"
  );
export const Gateways = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/devicesTab/GatewayTab.vue"
  );
export const PondGuards = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/devicesTab/PondGuardTab.vue"
  );
export const ShrimpTalks = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/accountManager/devicesTab/ShrimpTalkTab.vue"
  );
export default {
  path: "/account-manager",
  component: indexAccountManager,
  children: [
    {
      path: "/",
      redirect: "users"
    },
    {
      path: "users",
      name: "users",
      component: AccountManagerUsers,
      meta: {
        langKey: "DL_customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "sub-users",
      name: "sub-users",
      component: SkrettingTechnicianTable,
      meta: {
        langKey: "DL_customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "sub-user/:subuser_id",
      name: "subuserdetails",
      component: SkrettingTechnicianIndividualView,
      meta: {
        langKey: "DL_customers"
      },
      props: true,
      beforeEnter: doesUserCanAccess
    },

    {
      path: "subscriptions/manage",
      name: "Subscriptions-manage",
      component: SubscriptionsManage,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/manage-customer",
      name: "Subscriptions-manage-customer",
      component: SubscriptionsManageCustomer,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/year-wise",
      name: "Subscriptions-year-wise",
      component: SubscriptionsYearwise,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/customer-wise",
      name: "Subscriptions-customer",
      component: CustomerPondSummary,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/pond-details",
      name: "Subscriptions-pond-details",
      component: PondDetails,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondmothers",
      name: "pondmothers",
      component: PondMothers,
      meta: {
        langKey: "Comn_pond_mother"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "gateways",
      name: "gateways",
      component: Gateways,
      meta: {
        langKey: "Comn_gateway"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "shrimptalks",
      name: "shrimptalks",
      component: ShrimpTalks,
      meta: {
        langKey: "Comn_shrimptalk"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondguards",
      name: "pondguards",
      component: PondGuards,
      meta: {
        langKey: "Comn_pond_guard"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "404",
      name: "admin404",
      component: view404,
      meta: {
        langKey: "PondLogs"
      }
    }
  ]
};
