<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erSingleDayPicker.vue
Description: This file contains the date picker component which is the extension of the element ui date picker component for single date selection
-->
<template>
  <el-row
    type="flex"
    justify="center"
    :class="`er-single-day-picker ${disabled ? 'is-disabled' : ''}`"
  >
    <i class="el-icon-arrow-left" @click="prevDayClick"></i>
    <el-date-picker
      :value="value"
      :size="size"
      :clearable="clearable"
      :picker-options="computedPickerOptions"
      :format="format"
      :value-format="valueFormat"
      :disabled="disabled"
      @input="handleChangeInSelectedDate"
    ></el-date-picker>
    <i class="el-icon-arrow-right" @click="nextDayClick"></i>
  </el-row>
</template>
<script>
import { DATE_OBJ_DATE_FORMAT_WITH_YEAR } from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
export default {
  props: {
    clearable: {
      default: false
    },
    size: {
      default: "mini"
    },
    pickerOptions: {
      default: () => ({})
    },
    value: {
      default: ""
    },
    format: {
      default: DATE_OBJ_DATE_FORMAT_WITH_YEAR
    },
    valueFormat: {
      required: true,
      default: "yyyy-MM-dd"
    },
    disabled: {
      default: false
    }
  },
  data: function() {
    return {
      datePickerValue: this.value
    };
  },
  computed: {
    computedPickerOptions: function() {
      return Object.keys(this.pickerOptions).length > 0
        ? this.pickerOptions
        : {
            disabledDate(time) {
              return time.getTime() > Date.now();
            }
          };
    },
    computedValue: {
      get() {
        return dateUtils.parse(this.value, this.valueFormat, new Date());
      },
      set(value) {
        this.$emit("change-day", value);
      }
    },
    disabledDateMethod: function() {
      return (
        (this.pickerOptions && this.pickerOptions.disabledDate) ||
        (date => {
          return dateUtils.isAfter(date, new Date());
        })
      );
    }
  },
  methods: {
    prevDayClick() {
      const prevDay = dateUtils.subtract(
        dateUtils.parse(this.value, this.valueFormat, new Date()),
        { days: 1 }
      );
      if (!this.disabledDateMethod(prevDay)) {
        const value = dateUtils.formatDate(
          dateUtils.subtract(
            dateUtils.parse(this.value, this.valueFormat, new Date()),
            { days: 1 }
          ),
          this.valueFormat
        );
        this.datePickerValue = value;
        this.$emit("change-prev-day", value);
      }
    },
    nextDayClick() {
      const nextDay = dateUtils.add(
        dateUtils.parse(this.value, this.valueFormat, new Date()),
        { days: 1 }
      );
      if (!this.disabledDateMethod(nextDay)) {
        const value = dateUtils.formatDate(nextDay, this.valueFormat);
        this.datePickerValue = value;
        this.$emit("change-next-day", value);
      }
    },
    handleChangeInSelectedDate(value) {
      this.datePickerValue = value;
      this.$emit("change-day", value);
    },
    handleDatePickerInput(value) {
      this.datePickerValue = value;
      this.$emit("change-day", value);
    }
  }
};
</script>

<style lang="scss">
@mixin input-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.er-single-day-picker {
  align-items: center;
  justify-items: center;
  @include responsiveProperty(width, 100px, 115px, 140px);
  &.is-disabled {
    .el-input__inner {
      background-color: transparent;
    }
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  .el-icon-arrow-left,
  .el-icon-arrow-right {
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    @include input-font-size;
    font-weight: 700;
  }
  .el-input__icon {
    line-height: 28px;
    width: 16px;
    @include input-font-size;
  }
  .el-input__inner {
    border: unset;
    text-align: center;
    padding-right: 0px;
    @include input-font-size;
  }
  .el-input__prefix {
    left: 0px;
  }
  .el-input--prefix {
    .el-input__inner {
      padding-left: 16px;
    }
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
