import ScheduleService from "@/services/ScheduleService";
export default {
  namespaced: true,
  state: {
    pondsAndPmsBySelectedStatus: []
  },
  getters: {
    getPondsBySelectedStatus: (state, getters, rootState, rootGetters) => {
      const pondsAndPmsBySelectedStatus = state.pondsAndPmsBySelectedStatus;
      return Object.keys(pondsAndPmsBySelectedStatus).reduce((acc, pondId) => {
        if (pondsAndPmsBySelectedStatus[pondId].length === 0) {
          return acc;
        }
        acc.push(
          rootGetters["pondMotherSchedules/getPondDetailsToPondId"][pondId]
        );
        return acc;
      }, []);
    },
    getPmDetailsToPmIdFromPonds: (state, getters, rootState, rootGetters) => {
      return Object.values(
        rootGetters["pondMotherSchedules/getPondDetailsToPondId"]
      )
        .map(pond => pond.pond_mothers)
        .flat(1)
        .reduce((accPms, currPm) => {
          accPms[currPm._id] = currPm;
          return accPms;
        }, {});
    },
    getPmsBySelectedStatus(state, getters) {
      return Object.values(getters.getPondToPmsBySelectedStatus).flat(1);
    },
    getPondToPmsBySelectedStatus(state, getters) {
      return Object.entries(state.pondsAndPmsBySelectedStatus).reduce(
        (accPondToPms, [pondId, arrPms]) => {
          if (arrPms.length === 0) {
            return accPondToPms;
          }
          accPondToPms[pondId] = arrPms.map(eachPm => {
            return {
              ...eachPm,
              title: getters.getPmDetailsToPmIdFromPonds[eachPm._id].title,
              pond_id: pondId
            };
          });
          return accPondToPms;
        },
        {}
      );
    }
  },
  mutations: {
    SET_PMS_FETCHED_BY_STATUS(state, payload) {
      state.pondsAndPmsBySelectedStatus = payload;
    }
  },
  actions: {
    fetchAllPMsInAllPondsByScheduleStatus: async (
      { commit },
      { pondIds, status }
    ) => {
      const response = await ScheduleService.fetchAllPMsInAllPondsByScheduleStatus(
        pondIds,
        status
      );
      commit("SET_PMS_FETCHED_BY_STATUS", response.data.data);
    },
    changeStatusOfPondTS: async function(
      context,
      { pondId, ui_id, status, mode }
    ) {
      const selectedDay =
        context.rootGetters["pondMotherSchedules/getSelectedDay"];
      const schedule_id =
        context.rootGetters[
          "pondMotherSchedules/getExistSchedDtSecsToPondIdToPondSchedId"
        ][selectedDay + "_" + pondId];
      const ts =
        context.rootGetters["pondMotherSchedules/getPondTSTableData"][
          selectedDay + "_" + pondId
        ][ui_id];
      const pmIds = Object.keys(ts.pmsSubscribe);
      const pmTSIds = Object.values(ts.pmsSubscribe);
      const backendChangeStatusPayload = [];
      pmTSIds.forEach((ui_id, index) => {
        const dayPondIdPmIdKey = `${selectedDay}_${pondId}_${pmIds[index]}`;
        const uiPmTS =
          context.rootGetters["pondMotherSchedules/getPmTSTableData"][
            dayPondIdPmIdKey
          ][ui_id];
        const back_ts_id = uiPmTS.bk_id;
        if (back_ts_id) {
          backendChangeStatusPayload.push({
            schedule_id: schedule_id,
            time_slot_id: back_ts_id,
            pond_mother_id: pmIds[index]
          });
        }
      });
      let errorObj;
      try {
        await context.dispatch(
          "schedules/changePondMotherStatusInTimeSlot",
          {
            statusChangesPayload: backendChangeStatusPayload,
            status: status
          },
          { root: true }
        );
      } catch (err) {
        errorObj = err;
      } finally {
        await context.dispatch(
          "pondMotherSchedules/refreshPondSchedules",
          {
            days: [selectedDay]
          },
          { root: true }
        );
        if (errorObj) {
          throw errorObj;
        }
      }
    },
    changeStatusOfPmTS: async function(
      context,
      { pondId, pmId, tsId, status, mode }
    ) {
      const selectedDay =
        context.rootGetters["pondMotherSchedules/getSelectedDay"];
      const schedule_id =
        context.rootGetters[
          "pondMotherSchedules/getExistSchedDtSecsToPondIdToPondSchedId"
        ][selectedDay + "_" + pondId];
      let errorObj;
      try {
        await context.dispatch(
          "schedules/changePondMotherStatusInTimeSlot",
          {
            statusChangesPayload: {
              schedule_id: schedule_id,
              time_slot_id: tsId,
              pond_mother_id: pmId
            },
            status: status
          },
          { root: true }
        );
      } catch (err) {
        errorObj = err;
      } finally {
        await context.dispatch(
          "pondMotherSchedules/refreshPondSchedules",
          {
            days: [selectedDay]
          },
          { root: true }
        );
        if (errorObj) {
          throw errorObj;
        }
      }
    },
    changeStatusOfArrPmTS: async function(
      context,
      { arrPondIds, arrPmIds, status }
    ) {
      const selectedPondId =
        context.rootGetters["pondMotherSchedules/getSelectedPond"]._id;
      const selectedDay =
        context.rootGetters["pondMotherSchedules/getSelectedDay"];
      let errorObj;
      try {
        await context.dispatch(
          "schedules/changePondMotherStatusInTimeSlot",
          {
            statusChangesPayload: arrPmIds,
            status: status
          },
          { root: true }
        );
      } catch (err) {
        errorObj = err;
      } finally {
        if (arrPondIds.includes(selectedPondId)) {
          await context.dispatch(
            "pondMotherSchedules/refreshPondSchedules",
            {
              days: [selectedDay]
            },
            { root: true }
          );
        }
        if (errorObj) {
          throw errorObj;
        }
      }
    },
    changeStatusOfPmTSAtUI: function(
      context,
      { selectedDay, currPmTSId, pondId, pmId }
    ) {
      context.commit(
        "pondMotherSchedules/SET_TS_STATUS_AT_UI",
        {
          selectedDay,
          currPmTSId,
          pondId,
          pmId
        },
        { root: true }
      );
    },
    changeStatusOfPondTSAtUI: function(
      context,
      { selectedDay, currPondTSId, pondId }
    ) {
      context.commit(
        "pondMotherSchedules/SET_TS_STATUS_AT_UI",
        {
          selectedDay,
          currPondTSId,
          pondId
        },
        { root: true }
      );
    }
  }
};
