import newReleaseService from "../../services/newReleaseService";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  callNewReleaseInfo: async function() {
    return await newReleaseService.getReleaseInfo();
  },
  setWatchStatus: async function(context, params) {
    return await newReleaseService.setWatchStatus(params);
  }
};

export default {
  namespaced: "true",
  state,
  getters,
  mutations,
  actions
};
