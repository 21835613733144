/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondmother.js
Description: This file contains all the vuex store functions used in PondMother page
*/
import ShrimpSnap from "../../model/shrimpsnap";
import { cloneDeep } from "lodash";
import bkPermissions from "@/constants/bkPermissions";
import ShrimpSnapService from "../../services/ShrimpSnapService";
const state = {
  shrimpSnaps: [],
  mapSSIdToSS: null
};
const getters = {
  getShrimpSnaps: state => {
    return state.shrimpSnaps.map(pm => {
      return cloneDeep(pm);
    });
  },
  getObjSsIdSS: (state, getters) => {
    const objSsIdSs = {};
    getters.getShrimpSnaps.forEach(pm => {
      objSsIdSs[pm._id] = pm;
    });
    return objSsIdSs;
  }
};
const mutations = {
  SET_SHRIMP_SNAP_DATA(state, listOfShrimpSnaps) {
    const ssArr = [];
    listOfShrimpSnaps.forEach(pondmother => {
      const ssObj = new ShrimpSnap();
      ssObj.castToSSObj(pondmother);
      ssArr.push(ssObj);
    });
    state.shrimpSnaps = ssArr;
  }
};
const actions = {
  fetchAll: async function(context, queryParams) {
    const response = await ShrimpSnapService.fetchAll(queryParams);
    context.commit("SET_SHRIMP_SNAP_DATA", response.data.shrimp_snaps);
    return {
      message: response.data.message,
      totalPMs: response.data.total,
      page: response.data.page
    };
  },
  fetchOneById: async function(context, shrimpSnapId) {
    const response = await ShrimpSnapService.fetchOneById(shrimpSnapId);
    return response.data;
  },
  fetchByPondId: async function(context, pondId) {
    const requiredPermissions = bkPermissions["/ponds/:pond_id/shrimp-snap"];
    const canAccessApi = context.rootGetters[
      "user/canUserAccessApiByBkPermissions"
    ](requiredPermissions);
    if (!canAccessApi) {
      console.error("No Permissions to call this api");
      return [];
    }
    const response = await ShrimpSnapService.fetchByPondId(pondId, {
      include: ["title", "code"]
    });
    return response.data.shrimp_snaps;
  },
  addToPond: async function(context, shrimpSnaps) {
    await ShrimpSnapService.addMultiple(shrimpSnaps);
  },
  unAssignFromPond: async function(context, shrimpSnaps) {
    await ShrimpSnapService.deleteMultiple(shrimpSnaps);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
