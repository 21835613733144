/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Gateway Service
*/
import { $axios } from "@/config/axios";

export default {
  saveGatewayDetails: function(payload) {
    return $axios.post(`/gateways`, payload);
  },
  fetchGatewayData: function(params) {
    return $axios.get("/gateways", { params });
  },
  fetchGatewayInfoData: function(params) {
    return $axios.get("/gateways-with-device-info", { params });
  },
  fetchGatewayDeviceInfoData: function(params) {
    return $axios.get("/gateways-with-device-info-with-pond-wise-data", {
      params
    });
  }
};
