/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: index.js
Description: Contains all the imports for vuex store modules
*/
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import config from "@/config/app";
// import

// Modules
import auth from "./modules/auth";
import user from "./modules/user";
import newRelease from "./modules/newRelease";
import pond from "./components/pond";
import shrimptalk from "./modules/shrimptalk";
import superadmin from "./modules/superadmin/index";
import dealer from "./modules/dealer";
import header from "./components/header";
import accountManager from "./modules/accountManager";
import skrettingTechnician from "./modules/skrettingTechnician";
import releaseNotes from "./components/releaseNotes";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
const store = new Vuex.Store({
  state: {
    cancelTokens: []
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    }
  },
  mutations: {
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    }
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request, i) => {
        if (request.cancel) {
          request.cancel();
        }
      });

      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    }
  },
  modules: {
    auth,
    user,
    newRelease,
    pond,
    shrimptalk,
    superadmin,
    header,
    dealer,
    accountManager,
    skrettingTechnician,
    releaseNotes
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: config.APP_VERSION,
      paths: ["auth", "user.userLocations", "user.user"]
      // setState: (key, state, storage) => {
      //   try {
      //     return storage.setItem(
      //       `user_${state.user.user._id}`,
      //       JSON.stringify(state)
      //     );
      //   } catch (err) {
      //     return storage.clear();
      //   }
      // }
    })
  ]
});

export default store;
