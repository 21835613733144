/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondmother.js
Description: This file has the model,functions  of pondmother object used in the pondlogs customer site
*/
const PM_MANAGED_BY = {
  FARMER: { value: "FARMER", name: "Farmer" },
  SHRIMP_TALK: { value: "SHRIMP_TALK", name: "ShrimpTalk" }
};

class PondMother {
  constructor() {
    this._id = "";
    this.code = "";
    this.created_at = "";
    this.managed_by = "NO_MODE";
    this.modified = "";
    this.mode = "NO_MODE";
    this.pond_id = "";
    this.pond_mother_id = "";
    this.remaining_feed = 0;
    this.shrimp_talk_id = undefined;
    this.status = "";
    this.title = "";
    this.total_feed = 0;
    this.updated_at = "";
    this.user_id = "";
    this.isEditted = false;
    this.hasSelected = "";
    this.retain_previous_day_schedule = false;
    this.delete_future_schedules = false;
    this.midnight_sch_enable = false;
    this.settings = {
      kg_dispense_time_sec: undefined,
      mode: ""
    };
    this.hopper_type = "";
  }

  set setPondMotherTitle(value) {
    if (!this.code) {
      throw new Error("set pondmother Code before assigning pondmother title");
    }
    this.title = value || this.code;
  }

  getPmManagerType(type) {
    try {
      return PM_MANAGED_BY[type];
    } catch (err) {
      console.error("PM_MANAGED_BY of " + type + " does not exist");
    }
  }

  castToPMObj(inputPMDetails) {
    if (inputPMDetails != null && typeof inputPMDetails !== "undefined") {
      Object.keys(inputPMDetails).forEach(key => {
        if (key === "settings") {
          Object.keys(inputPMDetails.settings).forEach(innerKey => {
            if (innerKey === "kg_dispense_time") {
              this.settings.kg_dispense_time_sec =
                inputPMDetails.settings[innerKey];
              return;
            }
            this.settings[innerKey] = inputPMDetails.settings[innerKey];
          });
          return;
        }
        this[key] = inputPMDetails[key];
      });
      if (!this.settings || !this.settings.kg_dispense_time_sec) {
        this.settings.kg_dispense_time_sec = "";
      }
      this.pond_mother_id = this._id;
    }
  }

  getPMPropertiesObj() {
    const propertyNameList = [
      "title",
      "pond_mother_id",
      "managed_by",
      "shrimp_talks_id",
      "status"
    ];
    const properties = {};
    propertyNameList.forEach(property => {
      properties[property] = this[property];
    });
    return properties;
  }

  static generateColorForMode(mode) {
    if (["SHRIMP_TALK", "AUTOMATIC"].indexOf(mode) > -1) {
      return { color: "#39cc64" };
    } else if (["FARMER", "SCHEDULE"].indexOf(mode) > -1) {
      return { color: "#F5A623" };
    } else if (["BASIC", "MANUAL"].indexOf(mode) > -1) {
      return { color: "#8c92ac" };
    } else if (["HYBRID"].indexOf(mode) > -1) {
      return { color: "#F9327A" };
    } else {
      return { color: "#000000" };
    }
  }
}

export default PondMother;
