/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: elementUi.js
Description: This file registers the element ui configuration and add all the element ui components to the vuejs object
*/
import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import i18n from "./i18n";
import { DataTablesServer, DataTables } from "vue-data-tables";

Vue.use(DataTablesServer);
Vue.use(DataTables);
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
