import { $superAdminAxios } from "@/config/axios";

export default {
  fetchInquiryLog({ params }) {
    return $superAdminAxios.get(`admin/inquiry-logs`, {
      params
    });
  },
  addReplyToInquiryLog: async function(payload) {
    const response = await $superAdminAxios.post(
      "admin/inquiry-reply",
      payload
    );
    return response;
  }
};
