<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: layoutCenter.vue
Description:this file is not used across the application
-->
<template>
  <el-row type="flex" justify="center" align="middle" :style="`min-height:${height}`">
    <slot></slot>
  </el-row>
</template>

<script>
export default {
  props: {
    height: {
      default: "10vh"
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
