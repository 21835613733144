/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: accountManager.js
Description: Contains all vuex store functions of accountManager
*/
import { $superSkrettingTechnicianAxios } from "../../config/axios";
import {
  saveAccessTokenToLocalStorage,
  saveUserDetailsToLocalStorage,
  setImpersonation,
  setImpersonationBy
} from "@/middleware/auth";
import localStore from "@/utils/localStore";
import skrettingTechnicianService from "../../services/skrettingTechnicianService";

const state = {
  skrettingTechnicianProfile: {},
  skrettingTechnicianUsers: []
};
const getters = {
  getSkrettingTechnicianProfile: state => state.skrettingTechnicianProfile,
  getSkrettingTechnicianUsers: state => state.skrettingTechnicianUsers
};
const mutations = {
  SET_USER_DATA(state, userData) {
    state.skrettingTechnicianProfile = userData;
    console.log(
      "state.skrettingTechnicianProfile",
      state.skrettingTechnicianProfile
    );
  },
  SET_ALL_ASSIGNED_USERS_OF_SKRETTING_TECHNICIAN(
    state,
    skrettingTechnicianUsers
  ) {
    state.skrettingTechnicianUsers = skrettingTechnicianUsers;
  }
};
const actions = {
  async loginAsSkrettingTechnician(context) {
    const userId = localStore.getItem(`ACCOUNT_MANAGER_SUB_USER-id`);
    const credentials = localStore.getItem(
      `ACCOUNT_MANAGER_SUB_USER-${userId}`
    );
    if (credentials === undefined) {
      throw new Error("No Keys Available");
    }
    $superSkrettingTechnicianAxios.defaults.headers.common.Authorization =
      credentials.access_token;
    await context.dispatch("fetchUserProfile");
    const userProfile = context.getters.getSkrettingTechnicianProfile;
    console.log("userProfile", userProfile);
    saveUserDetailsToLocalStorage(userProfile);
    saveAccessTokenToLocalStorage(credentials);
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
  },
  async skrettingTechnicianNotifyBackendOnLogout() {
    await skrettingTechnicianService.skrettingTechnicianServiceNotifyBackendOnLogout();
  },
  fetchUserToken: async function(context, { userId, params }) {
    // const response = await $superSkrettingTechnicianAxios.get(
    //   `/account-manager/users/${userId}/signin`,
    //   {
    //     params
    //   }
    // );
    try {
      const response = await skrettingTechnicianService.fetchUserTokenService(
        params,
        userId
      );
      // saving accesstoken and refresh in the local storage
      const { user_details, access_token, refresh_token } = response.data;
      saveUserDetailsToLocalStorage(user_details);
      saveAccessTokenToLocalStorage({
        access_token,
        refresh_token
      });
      // saving the user data from the response
      context.commit("user/SET_USER_DATA", response.data.user_details, {
        root: true
      });
      context.commit("auth/SET_AUTH_STATUS", true, {
        root: true
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  LoginAsUser: async (context, userId) => {
    setImpersonation(true);
    setImpersonationBy("ACCOUNT_MANAGER_SUB_USER");
    await context.dispatch("fetchUserToken", { userId, params: {} });
  },
  fetchUserProfile: async context => {
    // const response = await $superSkrettingTechnicianAxios.get("/profile");
    try {
      const response = await skrettingTechnicianService.fetchUserProfileService();
      context.commit("SET_USER_DATA", response.data.user_details);
      const userDetails = response.data.user_details;
      return userDetails;
    } catch (err) {
      console.log(err);
    }
  },
  fetchAlreadyAssingedUsersFromSubuser: async (context, params = {}) => {
    try {
      const response = await skrettingTechnicianService.fetchAlreadyAssingedUsersFromSubuser(
        { subuser_id: params.subuser_id }
      );
      context.commit(
        "SET_ALL_ASSIGNED_USERS_OF_SKRETTING_TECHNICIAN",
        response.data.users
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
