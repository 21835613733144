/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Feed Blower
*/
import { $axios } from "@/config/axios";

export default {
  fetchAllFeedBlowers(params) {
    return $axios.get("/feed-blowers", { params });
  },
  assignFeedBlowersToPond(pond_id, payload) {
    return $axios.post(`ponds/${pond_id}/feed-blowers`, payload);
  },
  unAssignFeedBlowersFromPond(pond_id, payload) {
    return $axios.delete(`ponds/${pond_id}/feed-blowers`, { data: payload });
  },
  addPondMothersToFeedBlower(feed_blower_id, payload) {
    return $axios.post(`feed-blowers/${feed_blower_id}/pond-mothers`, payload);
  },
  removePondMothersFromFeedBlower(feed_blower_id, payload) {
    return $axios.delete(`feed-blowers/${feed_blower_id}/pond-mothers`, {
      data: payload
    });
  },
  updateFeedBlowerDetails(payload) {
    return $axios.patch(`feed-blowers`, payload);
  },
  updateFeedBlowerPms(feed_blower_id, payload) {
    return $axios.patch(`feed-blowers/${feed_blower_id}/pond-mothers`, payload);
  }
};
