<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDateRangeFilter2.vue
Description: This file contains the two date picker components useful for date range comparisons used in the dashboard tab  -- feed consumption card
-->
<template>
  <el-row tag="span" class="er-date-range-filter--2">
    <layout-toolbar gap="10">
      <!-- <el-col tag="span" :span="18"> -->
      <er-date-picker
        :value="getDatesRanges[0]"
        :timeZoneString="timeZoneString"
        class="date-range-picker-1"
        size="mini"
        type="daterange"
        :format="displayFormat"
        :value-format="datePickerFormat"
        popper-class="er-date-range-filter__date-range-1"
        :arrShortcuts="arrShortcuts"
        @input="handleChangeInDateRange($event, 0)"
      ></er-date-picker>
      <!-- </el-col> -->
      <!-- <el-col tag="span" :span="6"> -->
      <el-tag class="el-tag-1">
        <slot name="range_1_value"></slot>
      </el-tag>
      <!-- </el-col> -->
    </layout-toolbar>
    <el-row>
      <el-col tag="span" :span="18">
        <div class="icon-container">
          <svg
            class="svg-icon-size"
            viewBox="0 0 39 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.5 19C38.5 29.2051 30.0057 37.5 19.5 37.5C8.99427 37.5 0.5 29.2051 0.5 19C0.5 8.7949 8.99427 0.5 19.5 0.5C30.0057 0.5 38.5 8.7949 38.5 19Z"
              fill="#F9F9F9"
              stroke="#FB8CB4"
            />
            <path
              d="M22 22.2375V31H24.5V22.2375H28.25L23.25 17.25L18.25 22.2375H22Z"
              fill="#FC196B"
            />
            <path
              d="M14.5 14.7624H10.75L15.75 19.7499L20.75 14.7624H17V5.99994H14.5V14.7624Z"
              fill="#FC196B"
            />
          </svg>
        </div>
      </el-col>
    </el-row>
    <layout-toolbar gap="10">
      <!-- <el-col tag="span" :span="18"> -->
      <er-date-picker
        :value="getDatesRanges[1]"
        class="date-range-picker-2"
        :timeZoneString="timeZoneString"
        type="daterange"
        :format="displayFormat"
        :value-format="datePickerFormat"
        size="mini"
        popper-class="er-date-range-filter__date-range-2"
        :arrShortcuts="arrShortcuts"
        @input="handleChangeInDateRange($event, 1)"
      ></er-date-picker>
      <!-- </el-col> -->
      <!-- <el-col tag="span" :span="6"> -->
      <el-tag class="el-tag-2">
        <slot name="range_2_value"></slot>
      </el-tag>
      <!-- </el-col> -->
    </layout-toolbar>
    <el-row type="flex" justify="end" style="margin-top: 10px">
      <er-button
        size="mini"
        :showLabel="true"
        :showIcon="true"
        btnType="save"
        @click="handleApplyEvent"
        :title="$t('Comn_apply')"
      >
        <p>
          {{ $t("Comn_apply") }}
        </p>
      </er-button>
      <er-button btnType="cancel" size="mini" @click="handleCancelEvent">
        {{ $t("Comn_cancel") }}
      </er-button>
    </el-row>
  </el-row>
</template>

<script>
import { DATE_OBJ_DATE_FORMAT_WITH_YEAR } from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
import layoutToolbar from "../../layouts/layoutToolbar.vue";
import { mapActions } from "vuex";
export default {
  components: { layoutToolbar },
  props: {
    value: {
      default: undefined
    },
    timeZoneString: {
      required: true,
      default: 0
    },
    displayFormat: {
      default: DATE_OBJ_DATE_FORMAT_WITH_YEAR
    }
  },
  data: function() {
    return {
      dateRanges: undefined,
      datePickerFormat: "yyyy-MM-dd",
      selectedDateQuery: undefined,
      selectedDateRangeQuery2: undefined,
      checked: false
    };
  },
  computed: {
    dateRange2PickOptions() {
      return {
        previous_period: { id: "previous_period", text: "Previous Period" },
        previous_year: { id: "previous_year", text: "Previous Year" },
        custom: { id: "custom", text: "Custom" }
      };
    },
    getDefaultDateRanges() {
      const today = dateUtils.getCurrDateInGivenTZ(this.timeZoneString);
      return [
        [
          dateUtils.formatTZWithLocale(
            dateUtils.subtract(today, { days: 1 }),
            this.datePickerFormat,
            this.$i18n.locale
          ),
          dateUtils.formatTZWithLocale(
            today,
            this.datePickerFormat,
            this.$i18n.locale
          )
        ],
        [
          dateUtils.formatTZWithLocale(
            dateUtils.subtract(today, { days: 2 }),
            this.datePickerFormat,
            this.$i18n.locale
          ),
          dateUtils.formatTZWithLocale(
            dateUtils.subtract(today, { days: 1 }),
            this.datePickerFormat,
            this.$i18n.locale
          )
        ]
      ];
    },
    getDatesRanges() {
      return this.dateRanges || this.value || this.getDefaultDateRanges;
    },
    arrShortcuts() {
      return [
        dateUtils.getDatePickerShortcut(2, "days"),
        dateUtils.getDatePickerShortcut(3, "days"),
        dateUtils.getDatePickerShortcut(5, "days"),
        dateUtils.getDatePickerShortcut(30, "days"),
        dateUtils.getDatePickerShortcut(3, "months"),
        dateUtils.getDatePickerShortcut(6, "months")
      ];
    }
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleChangeInDateRange(value, dateInputID) {
      if (!this.dateRanges) {
        this.dateRanges = [...this.value];
      }
      this.dateRanges.splice(dateInputID, 1, value);
    },
    handleChangeDaterange2(value) {
      this.selectedDateRangeQuery2 = value;
    },
    handleApplyEvent() {
      if (!this.dateRanges) {
        this.dateRanges = [...this.value];
      }
      this.$emit(
        "input",
        this.dateRanges.map(x =>
          x.map(y => dateUtils.formatDate(new Date(y), this.datePickerFormat))
        )
      );
      this.$emit(
        "change",
        this.dateRanges.map(x =>
          x.map(y => dateUtils.formatDate(new Date(y), this.datePickerFormat))
        )
      );
      this.mixPanelEventGenerator({ eventName: "Dashboard - Feed Consumption - Compare Total Dispensed Feed" });
    },
    handleCancelEvent() {
      this.dateRanges = [...this.value];
      this.$emit(
        "cancel",
        this.dateRanges.map(x =>
          x.map(y => dateUtils.formatDate(new Date(y), this.datePickerFormat))
        )
      );
    }
  }
};
</script>

<style lang="scss">
.er-date-range-filter__date-range-2 {
  top: 225px !important;
}
.er-date-range-filter__date-range-1,
.er-date-range-filter__date-range-2 {
  .el-picker-panel [slot="sidebar"] + .el-picker-panel__body,
  .el-picker-panel__sidebar + .el-picker-panel__body {
    margin-left: 120px;
  }
}
.er-date-range-filter--2 {
  display: flex;
  flex-direction: column;
  .el-row {
    margin-bottom: 5px;
    // width: 20%;
    // display: inline-block;
  }
  .el-range-editor--mini .el-range-input {
    font-size: $date-picker-font-size;
  }
  .date-range-picker-1 {
    &.is-active,
    &.is-active:hover {
      border-color: #233a73;
    }
  }
  .date-range-picker-2 {
    &.is-active,
    &.is-active:hover {
      border-color: #fc196b;
    }
  }
  &.el-range-editor--mini .el-range-separator {
    height: unset;
    line-height: 1.15;
    font-size: $date-picker-font-size;
    @include responsiveProperty(
      width,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .el-tag {
    height: 28px;
    line-height: 28px;
    margin-left: 10px;
  }
  .el-tag-1 {
    background-color: #233a73;
    border-color: #233a73;
    color: white;
  }
  .el-tag-2 {
    background-color: #fc196b;
    border-color: #fc196b;
    color: white;
  }
  .icon-container {
    display: table;
    margin: auto;
    svg {
      display: table-cell;
    }
  }
}
</style>
