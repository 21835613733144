/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: shrimptalk.js
Description: This file contains the model, functions of  shrimptalk used all over the pondlogs customer site
*/
/* eslint-disable */
import { getType } from "@/utils/commonUtils";
class ShrimpTalk {
  constructor() {
    this._id = "";
    this.title = "";
    this.code = "";
    this.pond_id = "";
    this.shrimp_talk_id = "";
    this.main_pond_mother_id = "";
    this.pond_mothers = [];
    this.settings = {
      ip_address: "0.0.0.0",
      external_ip_address: "0.0.0.0",
      mac_address: undefined,
      os_version: undefined,
      software_version: undefined,
      hardware_version: undefined,
      timezone: undefined,
      latitude: undefined,
      logitude: undefined,
      shrimp_snap_restart: false,
      pi_reboot: false,
      settings_interval: 0,
      start_time: "00:00",
      end_time: "00:01",
      data_clean_interval: 0,
      sampling_frequency: 1,
      motor_mode: 0,
      video_duration: 10,
      image_count: 12,
      manual_clean: false,
      tray_depth_top: 0,
      last_updated_at: new Date(-1),
      utc_last_updated_at: new Date(-1)
    };
    this.image_settings = {
      resolution: 200,
      iso: 0,
      shutter_speed: 0,
      aperture: 0,
      white_balance: 0,
      focus: 0,
      lens_type: 0,
      compression: 0,
      file_extension: "jpg",
      last_updated_at: new Date(-1),
      utc_last_updated_at: new Date(-1)
    };
    this.supports = {
      pms_count: 2
    };
    this.status = "";
    this.user_id = "";
    this.created_at = "";
    this.updated_at = "";
  }

  castToSSObj(inputSTDetails) {
    const setValues = function(source, destination) {
      for (let key in source) {
        if (destination[key] === undefined) {
          destination[key] = {};
        }
        if (getType(source[key]) === "object") {
          setValues(source[key], destination[key]);
          continue;
        }
        destination[key] = source[key];
      }
    };
    setValues(inputSTDetails, this);
    this.v1_title = `SS${this.v1_code}`;
    this.shrimp_snap_id = this._id;
  }
  getSSPropertiesObj() {
    const propertyNameList = ["title", "pond_id", "shrimp_snaps_id", "code"];
    const properties = {};
    propertyNameList.forEach(property => {
      properties[property] = this[property];
    });
    return properties;
  }
}
export default ShrimpTalk;
