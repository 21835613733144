/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: documentTitleMixin.js
Description: This mixin is useful for handling the common functionality related to setting the title of the chrome tab
*/
const documentTitleMixin = {
  mounted() {
    const title =
      this.getTitle(this) ||
      (this.$route || { meta: { langKey: "Pondlogs" } }).meta.langKey;
    if (title && this.$i18n) {
      document.title = `Pondlogs | ${this.$t(title)}`;
    }
  },
  methods: {
    getTitle: function(vm) {
      const { documentTitle } = vm.$options;
      if (documentTitle) {
        return typeof title === "function"
          ? documentTitle.call(vm)
          : documentTitle;
      }
    }
  }
};

export default documentTitleMixin;
