/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: auth.js
Description:  Contains all vuex store functions for authentication
*/
import { $unAuthAxios } from "../../config/axios";
import localStore from "@/utils/localStore";
import { userTypes } from "@/middleware/pageAccessManager";
import {
  saveAccessTokenToLocalStorage,
  saveUserDetailsToLocalStorage,
  clearUserLocalStorage
} from "@/middleware/auth";
// initial state
const state = {
  authenticatedUser: false,
  countries: []
};

const getters = {
  isAuthenticated: state => state.authenticatedUser,
  getCountries: state => {
    const countries = state.countries;
    const countriesObj = {};
    countries.forEach(({ country_code, country_name }) => {
      countriesObj[country_code] = country_name;
    });
    return countriesObj;
  },
  getArrCountries: state => {
    return state.countries.map(x => ({
      name: x.country_name,
      code: x.country_code
    }));
  }
};

const mutations = {
  SET_AUTH_STATUS(state, status) {
    state.authenticatedUser = status;
  },
  SET_COUNTRIES_LIST(state, countries) {
    state.countries = countries;
  }
};

const actions = {
  fetchCountryList(context) {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .get("/countries")
        .then(response => {
          context.commit("SET_COUNTRIES_LIST", response.data.countries);
          resolve(response.data.message);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  signUp: (context, userData) => {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .post("/signup", userData.getSignupFields())
        .then(response => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  signIn: async (context, { email, password }) => {
    const response = await $unAuthAxios.post("/signin", {
      email,
      password,
      meta_data: {
        browser: navigator.userAgent,
        client: "WEB"
      }
    });
    const { user_details, access_token, refresh_token } = response.data;
    saveUserDetailsToLocalStorage(user_details);
    saveAccessTokenToLocalStorage({
      access_token,
      refresh_token
    });
    switch (user_details.user_type) {
      case userTypes.account_manager:
        context.commit("accountManager/SET_USER_DATA", user_details, {
          root: true
        });
        break;
      case userTypes.dealer:
        context.commit("dealer/SET_USER_DATA", user_details, { root: true });
        break;
      case userTypes.super_admin:
        context.commit("superadmin/SET_USER_DATA", user_details, {
          root: true
        });
        break;
      case userTypes.skretting_technician:
        context.commit("skrettingTechnician/SET_USER_DATA", user_details, {
          root: true
        });
        break;
      default:
        context.commit("user/SET_USER_DATA", user_details, { root: true });
    }
    context.commit("SET_AUTH_STATUS", true);
    return { user_details, access_token, refresh_token };
  },
  storeGoogleAccessToken: async (context, google_access_token) => {
    const {
      user_details,
      access_token,
      refresh_token
    } = await $unAuthAxios.post("/auth/google/userInfo/", {
      access_token: google_access_token
    });
    // saving accesstoken and refresh in the local storage
    saveUserDetailsToLocalStorage(user_details);
    saveAccessTokenToLocalStorage({
      access_token,
      refresh_token
    });
    // saving the user data from the response
    context.commit("user/SET_USER_DATA", user_details, {
      root: true
    });
    context.commit("SET_AUTH_STATUS", true);
    return { user_details, access_token, refresh_token };
  },
  redirectedUserSignOut(context) {
    context.commit("SET_AUTH_STATUS", false);
    localStorage.clear();
  },
  signOut: context => {
    context.commit("SET_AUTH_STATUS", false);
    // context.dispatch("auth/user/notifyBackendOnLogout");
    const userProfile = context.rootGetters["user/getUserProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    context.commit("user/SET_USER_DATA", null, { root: true });
    localStore.setItem("is-user-signed-out", true);
  },
  impersonationSignOut: context => {
    const userProfile = context.rootGetters["user/getUserProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    localStore.removeItem("is-impersonation");
    localStore.removeItem("is-impersonation-by");
    context.commit("user/SET_USER_DATA", null, { root: true });
  },
  superAdminSignOut: context => {
    context.commit("SET_AUTH_STATUS", false);
    const userProfile = context.rootGetters["superadmin/getSuperAdminProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    context.commit("superadmin/SET_USER_DATA", null, { root: true });
  },

  dealerSignOut: context => {
    context.commit("SET_AUTH_STATUS", false);
    const userProfile = context.rootGetters["dealer/getDealerProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    context.commit("dealer/SET_USER_DATA", null, { root: true });
  },

  accountManagerSignOut: context => {
    context.commit("SET_AUTH_STATUS", false);
    const userProfile =
      context.rootGetters["accountManager/getAccountManagerProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    context.commit("accountManager/SET_USER_DATA", {}, { root: true });
  },
  skrettingTechnicianSignOut: context => {
    context.commit("SET_AUTH_STATUS", false);
    const userProfile =
      context.rootGetters["skrettingTechnician/getSkrettingTechnicianProfile"];
    if (!userProfile) return;
    clearUserLocalStorage(userProfile);
    context.commit("skrettingTechnician/SET_USER_DATA", {}, { root: true });
  },

  forgotPassword: (context, { email }) => {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .post("/password/forgot", { email })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetPassword: (context, resetPasswordDetails) => {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .post("/password/reset", resetPasswordDetails)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verifyEmailRequest: (context, verifyDetails) => {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .post("/email/verify", verifyDetails)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resendVerifyEmail: (context, email) => {
    return new Promise((resolve, reject) => {
      $unAuthAxios
        .post("/email/verification", email)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
