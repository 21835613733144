/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: dealerService.js
Description: This file contains all API service calls in related to dealer
*/
import { $superAccountAxios } from "@/config/axios";
export default {
  fetchAllSubscriptions(params) {
    return $superAccountAxios.get(`/dealer/shrimp-talk-subscription-plans`, {
      get_all: true,
      ...params
    });
  },
  fetchSubscriptionReceivables(params) {
    return $superAccountAxios.get(
      `/dealer/users-shrimp-talk-subscription-receivables`,
      {
        params
      }
    );
  },
  fetchCustomerSubscriptionReceivables(customerId, params) {
    return $superAccountAxios.get(
      `/dealer/user-shrimp-talk-subscription-receivables/${customerId}`,
      {
        params
      }
    );
  },
  fetchCustomerPondShrimptalks(pondId, params) {
    return $superAccountAxios.get(
      `/dealer/pond-subscription-shrimp-talks/${pondId}`,
      {
        params
      }
    );
  },
  fetchAllManagerDevicesService(url, params) {
    return $superAccountAxios.get(`${url}`, {
      params
    });
  }
};
