/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: featureToggleManager.js
Description:This file contains funtions related to feature availability functionality based on the user id
*/
import { userTypes } from "./pageAccessManager";
export const ALL_USERS = "ALL";
export const isFeatureAvailable = function(allowedUsers, currUser) {
  if (Array.isArray(allowedUsers)) {
    const userType = currUser.user_type;
    const userId = currUser._id;
    switch (userType) {
      case userTypes.admin:
        return allowedUsers.includes(userId);
      case userTypes.pond_owner:
      case userTypes.pond_manager:
        return allowedUsers.includes(currUser.userId);
      default:
        return false;
    }
  }
  if (allowedUsers === ALL_USERS) {
    return true;
  }
  return false;
};
export default { ALL_USERS, isFeatureAvailable };
