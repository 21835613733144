/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: bkPermissions.js
Description: This file contains the backends api permissions useful for user access management and for checking the user has required permissions to call the api
*/
export default {
  "/ponds/abw-vs-biomass": ["VIEW_STATS"],
  "/ponds/performance": ["VIEW_STATS"],
  "/ponds/weekly-weight-growth": ["VIEW_STATS"],
  "/ponds/temperature-summary": ["VIEW_STATS"],
  "/ponds/dissolved-oxygen-summary": ["VIEW_STATS"],
  "/ponds/feed-consumption-summary": ["VIEW_STATS"],
  "/ponds/feed-consumption-growth": ["VIEW_STATS"],

  "/ponds/active/summary": ["VIEW_POND_SUMMARY"],
  // '/ponds/summary': ['VIEW_POND_SUMMARY'],
  "/ponds/inactive/summary": ["VIEW_POND_SUMMARY"],
  "/ponds/hourly-feed-and-pond-guard-data": ["VIEW_POND_SUMMARY"],
  "/ponds/ABW-summary-with-multiple-ponds": ["VIEW_POND_SUMMARY"],
  "/ponds/schedule-feed-data": ["VIEW_POND_SUMMARY"],

  "/ponds/ABW-summary": ["VIEW_ABW"],
  "/ponds/error-alerts-new": ["VIEW_POND_ALERTS"],

  "/ponds/latest-ABW-values": ["VIEW_ABW"],
  "/ponds/:pond_id/latest-abw-values": ["VIEW_ABW"],
  "/ponds/abw": {
    multi: true,
    post: ["UPDATE_ABW"]
  },
  "/ponds/ABW/:abw_id": {
    multi: true,
    delete: ["DELETE_ABW"]
  },
  "/ponds/:pond_id/ABW-samples": ["VIEW_ABW"],

  "/ponds/do-temperature": ["VIEW_WATER_QUALITY_SUMMARY"],
  "/ponds/:pond_id/pond-guards/:pond_guard_id/sensor-data": [
    "VIEW_WATER_QUALITY_SUMMARY"
  ],
  "/ponds/pond-guards/:pond_guard_id/sensor-data": [
    "VIEW_WATER_QUALITY_SUMMARY"
  ],

  "/ponds": {
    multi: true,
    get: ["VIEW_PONDS"],
    post: ["UPDATE_PONDS"]
  },
  "/ponds/:pond_id": {
    multi: true,
    get: ["VIEW_PONDS"],
    patch: ["UPDATE_PONDS"]
  },
  "/ponds/:pond_id/devices": {
    multi: true,
    delete: ["UPDATE_PONDS"]
  },
  "/ponds/:pond_id/cultures": {
    multi: true,
    patch: ["UPDATE_CULTURE"]
  },
  "/ponds/:pond_id/growth-vs-biomass": ["VIEW_CULTURE"],

  "/ponds/manual-feed": {
    multi: true,
    post: ["UPDATE_MANUAL_FEED"]
  },
  "/ponds/manual-feed/:manual_feed_id": {
    multi: true,
    delete: ["DELETE_MANUAL_FEED"]
  },
  "/ponds/:pond_id/manual-feed": ["VIEW_MANUAL_FEED"],

  "/ponds/:pond_id/slotwise-feed": ["VIEW_SCHEDULES"],
  "/ponds/:pond_id/hourly-feed": ["VIEW_SCHEDULES"],
  "/ponds/:pond_id/feed-consumption-summary": ["VIEW_SCHEDULES"],
  "/ponds/schedules/meta-data": ["VIEW_SCHEDULES"],
  "/ponds/:pond_id/schedules": ["VIEW_SCHEDULES"],
  "/ponds/schedules": {
    multi: true,
    post: ["UPDATE_SCHEDULES"],
    put: ["UPDATE_SCHEDULES"],
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/schedules/today": {
    multi: true,
    put: ["UPDATE_SCHEDULES"]
  },
  "/pond-mothers/schedules": {
    multi: true,
    post: ["UPDATE_SCHEDULES"],
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/time-slots/pond-mothers": {
    multi: true,
    patch: ["UPDATE_SCHEDULES"],
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/time-slots": {
    multi: true,
    patch: ["UPDATE_SCHEDULES"],
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/schedules/:schedule_id": {
    multi: true,
    delete: ["DELETE_SCHEDULES"]
  },

  "/ponds/schedules/time-slots/pond-mothers/stop": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/time-slots/pond-mothers/resume": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/time-slots/pond-mothers/pause": ["UPDATE_SCHEDULES"],

  "/ponds/schedules/time-slots/stop": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/time-slots/resume": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/time-slots/pause": ["UPDATE_SCHEDULES"],

  "/ponds/schedules/stop": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/resume": ["UPDATE_SCHEDULES"],
  "/ponds/schedules/pause": ["UPDATE_SCHEDULES"],

  "/schedules/:schedule_id/time-slots/:time_slot_id/pond-mothers/:pond_mother_id": [
    "DELETE_SCHEDULES"
  ],
  "/schedules/:schedule_id/time-slots/:time_slot_id": ["DELETE_SCHEDULES"],
  "/pond-mothers/:pond_mother_id/schedules": ["VIEW_SCHEDULES"],
  "/ponds/:pond_id/pond-mothers/schedules": ["VIEW_SCHEDULES"],
  "/ponds/schedules/time-slots": {
    multi: true,
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/schedules/time-slots/pond-mothers": {
    multi: true,
    delete: ["DELETE_SCHEDULES"]
  },
  "/ponds/:pond_id/feed-data": ["VIEW_SCHEDULES"],

  "/schedules/templates": {
    multi: true,
    get: ["VIEW_SCHEDULES"],
    post: ["UPDATE_SCHEDULES"]
  },
  "/schedules/templates/:template_id": {
    multi: true,
    patch: ["UPDATE_SCHEDULES"],
    delete: ["DELETE_SCHEDULES"],
    get: ["VIEW_SCHEDULES"]
  },

  "/ponds/:pond_id/pond-mothers": ["VIEW_POND_MOTHERS"],
  "/ponds/pond-mothers": {
    multi: true,
    get: ["VIEW_POND_MOTHERS"],
    post: ["VIEW_POND_MOTHERS"]
  },
  "/ponds/pond-mothers/settings": {
    multi: true,
    patch: ["UPDATE_POND_MOTHERS"]
  },
  "/ponds/pond-mothers/:pond_mother_id": {
    multi: true,
    delete: ["DELETE_POND_MOTHERS"]
  },

  "/ponds/shrimp-talks": {
    multi: true,
    get: ["VIEW_SHRIMP_TALKS"],
    post: ["UPDATE_SHRIMP_TALKS"],
    delete: ["DELETE_SHRIMP_TALKS"]
  },
  "/ponds/shrimp-talks/settings": {
    multi: true,
    patch: ["UPDATE_SHRIMP_TALKS"]
  },
  "/ponds/shrimp-talks/:shrimp_talk_id": {
    multi: true,
    get: ["VIEW_SHRIMP_TALKS"],
    delete: ["DELETE_SHRIMP_TALKS"]
  },

  "/ponds/shrimp-snaps": {
    multi: true,
    get: ["VIEW_SHRIMP_SNAPS"],
    post: ["UPDATE_SHRIMP_SNAPS"],
    delete: ["DELETE_SHRIMP_SNAPS"]
  },
  "/ponds/shrimp-snaps/:shrimp_snap_id": {
    multi: true,
    get: ["VIEW_SHRIMP_SNAPS"],
    delete: ["DELETE_SHRIMP_SNAPS"]
  },

  "/ponds/device-summary": [
    "VIEW_POND_MOTHERS",
    "VIEW_POND_GUARDS",
    "VIEW_SHRIMP_TALKS",
    "VIEW_GATEWAYS"
  ],

  "/ponds/pond-guards": {
    multi: true,
    get: ["VIEW_POND_GUARDS"],
    post: ["UPDATE_POND_GUARDS"]
  },
  "/ponds/pond-guards/settings": {
    multi: true,
    patch: ["UPDATE_POND_GUARDS"]
  },
  "/ponds/pond-guards/:pond_guard_id": {
    multi: true,
    delete: ["DELETE_POND_GUARDS"]
  },
  "/ponds/:pond_id/pond-guards-in-current-culture": ["VIEW_POND_GUARDS"],

  "/gateways-with-device-info": ["VIEW_GATEWAYS"],
  "/gateways-with-device-info-with-pond-wise-data": ["VIEW_GATEWAYS"],
  "/gateways": {
    multi: true,
    get: ["VIEW_GATEWAYS"],
    post: ["UPDATE_GATEWAYS"]
  },

  "/resources/vendors": {
    multi: true,
    post: ["UPDATE_RESOURCES"],
    patch: ["UPDATE_RESOURCES"]
  },
  "/resources/vendors/:resource_vendor_id": {
    multi: true,
    patch: ["UPDATE_RESOURCES"],
    delete: ["DELETE_RESOURCES"]
  },
  "/resources": {
    multi: true,
    get: ["VIEW_RESOURCES"],
    post: ["UPDATE_RESOURCES"]
  },
  "/resources/:resource_id": {
    multi: true,
    delete: ["DELETE_RESOURCES"]
  },

  "/ponds/:pond_id/medicines-and-minerals": ["VIEW_MEDICINES"],

  "/ponds/harvests": {
    multi: true,
    get: ["VIEW_HARVESTS"],
    post: ["UPDATE_HARVESTS"]
  },
  "/ponds/harvests/:harvest_id": {
    multi: true,
    patch: ["UPDATE_HARVESTS"],
    delete: ["DELETE_HARVESTS"]
  },

  "/download-reports": ["VIEW_REPORTS"],

  "/ponds/medicines-and-minerals": {
    multi: true,
    post: ["UPDATE_MEDICINES"]
  },
  "/ponds/medicines-and-minerals/:medicine_and_mineral_id": {
    multi: true,
    delete: ["DELETE_MEDICINES"]
  },

  "/users/locations/:location_id": {
    multi: true,
    patch: ["UPDATE_LOCATIONS"],
    delete: ["DELETE_LOCATIONS"]
  },

  "/ponds/pond-mothers/:pond_mother_code/sensor-data-alerts": {
    multi: true,
    get: ["VIEW_POND_ALERTS"]
  },
  "/labs": {
    multi: true,
    get: ["VIEW_LABS"],
    post: ["UPDATE_LABS"]
  },
  "/labs/:lab_id": {
    multi: true,
    get: ["VIEW_LABS"],
    patch: ["UPDATE_LABS"],
    delete: ["DELETE_LABS"]
  },
  "/lab-tests": {
    multi: true,
    get: ["VIEW_LAB_TEST"],
    post: ["UPDATE_LAB_TEST"]
  },
  "/lab-tests/:lab_test_id": {
    multi: true,
    get: ["VIEW_LAB_TEST"],
    delete: ["DELETE_LAB_TEST"]
  }
};
