var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{on:{"mouseover":function($event){return _vm.handleNavStatus(true)},"mouseleave":function($event){return _vm.handleNavStatus(_vm.sidemove)}}},[_c('el-menu',{staticClass:"navigation-menu",attrs:{"default-openeds":_vm.openedSubItems},on:{"open":_vm.handleSubMenuOpen,"close":_vm.handleSubMenuClose}},[_c('el-scrollbar',{staticClass:"navigation-menu-scroll"},[_c('ul',{staticClass:"nav__header"},[_c('li',{staticClass:"company-logo"},[_c('router-link',{attrs:{"to":"/user/dashboard?tab=dashboardTab"}},[_c('pondlogs-logo',{staticClass:"menu-icon",attrs:{"color":"#0A2463"}})],1)],1),_vm._v(" "),_c('li',{staticClass:"nav__header-title"},[_c('router-link',{attrs:{"to":"/user/dashboard?tab=dashboardTab"}},[_c('p',[_vm._v("PondLogs")])])],1),_vm._v(" "),_c('li',{staticClass:"nav__checkbox"},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.freezethenavigation(_vm.checked)}}},[(this.checked)?_c('div',[_c('img',{ref:"lock",attrs:{"width":"25px;","height":"15px;","src":_vm.otherImage},on:{"mouseover":function($event){return _vm.setCloseNewImage()},"mouseleave":function($event){return _vm.setCloseOldImage()}}})]):_c('div',[_c('img',{ref:"unlock",attrs:{"width":"25px;","height":"15px;","src":_vm.myImage},on:{"mouseover":function($event){return _vm.setOpenNewImage()},"mouseleave":function($event){return _vm.setOpenOldImage()}}})])])],1)]),_vm._v(" "),_c('hr',{staticClass:"divider"}),_vm._v(" "),_c('sub-menu-overview'),_vm._v(" "),_c('sub-menu-culture'),_vm._v(" "),_c('sub-menu-manage'),_vm._v(" "),_c('router-link',{staticClass:"menu-item-wrapper help-section-menu-item  truncate",attrs:{"index":"Comn_help-section","to":{
          path: '/user/help',
          query: _vm.mergeQueryParams({ tab: 'releaseNotes' })
        }}},[_c('li',{staticClass:"el-menu-item el-menu-item-help",class:{
            'is-active': _vm.$route.meta.langKey === 'Comn_help-section'
          },attrs:{"index":"Comn_helpSection","to":{
            path: '/user/help',
            query: _vm.mergeQueryParams({ tab: 'releaseNotes' })
          }}},[_c('img',{attrs:{"src":require("@/assets/help/Help_icon.svg")}}),_vm._v(" "),(_vm.getSideNavStatus)?_c('p',{staticClass:"menu-title truncate"},[_vm._v("\n            "+_vm._s(_vm.$tc("Comn_help-section", 2))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('router-link',{staticClass:"menu-item-wrapper settings-menu-item truncate",attrs:{"index":"Comn_settings","to":{
          path: '/user/settings',
          query: _vm.mergeQueryParams({ tab: 'userProfile' })
        }}},[_c('li',{staticClass:"el-menu-item",class:{ 'is-active': _vm.$route.meta.langKey === 'Comn_settings' },attrs:{"index":"Comn_settings","to":{
            path: '/user/settings',
            query: _vm.mergeQueryParams({ tab: 'userProfile' })
          }}},[_c('img',{attrs:{"src":require("@/assets/navigation/nav-settings.svg")}}),_vm._v(" "),(_vm.getSideNavStatus)?_c('p',{staticClass:"menu-title truncate"},[_vm._v("\n            "+_vm._s(_vm.$tc("Comn_settings", 2))+"\n          ")]):_vm._e()])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }