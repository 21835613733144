<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondlogsLogo.vue
Description: This file is the pondlogs logo component that can be used all over the application
-->
<template>
  <svg
    width="31"
    height="25"
    viewBox="0 0 31 25"
    fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3338 13.7758C1.44743 13.7413 1.56398 13.7172 1.68199 13.7039C2.53775 13.6915 3.37968 13.4863 4.14518 13.1036C4.91067 12.7208 5.58001 12.1704 6.1034 11.4932C7.06506 10.2939 7.95487 9.03935 8.86679 7.82346C9.83191 6.46576 11.0271 5.28736 12.3984 4.34159C13.6292 3.54822 14.9946 2.98633 16.4274 2.68356C17.4829 2.49489 18.5575 2.43551 19.6274 2.50672C19.8927 2.50672 19.9811 2.64488 19.9811 2.90464C19.9811 5.21483 19.9811 7.52501 19.9811 9.83519C19.9811 10.0618 19.9203 10.1392 19.6771 10.1834C18.5121 10.3881 17.4388 10.9479 16.6043 11.7861C15.4461 12.9932 14.1705 14.0818 12.7963 15.0359C11.3512 16.0529 9.56993 16.4745 7.82223 16.2131C6.81089 16.0411 5.81874 15.7708 4.85988 15.4062C3.71031 14.953 2.60496 14.4003 1.49961 13.8974L1.3338 13.7758ZM9.97766 12.4936C9.98509 12.3414 9.96233 12.1893 9.91071 12.0459C9.85909 11.9026 9.77962 11.7709 9.67688 11.6584C9.57414 11.5459 9.45016 11.4548 9.31207 11.3904C9.17399 11.326 9.02453 11.2896 8.87231 11.2832H8.80599C8.50199 11.2664 8.20346 11.3691 7.97419 11.5695C7.74493 11.7698 7.60311 12.0519 7.57905 12.3554C7.57905 12.3554 7.57905 12.3941 7.57905 12.4162C7.54652 12.7247 7.63763 13.0334 7.8324 13.2748C8.02717 13.5162 8.30972 13.6705 8.61808 13.7039H8.73967C8.89249 13.7176 9.04651 13.701 9.19289 13.655C9.33927 13.609 9.47514 13.5346 9.59271 13.436C9.71029 13.3375 9.80725 13.2167 9.87805 13.0805C9.94885 12.9444 9.99209 12.7957 10.0053 12.6428C10.0108 12.5914 10.0108 12.5395 10.0053 12.4881L9.97766 12.4936Z"
      :fill="color"
    />
    <path
      d="M16.3391 22.7955C15.687 22.2428 15.0348 21.7896 14.449 21.2369C13.6422 20.4393 13 19.491 12.5588 18.4459C12.5431 18.3864 12.5424 18.3239 12.5569 18.2641C12.5714 18.2042 12.6005 18.149 12.6417 18.1032C13.3934 17.4787 14.1561 16.8763 14.974 16.2241C15.0404 16.4231 15.1067 16.5778 15.1454 16.7381C15.3495 17.7141 15.8098 18.6182 16.4791 19.3574C17.1483 20.0965 18.0023 20.6442 18.9533 20.944C19.2175 21.0355 19.487 21.1112 19.7602 21.1706C19.7965 21.1727 19.8321 21.182 19.8648 21.1978C19.8975 21.2137 19.9268 21.2359 19.9509 21.2631C19.975 21.2903 19.9936 21.322 20.0054 21.3564C20.0173 21.3908 20.0222 21.4272 20.02 21.4635C20.0226 21.4725 20.0226 21.4821 20.02 21.4911C20.02 22.5633 20.02 23.6355 20.02 24.7077C20.02 24.9288 19.9426 24.9951 19.727 24.9896C18.6991 24.9896 17.6656 25.0283 16.6431 24.9564C14.6198 24.8487 12.6628 24.1976 10.9782 23.0718C10.2707 22.5799 9.62965 22.0051 8.95539 21.469C8.76748 21.3143 8.76748 21.1927 8.95539 21.0269C9.45832 20.64 9.9502 20.2421 10.4255 19.8165C10.6742 19.5954 10.84 19.6341 11.05 19.8552C11.9318 20.7821 12.9822 21.5324 14.145 22.0659C14.7474 22.3423 15.383 22.5412 16.0075 22.7623C16.1162 22.7873 16.2277 22.7984 16.3391 22.7955Z"
      :fill="color"
    />
    <path
      d="M21.2413 6.47499C21.2413 5.36964 21.2413 4.23112 21.2413 3.10366C21.2413 2.83837 21.2855 2.71679 21.595 2.79969C22.7401 3.09772 23.8273 3.58527 24.8116 4.24217C25.9766 5.0257 27.0037 5.99683 27.8513 7.1161C28.0116 7.32611 27.9784 7.4477 27.8071 7.6135C26.6575 8.75201 25.5135 9.89605 24.3749 11.0456C24.2147 11.2059 24.0931 11.2722 23.8775 11.123C23.1507 10.6198 22.3159 10.2942 21.4402 10.1724C21.2136 10.1392 21.2523 9.99002 21.2523 9.84632V6.47499H21.2413Z"
      :fill="color"
    />
    <path
      d="M28.6638 8.49777C29.6249 10.2036 30.0846 12.1462 29.9902 14.1019C29.9515 14.9972 29.946 14.9972 29.0562 14.9972C28.1664 14.9972 27.2876 14.9972 26.4033 14.9972C26.1657 14.9972 26.0938 14.9309 26.0773 14.6822C26.008 13.8603 25.6964 13.0775 25.1819 12.4328C25.0051 12.2007 24.994 12.0625 25.2206 11.8415C26.2707 10.819 27.2932 9.77997 28.3267 8.74647C28.4341 8.65708 28.5467 8.57404 28.6638 8.49777Z"
      :fill="color"
    />
    <path
      d="M27.1162 21.3199C26.4032 20.5849 25.7345 19.9161 25.0878 19.2363C25.0381 19.1811 25.0878 19.0153 25.0878 18.9213C25.3752 18.3686 25.6903 17.816 25.9666 17.2191C26.0771 16.987 26.0827 16.7106 26.1766 16.473C26.1981 16.4184 26.2324 16.3698 26.2767 16.3313C26.3209 16.2928 26.3738 16.2656 26.4309 16.2519C27.4422 16.2519 28.4536 16.2519 29.4595 16.2519C29.6751 16.2519 29.7303 16.3348 29.6806 16.5448C29.3695 17.8465 28.8062 19.0745 28.0226 20.1593C27.7628 20.5296 27.4754 20.8833 27.1162 21.3199Z"
      :fill="color"
    />
    <path
      d="M21.2467 22.8177V21.3476C21.2467 21.1762 21.2799 21.0878 21.4844 21.0767C22.3174 21.0221 23.1148 20.7178 23.7724 20.2035C24.0211 20.0156 24.1925 19.9824 24.4301 20.2035C24.9496 20.723 25.4913 21.226 26.0439 21.7178C26.2761 21.9223 26.2208 22.0495 26.0108 22.2263C24.8385 23.2539 23.4531 24.0089 21.9541 24.437C21.2135 24.6415 21.2522 24.752 21.2522 23.9396V22.7845L21.2467 22.8177Z"
      :fill="color"
    />
    <path
      d="M0.00739313 9.35443C-0.0976154 8.38725 0.935885 7.48638 1.84227 7.4698C2.3424 7.46773 2.82413 7.65835 3.18742 8.00209C3.55071 8.34583 3.76765 8.81626 3.79322 9.31574C3.80514 9.82015 3.61723 10.3088 3.27043 10.6753C2.92363 11.0418 2.44606 11.2563 1.94176 11.2722H1.89755C1.3972 11.2722 0.917268 11.0739 0.562954 10.7206C0.20864 10.3673 0.00885612 9.88793 0.00739313 9.38759V9.35443Z"
      :fill="color"
    />
    <path
      d="M1.89772 3.12017C2.21899 3.10661 2.53264 3.2203 2.77062 3.43655C3.0086 3.65279 3.15171 3.95416 3.16888 4.27526V4.3858C3.15776 4.72182 3.01894 5.04095 2.7807 5.27816C2.54246 5.51538 2.22273 5.65282 1.88667 5.66248C1.55271 5.64252 1.23783 5.50011 1.00229 5.26253C0.766743 5.02495 0.627069 4.70885 0.609985 4.37474C0.6129 4.04099 0.747525 3.72191 0.984562 3.48694C1.2216 3.25198 1.54185 3.12015 1.87561 3.12017H1.89772Z"
      :fill="color"
    />
    <path
      d="M2.84806 1.8933C2.73156 1.90315 2.61427 1.88995 2.50288 1.85444C2.39148 1.81893 2.28818 1.76182 2.19888 1.68637C2.10957 1.61092 2.036 1.51861 1.98239 1.41472C1.92877 1.31082 1.89615 1.19737 1.8864 1.08086C1.88365 1.04223 1.88365 1.00345 1.8864 0.964807C1.87655 0.848309 1.88976 0.731012 1.92527 0.619621C1.96078 0.508229 2.01788 0.40493 2.09333 0.315622C2.16879 0.226313 2.2611 0.15274 2.36499 0.0991252C2.46889 0.04551 2.58233 0.0129066 2.69884 0.00315568H2.8149C3.06629 -0.0173653 3.31552 0.0628005 3.50779 0.226044C3.70005 0.389288 3.81959 0.622228 3.84012 0.873611C3.86064 1.12499 3.78045 1.37423 3.61721 1.5665C3.45397 1.75876 3.22103 1.87831 2.96965 1.89883L2.84806 1.8933Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"]
};
</script>

<style lang="scss" scoped>
</style>
