/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: adminDatesHandlerMixin.js
Description: This Mixin is useful for handling the common functionality related to the dates in pondlogs customer's site.
*/
import { mapGetters } from "vuex";
import dateUtils from "@/utils/dateUtils";
const datesHandlerMixin = {
  data: function() {
    return {
      dhm__dateUtilsLib: dateUtils
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    dhm__getTodayInUserTZ() {
      return this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
    },
    dhm__getUserTZStringObj() {
      return {
        timeZone: this.getUserTimeZoneString
      };
    },
    dhm__castUserUTCToUTCISO: function() {
      return dateISO => {
        return this.dhm__dateUtilsLib.formatUserUTCISOToUTCISO(
          dateISO,
          this.getUserTimeZoneString
        );
      };
    },
    dhm__castUserUTCToUTCDateObj: function() {
      return dateISO => {
        return this.dhm__dateUtilsLib.zonedTimeToUtc(
          this.dhm__castUserUTCToUTCISO(dateISO),
          this.getUserTimeZoneString
        );
      };
    },
    dhm__getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    }
  },
  methods: {
    dhm__toISOString(dateObj) {
      return this.dhm__dateUtilsLib.toISOString(dateObj);
    },
    dhm__formatByTZOnUTCWithLocale(userUTCdateISO, format) {
      return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        this.dhm__castUserUTCToUTCISO(userUTCdateISO),
        format,
        this.getUserTimeZoneString,
        this.dhm__getLocaleObj
      );
    },
    dhm__formatByTZOnUTCISOWithLocale(dateISO, format) {
      return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        dateISO,
        format,
        this.getUserTimeZoneString,
        this.dhm__getLocaleObj
      );
    },
    dhm__formatOnDateObjWithLocale(date, format) {
      return this.dhm__dateUtilsLib.formatDate(date, format, {
        locale: this.dhm__dateUtilsLib.localesMap[this.$i18n.locale]
      });
    },
    dhm__formatByTZOnDate(date, format) {
      return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
        this.dhm__dateUtilsLib.toISOString(date),
        format,
        this.getUserTimeZoneString,
        this.dhm__getLocaleObj
      );
    },
    dhm__formatTZ(date, format) {
      return this.dhm__dateUtilsLib.formatTZ(date, format, {
        timeZone: this.getUserTimeZoneString,
        locale: this.dhm__dateUtilsLib.localesMap[this.$i18n.locale]
      });
    }
  }
};

export default datesHandlerMixin;
