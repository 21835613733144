<template>
  <el-container class="er-empty-container" direction="vertical">
    <slot>
      <span class="er-empty__image-wrapper">
        <slot name="image">
          <img class="no-data__img" src="@/assets/no_data.svg" />
        </slot>
      </span>
      <span class="er-empty__text-wrapper">
        <slot name="title">
          <h4>{{ ftm__capitalize($t("Comn_no_data_connection")) }}</h4>
        </slot>
      </span>
    </slot>
  </el-container>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [filtersMixin]
};
</script>

<style lang="scss" scoped>
.er-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  .er-empty__image-wrapper img {
    width: 50px;
    height: 50px;
  }
  .er-empty__text-wrapper {
    @include responsiveProperty(font-size, 11px, 11px, 0.8em);
  }
}
</style>
