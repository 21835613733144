<template>
  <div class="el-image" v-loading="loading">
    <slot v-if="error" name="error">
      <div class="el-image__error">{{ $t("el.image.error") }}</div>
    </slot>
    <img
      class="el-image__inner"
      v-show="!error"
      v-bind="$attrs"
      v-on="$listeners"
      @click="clickHandler"
      :data-src="src"
      :style="imageStyle"
      ref="imageTag"
      :class="{
        'el-image__inner--center': alignCenter
      }"
    />
  </div>
</template>

<script>
import lozad from "lozad";
const isSupportObjectFit = () =>
  document.documentElement.style.objectFit !== undefined;

const ObjectFit = {
  NONE: "none",
  CONTAIN: "contain",
  COVER: "cover",
  FILL: "fill",
  SCALE_DOWN: "scale-down"
};

export default {
  name: "ElImage",
  inheritAttrs: false,
  props: {
    src: String,
    fit: String,
    lazy: Boolean,
    zIndex: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      show: !this.lazy,
      imageWidth: 0,
      imageHeight: 0
    };
  },
  computed: {
    imageStyle() {
      const { fit } = this;
      if (fit) {
        return isSupportObjectFit()
          ? { "object-fit": fit }
          : this.getImageStyle(fit);
      }
      return {};
    },
    alignCenter() {
      return (
        !this.$isServer && !isSupportObjectFit() && this.fit !== ObjectFit.FILL
      );
    }
  },
  watch: {
    src(val) {
      this.show && this.loadImage();
    },
    show(val) {
      val && this.loadImage();
    }
  },

  async mounted() {
    await this.loadImage();
  },
  methods: {
    handleLoad(e, img) {
      this.imageWidth = img.width;
      this.imageHeight = img.height;
      this.loading = false;
      this.error = false;
    },
    handleError(e) {
      this.loading = false;
      this.error = true;
      this.$emit("error", e);
    },
    async loadImage() {
      await this.$nextTick();
      const componentScope = this;
      const observer = lozad(this.$refs.imageTag, {
        enableAutoReload: true,
        load: function(el) {
          el.onload = e => componentScope.handleLoad(e, el);
          el.onerror = componentScope.handleError;
          el.src = el.getAttribute("data-src");
        }
      });
      this.loading = true;
      observer.observe();
    },
    /**
     * simulate object-fit behavior to compatible with IE11 and other browsers which not support object-fit
     */
    getImageStyle(fit) {
      const { imageWidth, imageHeight } = this;
      const {
        clientWidth: containerWidth,
        clientHeight: containerHeight
      } = this.$el;

      if (!imageWidth || !imageHeight || !containerWidth || !containerHeight) {
        return {};
      }

      const imageAspectRatio = imageWidth / imageHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      if (fit === ObjectFit.SCALE_DOWN) {
        const isSmaller =
          imageWidth < containerWidth && imageHeight < containerHeight;
        fit = isSmaller ? ObjectFit.NONE : ObjectFit.CONTAIN;
      }

      switch (fit) {
        case ObjectFit.NONE:
          return { width: "auto", height: "auto" };
        case ObjectFit.CONTAIN:
          return imageAspectRatio < containerAspectRatio
            ? { width: "auto" }
            : { height: "auto" };
        case ObjectFit.COVER:
          return imageAspectRatio < containerAspectRatio
            ? { height: "auto" }
            : { width: "auto" };
        default:
          return {};
      }
    },
    clickHandler() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped></style>
