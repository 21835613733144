/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/

import { $axios } from "@/config/axios";
import { cloneDeep } from "lodash";
import { alphaNumericComparator } from "@/utils/commonUtils";

import dateUtils from "@/utils/dateUtils";
// import { startOfWeek } from "date-fns";

/**
File Name: stock.js
Description: This file contains all the vuex store functions used in stock page
*/

const state = {
  dialogBoxVisibleStatus: false,
  expandstocks: [],
  stocks: [],
  medicines: [],
  individualstockData: [],
  individualmedicalData: []
};
const getters = {
  getStateOfDialogBox: function(state) {
    return state.dialogBoxVisibleStatus;
  },

  getAllFeeds: function(state) {
    const stocks = cloneDeep(state.stocks);
    const resStocks = stocks.filter(item => item.show_resource && item.status);

    return resStocks.sort((a, b) => alphaNumericComparator(a.name, b.name));
  },
  getAllMedicines: function(state) {
    const medicines = cloneDeep(state.medicines);
    const resMedicines = medicines.filter(
      item => item.show_resource && item.status
    );
    return resMedicines.sort((a, b) => alphaNumericComparator(a.name, b.name));
  },
  getObjOfAllMedicines: function(state, getters) {
    return getters.getAllMedicines.reduce((acc, currMed) => {
      acc[currMed._id] = currMed;
      return acc;
    }, {});
  },

  getObjofAllFeeds: function(state, getters) {
    return getters.getAllFeeds.reduce((acc, currfeed) => {
      acc[currfeed._id] = currfeed;
      return acc;
    }, {});
  },
  getIndividualStockSummary: function(state) {
    const individualFeedLog = cloneDeep(
      state.individualstockData.resource.stock
    );
    // const stock_units = cloneDeep(state.individualstockData.resource.units);
    return individualFeedLog.sort((a, b) =>
      dateUtils.dateComparator(b.date, a.date, true)
    );
  },
  getIndividualMedicalSummary: function(state) {
    const individualMedicineLog = cloneDeep(
      state.individualmedicalData.resource.stock
    );
    const medicine_units = cloneDeep(
      state.individualmedicalData.resource.units
    );
    individualMedicineLog.map(item => {
      item.units = medicine_units;
      return item;
    });
    return individualMedicineLog.sort((a, b) =>
      dateUtils.dateComparator(b.date, a.date, true)
    );
  },
  getChangedAllFeeds: function(state) {
    return state.feedobject;
  },
  getChangedAllMedicines: function(state) {
    return state.medicineobject;
  }
};
const mutations = {
  SET_DIALOG_BOX_VISIBLE_STATUS(state, isDialogBoxVisibleStatus) {
    state.dialogBoxVisibleStatus = isDialogBoxVisibleStatus;
  },

  SET_FEEDS_DATA(state, stocks) {
    state.stocks = stocks;
  },
  SET_MEDICINE_DATA(state, medicineData) {
    state.medicines = medicineData;
  },
  SET_FEEDS_CHANGED_DATA(state, feedobject) {
    state.feedobject = feedobject;
  },
  SET_MEDICINE_CHANGED_DATA(state, medicineobject) {
    state.medicineobject = medicineobject;
  },
  SET_INDIVIDUAL_FEED_STOCKS_DATA(state, individualstockData) {
    state.individualstockData = individualstockData;
  },
  SET_INDIVIDUAL_MEDICAL_STOCKS_DATA(state, individualmedicalData) {
    state.individualmedicalData = individualmedicalData;
  }
};
const actions = {
  changeDialogBoxVisibleStatus({ commit }, isDialogBoxVisibleStatus) {
    commit("SET_DIALOG_BOX_VISIBLE_STATUS", isDialogBoxVisibleStatus);
  },

  fetchFeedStocks: async (context, params = {}) => {
    const response = await $axios.get("stock/feed", { params });
    console.log("response fetchFeedStocks", response);
    context.commit("SET_FEEDS_DATA", response.data.resources_stock);
  },
  fetchMedicineStocks: async (context, params = {}) => {
    const response = await $axios.get("stock/medicine", { params });
    context.commit("SET_MEDICINE_DATA", response.data.resources_stock);
  },

  fetchIndividualStockSummary: async (context, { id, ...params }) => {
    const response = await $axios.get(`stock/feed/${id}`, { params });
    context.commit("SET_INDIVIDUAL_FEED_STOCKS_DATA", response.data);
    // context.commit("SET_FEEDS_CHANGED_DATA", response.data.resource);
    return response;
  },

  fetchIndividualMedicineLog: async (context, { id, ...params }) => {
    const response = await $axios.get(`stock/medicine/${id}`, {
      params
    });
    context.commit("SET_INDIVIDUAL_MEDICAL_STOCKS_DATA", response.data);
    // context.commit("SET_MEDICINE_CHANGED_DATA", response.data.resource);
    return response;
  },

  createStock: async (context, payload) => {
    const response = await $axios.post("stock/feed", payload);
    return response;
  },
  createMedicine: async (context, payload) => {
    const response = await $axios.post("stock/medicine", payload);
    return response;
  },
  deleteStock: async (context, resource_id) => {
    const response = await $axios.delete(`stock/feed/${resource_id}`);
    return response;
  },
  deleteMedicineStock: async (context, resource_id) => {
    const response = await $axios.delete(`stock/medicine/${resource_id}`);
    return response;
  },
  updateFeedStock: async (context, payload) => {
    await $axios.patch(`stock/feed/${payload._id}`, payload);
  },
  updateMedicine: async (context, payload) => {
    await $axios.patch(`stock/medicine/${payload._id}`, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
