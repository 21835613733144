/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: authRoutes.js
Description: This file contains the router configuration useful for handling the navigation of user registration process of the pondlogs site.
*/
import authMiddleware from "@/middleware/auth";
import indexAuthView from "@/views/auth/indexAuthView";

export const signIn = () =>
  import(
    /* webpackChunkName: "userSignUpAndSignInFunctions" */ "@/components/auth/signIn"
  );
export const signUp = () =>
  import(
    /* webpackChunkName: "userSignUpAndSignInFunctions" */ "@/components/auth/signUp"
  );
export const forgotPassword = () =>
  import(
    /* webpackChunkName: "userConfirmAndPasswordsFunctions" */ "@/components/auth/forgotPassword"
  );
export const resetPassword = () =>
  import(
    /* webpackChunkName: "userConfirmAndPasswordsFunctions" */ "@/components/auth/resetPassword"
  );
export const resendEmail = () =>
  import(
    /* webpackChunkName: "userConfirmAndPasswordsFunctions" */ "@/components/auth/resendEmail"
  );
export const signUpStatus = () =>
  import(
    /* webpackChunkName: "userConfirmAndPasswordsFunctions" */ "@/components/auth/signUpStatus"
  );

export default {
  path: "/",
  component: indexAuthView,
  children: [
    {
      path: "/",
      alias: "sign-in",
      component: signIn,
      name: "signIn"
    },
    {
      path: "sign-up",
      component: signUp
    },
    {
      path: "forgot-password",
      component: forgotPassword
    },
    {
      path: "reset-password",
      component: resetPassword
    },
    {
      path: "resend-email",
      component: resendEmail
    },
    {
      path: "/sign-up-status",
      name: "SignUpStatus",
      component: signUpStatus,
      props: true
    },
    {
      path: "/password/reset",
      redirect: "/reset-password"
    }
  ],
  beforeEnter: authMiddleware.isUnAuthenticatedUser
};
