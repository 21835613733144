<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erNoPonds.vue
Description: This file is the component useful for representing the no ponds state when user does not created any ponds.
-->
<template>
  <el-row class="no-location">
    <p id="info">
      {{ $t("Usrs_donot_have_ponds_in_loc_click_here_to") }}
    </p>
    <er-button
      btnType="circle_add"
      :showLabel="true"
      :showIcon="true"
      size="mini"
      @click="handleAddPondClick"
      :disabled="disabled"
    >
      <slot>
        <p style="text-transform: uppercase">{{ $t("Comn_add_pond") }}</p>
      </slot>
    </er-button>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";

export default {
  name: "NoPonds",
  mixins: [errorHandlerMixin, redirectsMixin],
  props: {
    tabData: {
      default: () => ({})
    },
    disabled: {
      default: false
    }
  },
  methods: {
    handleAddPondClick() {
      try {
        // this.$gblUAMCanUserEdit(this.tabData);
        this.rdm__navigateToPathWithLocationId({ path: "map-view" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.no-location {
  display: flex;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
  background: white;
  #info {
    margin-right: 8px;
    margin-left: 16px;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-button {
    p {
      text-transform: uppercase;
    }
  }
}
</style>
