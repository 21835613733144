/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Schedule
*/
import { $axios } from "../config/axios";

export default {
  fetchAScheduleByPondId({ pondId, params }) {
    return $axios.get(`/ponds/${pondId}/schedules`, { params });
  },
  fetchAllPMsInAllPondsByScheduleStatus(pondIds, status) {
    return $axios.get(
      `/ponds/schedules/list-pond-mothers-from-schedules-based-on-status`,
      {
        params: {
          pond_ids: pondIds,
          status
        }
      }
    );
  }
};
