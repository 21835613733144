/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2022
**/
/**
File Name: adminReleaseNotes.js
Description: This file contains all API service calls in relation to Ponds
*/
import { $superAdminAxios } from "@/config/axios";

export default {
  fetchReleaseList(params) {
    return $superAdminAxios.get("admin/ui-versions", { params });
  }
};
