/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to ShrimpTalk
*/
import { $axios } from "@/config/axios";

export default {
  // pgs will be repeated in the payload
  fetchStStatsByPondIdAndStId(pondId, stId, params) {
    return $axios.get(`/ponds/${pondId}/shrimp-talks/${stId}/statistics-data`, {
      params
    });
  },
  fetchStDetailsByPondId(pondId, params) {
    return $axios.get(`/ponds/${pondId}/shrimp-talks`, {
      params
    });
  },
  addPondMotherToShrimpTalk(stId, params) {
    return $axios.post(`ponds/shrimp-talks/${stId}/pond-mothers`, params);
  },
  removePondMotherFromShrimpTalk(stId, params) {
    return $axios.delete(`ponds/shrimp-talks/${stId}/pond-mothers`, {
      data: params
    });
  },
  fetchStReponseQuality(pond_id, params) {
    return $axios.post(`ponds/response-quality-stats`, {
      ...params,
      pond_id
    });
  },
  fetchHourlyDataUsingStoreService(pond_id, params) {
    return $axios.post(`ponds/hourly-response-quality-stats`, {
      ...params,
      pond_id
    });
  },
  fetchHourlyFeedVsResponse(params) {
    return $axios.post(`/shrimp-talks/hourly-feed-vs-response`, {
      ...params
    });
  }
};
