<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: subMenuManage.vue
Description: This file is the part of navigation menu which contains menu items related to manage ponds module
-->
<template>
  <el-submenu index="Comn_manage" v-if="canShowSubMenuItem">
    <template slot="title">{{ $t("Comn_manage") }}</template>
    <router-link
      v-for="(item, key) in filterMenuItemsByPermissions"
      :key="key"
      :to="item.route"
      class="menu-item-wrapper"
    >
      <li
        class="el-menu-item"
        :class="{ 'is-active': $route.meta.langKey === item.langKey }"
        :index="item.langKey"
        :route="item.route"
      >
        <img class="menu-image" :src="renderMenuIcon(item.imgKey)" />
        <p class="menu-title truncate" v-if="getSideNavStatus">
          {{ $t(item.langKey) }}
        </p>
      </li>
    </router-link>
  </el-submenu>
</template>

<script>
import { mapGetters } from "vuex";
import renderMenuIcon from "./renderMenuIcon";
import {
  permissionsToStringMap as permissions,
  userTypes
} from "@/middleware/pageAccessManager";

export default {
  computed: {
    ...mapGetters("header", {
      getSideNavStatus: "getSideNavStatus"
    }),
    menuItems: function () {
      const getRouteObject = (path, newQuery = {}) => {
        return {
          path,
          query: { location_id: this.$route.query.location_id, ...newQuery }
        };
      };
      return {
        // STOCKS: {
        //   langKey: "Stock_stock",
        //   route: "/user/stock?tab=feed",
        //   imgKey: "STOCKS",
        //   keyToValidateAccess: "PERMISSIONS",
        //   PERMISSIONS: [permissions.MANAGE_STOCK]
        // },
        GATEWAY: {
          langKey: "Comn_gateway",
          route: getRouteObject("/user/gateway", { tab: "deviceInfoTab" }),
          imgKey: "GATEWAY",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [
            permissions.MANAGE_CULTURE,
            permissions.MANAGE_SCHEDULES,
            permissions.MANAGE_DEVICES
          ]
        },
        REPORTS: {
          langKey: "Comn_reports",
          route: getRouteObject("/user/reports"),
          imgKey: "REPORTS",
          keyToValidateAccess: "USER_TYPES",
          USER_TYPES: [
            userTypes.admin,
            userTypes.pond_manager,
            userTypes.pond_owner
          ]
        },
        RESOURCES: {
          langKey: "Comn_resources",
          route: getRouteObject("/user/resources", { tab: "resourcesTab" }),
          imgKey: "RESOURCES",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [
            permissions.MANAGE_MEDICINE,
            permissions.MANAGE_SCHEDULES, // removed lab-test
            permissions.MANAGE_STOCK,
            permissions.MANAGE_LAB_TESTS
          ]
        }
      };
    },
    filterMenuItemsByPermissions: function () {
      return this.$gblUAMFilterItemsUserCanView(this.menuItems);
    },
    canShowSubMenuItem: function () {
      return Object.keys(this.filterMenuItemsByPermissions).length > 0;
    }
  },
  methods: {
    renderMenuIcon
  }
};
</script>

<style></style>
