/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: auth.js
Description: This file contains the functions related to the authentication, saving auth information in local storage, check the authorization of the user.
*/
import store from "@/store/index";
import localStore from "@/utils/localStore";
import { userTypes } from "@/middleware/pageAccessManager";
import {
  $axios,
  $superAdminAxios,
  $superDealerAxios,
  $superAccountAxios,
  $superSkrettingTechnicianAxios
} from "@/config/axios";

const nonAuthCheckingRoutes = ["emailVerify"];

export const auth = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
  } else {
    next({ path: "/sign-in" });
  }
};
export const isUnAuthenticatedUser = (to, from, next) => {
  if (nonAuthCheckingRoutes.indexOf(to) !== -1) {
    next();
  } else if (store.getters["auth/isAuthenticated"]) {
    next({ path: "/user" });
  } else {
    next();
  }
};
export const saveUserDetailsToLocalStorage = userProfile => {
  const userId = userProfile._id;
  const userType = userProfile.user_type;
  // alert("Setting it to false auth");
  // localStorage.setItem("AlertHistory", false);
  localStore.setItem("curr-user-type", userType);
  localStore.setItem(`${userType}-id`, userId);
  localStore.setItem(`${userType}-${userId}-trites`, userProfile);
};
export const saveAccessTokenToLocalStorage = accessTokens => {
  const userType = localStore.getItem("curr-user-type");
  const userId = localStore.getItem(`${userType}-id`);
  localStore.setItem(`${userType}-${userId}`, accessTokens);
  if (userType === userTypes.super_admin) {
    $superAdminAxios.defaults.headers.common.Authorization =
      accessTokens.access_token;
  } else if (userType === userTypes.dealer) {
    $superDealerAxios.defaults.headers.common.Authorization =
      accessTokens.access_token;
  } else if (userType === userTypes.account_manager) {
    $superAccountAxios.defaults.headers.common.Authorization =
      accessTokens.access_token;
  } else if (userType === userTypes.skretting_technician) {
    $superSkrettingTechnicianAxios.defaults.headers.common.Authorization =
      accessTokens.access_token;
  } else {
    $axios.defaults.headers.common.Authorization = accessTokens.access_token;
  }
};
export const setImpersonation = newStatus => {
  localStore.setItem("is-impersonation", newStatus);
};

export const setImpersonationBy = newStatus => {
  localStore.setItem("is-impersonation-by", newStatus);
};
export const getImpersonationBy = () => {
  return localStore.getItem("is-impersonation-by");
};

export const getImpersonation = () => {
  return localStore.getItem("is-impersonation");
};
export const clearUserLocalStorage = userProfile => {
  const userId = userProfile._id;
  const userType = userProfile.user_type;
  const isImpersonation = localStore.getItem("is-impersonation");
  localStore.removeItem(`${userType}-id`);
  localStore.removeItem(`${userType}-${userId}`);
  localStore.removeItem(`${userType}-${userId}-trites`);
  if (!isImpersonation) {
    localStore.removeItem("curr-user-type");
  } else if (userType === userTypes.super_admin) {
    localStore.removeItem("is-impersonation");
    localStore.removeItem("is-impersonation-by");
    localStore.removeItem("curr-user-type");
  } else if (userType === userTypes.dealer) {
    localStore.removeItem("is-impersonation");
    localStore.removeItem("is-impersonation-by");
    localStore.removeItem("curr-user-type");
  } else if (userType === userTypes.account_manager) {
    localStore.removeItem("is-impersonation");
    localStore.removeItem("is-impersonation-by");
    localStore.removeItem("curr-user-type");
  } else if (userType === userTypes.skretting_technician) {
    localStore.removeItem("is-impersonation");
    localStore.removeItem("is-impersonation-by");
    localStore.removeItem("curr-user-type");
  }
};

export default {
  auth,
  isUnAuthenticatedUser,
  saveAccessTokenToLocalStorage,
  clearUserLocalStorage,
  saveUserDetailsToLocalStorage
};
