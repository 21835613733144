<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: indexAdmin.vue
Description:This file is main file when Admin login and gives the functionality over admin login.And adminHeader is the child component of indexAdmin.vue
-->
<template>
  <el-container
    class="admin-container"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-header class="header__nav">
      <admin-header class="header--padding"></admin-header>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>
<script>
import adminHeader from "@/components/superadmin/adminHeader";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [errorHandlerMixin],
  components: {
    adminHeader
  },
  data: function() {
    return {
      showControl: true,
      loading: false
    };
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/signOut");
      this.$router.push("/");
    },
    toggleNavigation: function() {
      this.showControl = !this.showControl;
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.$store.dispatch("superadmin/fetchUserProfile");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.admin-container {
  display: block;
  $navigation-width-onopen: 200px;
  $navigation-width-onclose: 64px;
  box-sizing: border-box;
  //   height: 100vh;
  overflow: hidden;
  .header__nav {
    height: var(--header-height) !important;
    position: relative;
    border-bottom: none;
    margin-bottom: 5px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .header--padding {
    margin-left: 10px;
    margin-right: 10px;
  }
  .el-header {
    padding: 0px;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .material-icons-round {
    font-size: 15px;
  }
}
</style>
