<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erLogo.vue
Description:  This file contains the pondlogs logo
-->
<template>
  <img class="image__eruvaka--100" src="@/assets/eruvaka_icon.png" />
</template>

<script>
export default {};
</script>

<style lang="scss">
.image__eruvaka--100 {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
</style>
