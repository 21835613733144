/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: table.js
Description: This file contains the constants used for table configurations
*/
export default {
  "pagination-props": {
    small: true,
    background: true,
    pageSizes: [10, 30, 50, 100],
    popperClass: "er-data-tables__pagination-page-selector",
    layout: "prev,pager,next,jumper,sizes,total",
    class:
      "el-pagination is-background el-pagination--small pagination-without-slot"
  },
  "pagination-props-with-slot": {
    small: true,
    background: true,
    pageSizes: [10, 30, 50, 100],
    popperClass: "er-data-tables__pagination-page-selector",
    layout: "slot,->,prev,pager,next,jumper,sizes,total",
    class:
      "el-pagination is-background el-pagination--small pagination-with-slot"
  },
  "small-table-pagination-props": {
    class: "pagination-eruvaka",
    small: true,
    popperClass: "pagination-page-selector",
    pageSizes: [5, 10, 30, 50, 100]
  },
  "page-size": 30,
  "page-size-5": 5
};
