var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"server-side-pagination-table"},[_c('data-tables-server',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"dataTable",class:_vm.computedTableTypeClass,attrs:{"data":_vm.data,"total":_vm.total,"size":_vm.size,"page-size":_vm.$constants.table['page-size'],"pagination-props":_vm.$constants.table['pagination-props'],"table-props":_vm.computedTableProps,"element-loading-background":"white"},on:{"cell-click":_vm.handleCellClick,"current-page-change":_vm.currentPageChange,"query-change":_vm.onQueryChange}},[_c('template',{slot:"empty"},[_c('img',{staticClass:"no-data__img",attrs:{"src":require("@/assets/no_data.svg")}}),_vm._v(" "),_c('span',{staticClass:"no-data__text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Comn_no_data_connection")))])])]),_vm._v(" "),_vm._l((_vm.columns),function(column,key){return [_c('el-table-column',{directives:[{name:"show",rawName:"v-show",value:(column.visible),expression:"column.visible"}],key:("" + key),attrs:{"prop":column.prop,"label":column.label,"sortable":column.sortable || false,"width":column.width || '',"min-width":column.minWidth || ''},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_vm._t(((key.toLowerCase()) + "_header"),[_vm._v("\n            "+_vm._s(column.label)+"\n            "+_vm._s(column.sub_label)+"\n          ")],{"header":{
              column: scope.column,
              $index: scope.$index,
              label: column.label,
              sub_label: column.sub_label || ''
            }})]}},{key:"default",fn:function(scope){return [_vm._t(key.toLowerCase(),[(
                _vm.getRowValue(scope.row, column.prop, column.type).type ===
                  'array'
              )?_vm._l((_vm.getRowValue(
                  scope.row,
                  column.prop,
                  column.type
                ).value),function(val,tagIndex){return _c('el-tag',{key:tagIndex,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(val))])}):[_vm._v(_vm._s(_vm.getRowValue(scope.row, column.prop, column.type).value))]],{"row":{
              data: scope.row,
              path: column.prop,
              $index: scope.$index
            }})]}}],null,true)},[_vm._v(" "),_vm._v(" "),_vm._t(((key.toLowerCase()) + "_NestedColumn"))],2)]}),_vm._v(" "),_vm._t("actionColumn",[(_vm.actionColumn)?_c('el-table-column',{attrs:{"label":"Actions"}},[_c('el-button',{attrs:{"icon":"el-icon-setting","size":"small","disabled":true}})],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }