import Fuse from "fuse.js";
const searchMixin = {
  data() {
    return {
      sm__arrayOfWatches: []
    };
  },
  computed: {
    sm__getDefaultSearchOptions() {
      return {
        isCaseSensitive: false,
        includeScore: true,
        useExtendedSearch: true,
        shouldSort: true,
        includeMatches: false,
        findAllMatches: true,
        minMatchCharLength: 1,
        location: 0,
        threshold: 0.3,
        distance: 100,
        ignoreLocation: false,
        ignoreFieldNorm: true
      };
    }
  },
  beforeDestroy() {
    this.sm__unregisterWatchers();
  },
  methods: {
    sm__initFuseSearch(data, keys, extendOptions = {}) {
      const getOptions = options => {
        return { ...this.sm__getDefaultSearchOptions, ...options };
      };
      const fuseOptions = {
        ...getOptions(extendOptions),
        keys
      };
      return new Fuse(data, fuseOptions);
    },
    sm__generateHighlightArray(searchResult, matchIndexArr) {
      const length = searchResult.value.length;
      const value = searchResult.value;
      const highlightObjGenerator = (value, highlight) => {
        return { value: value.replace(" ", "&nbsp;"), highlight };
      };
      if (matchIndexArr[0] === 0) {
        // matches at start
        return [
          highlightObjGenerator(
            value.slice(matchIndexArr[0], matchIndexArr[1] + 1),
            true
          ),
          highlightObjGenerator(
            value.slice(matchIndexArr[1] + 1, length),
            false
          )
        ];
      }
      if (matchIndexArr[1] + 1 === length) {
        // matches at end
        return [
          highlightObjGenerator(value.slice(0, matchIndexArr[0]), false),
          highlightObjGenerator(value.slice(matchIndexArr[0], length), true)
        ];
      }
      // matches in middle
      return [
        highlightObjGenerator(value.slice(0, matchIndexArr[0]), false),
        highlightObjGenerator(
          value.slice(matchIndexArr[0], matchIndexArr[1] + 1),
          true
        ),
        highlightObjGenerator(value.slice(matchIndexArr[1] + 1, length), false)
      ];
    },
    sm__getFilterDataBy(
      FuseObj,
      searchString,
      extendSearchFunc,
      processSeachResults
    ) {
      const getPattern = function(searchString, extendSearchFunc) {
        if (extendSearchFunc) {
          return extendSearchFunc(searchString);
        }
        return `'"${searchString}"`;
      };
      const results = FuseObj.search(
        getPattern(searchString, extendSearchFunc)
      );
      if (processSeachResults) return processSeachResults(results);
      return results;
    },
    sm__registerWatcherToInitFuseOn(expression, callback) {
      this.sm__arrayOfWatches.push(this.$watch(expression, callback));
    },
    sm__unregisterWatchers() {
      this.sm__arrayOfWatches.forEach(unwatchFunc => {
        unwatchFunc();
      });
    }
  }
};

export default searchMixin;
