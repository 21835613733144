/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to PondGuard
*/
import { $axios } from "@/config/axios";

export default {
  // pgs will be repeated in the payload
  fetchPGsUsedInCurrCulture: function(pondId, params) {
    return $axios.get(`ponds/${pondId}/pond-guards-in-current-culture`, {
      params
    });
  }
};
