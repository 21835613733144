<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erTopRouteNav.vue
Description: This file is component which change the tabs based on the width of the screen to the dropdown of items
-->
<template>
  <el-row>
    <!-- <el-col :lg="15"> -->
      <el-menu mode="horizontal" :class="menuTypesToCssClass[type]">
        <li
          v-for="(tab, key) in items"
          :key="key"
          class="el-menu-item hidden-md-and-down"
          :class="{ 'is-active': isActive(tab.name) }"
          @click.stop="handleSelectedClick($event, tab)"
        >
          <slot name="lg-up-menu-item" :tab="tab">
            <router-link
              :to="{ query: baseUrlFormatter({ tab: tab.name }) }"
              v-if="isRouter"
            >
              <p class="menu-title truncate">
                <slot name="lg-up-menu-item.title" :tab="tab">
                  {{ $t(tab.label) }}
                </slot>
              </p>
            </router-link>
          </slot>
        </li>

        <el-submenu
          index="main"
          class="hidden-lg-and-up"
          popper-class="er-top-route-nav--menu-popup"
        >
          <template slot="title">
            <slot name="md-down-menu-item.selected.title">
              {{
                items[$route.query.tab]
                  ? $t(items[$route.query.tab].label)
                  : "NO LABEL"
              }}
            </slot>
          </template>
          <li
            v-for="(tab, key) in items"
            :key="key"
            class="el-menu-item"
            :class="{ 'is-active': isActive(tab.name) }"
            @click.stop="handleSelectedClick($event, tab)"
          >
            <slot name="md-down-menu-item" :tab="tab">
              <router-link :to="{ query: baseUrlFormatter({ tab: tab.name }) }">
                <p class="menu-title truncate">
                  <slot name="md-down-menu-item.title" :tab="tab">
                    {{ $t(tab.label) }}
                  </slot>
                </p>
              </router-link>
            </slot>
          </li>
        </el-submenu>
      </el-menu>
    <!-- </el-col> -->
  </el-row>
</template>

<script>
const menuTypesToCssClass = {
  navigationMenu: "nav-menu",
  tabs: "type-tab"
};
export default {
  props: {
    items: {
      default: () => [],
      required: true
    },
    isRouter: {
      default: true
    },
    isActiveComparator: {
      default: undefined
    },
    type: {
      type: String,
      default: "navigationMenu"
    }
  },
  data: function() {
    return {
      menuTypesToCssClass
    };
  },
  methods: {
    baseUrlFormatter(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      };
    },
    isActive(name) {
      return this.isActiveComparator
        ? this.isActiveComparator(name)
        : this.$route.query.tab === name;
    },
    handleSelectedClick(event, tab) {
      if (!event.ctrlKey) {
        this.$emit("selected", tab);
      }
    }
  }
};
</script>

<style lang="scss">
.er-top-route-nav--menu-popup {
  a.router-link-active {
    color: #113168;
  }
}
.el-menu--horizontal.el-menu.type-tab {
  margin-bottom: 15px;
  display: inline-block;
  border-radius: 5px;
  a.router-link-active {
    color: #113168;
  }
  & > .el-submenu {
    padding: 0px 10px;
    .el-submenu__title {
      height: var(--header-height);
      line-height: var(--header-height);
      color: #0a2463;
      font-weight: bold;
      border-bottom: 2px solid #0a2463;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
  }
  > .el-menu-item {
    text-align: center;
    line-height: 40px;
    height: 38px;
    color: #0a2463;
    opacity: 0.8;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    a {
      text-decoration: none;
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &.is-active {
      font-weight: 600;
      opacity: 1;
      border-bottom: 2px solid #0a2463;
    }
  }
}
</style>
