/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:pondMotherTS.js
Description: This file contains the model, functions of pond mother timeslots used in the pondlogs customer site
*/
import {
  objectIdGenerator,
  timeStrHHmmVal,
  castSecsHHmmStr,
  getType as cmGetType
} from "@/utils/commonUtils";
import {
  calcPMScheduleEndTime,
  calcMaxTFForADay,
  calcFG,
  calcTF,
  suggestOCFForPm
} from "@/utils/scheduleParamsCalculationUtils";
import {
  INIT_OCF_VALUE,
  PM_TS_STATUS,
  MAX_OCF_VALUE,
  MIN_FEED_GAP_VALUE,
  MIN_TOTAL_FEED_VALUE_ST_MODE,
  MAX_TOTAL_FEED_VALUE_ST_MODE,
  TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
  INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS,
  PM_MODES,
  TS_PM_MODES,
  TS_BACKEND_KEYS,
  MIN_S_TIME_SECS as C_MIN_S_TIME_SECS,
  MIN_E_TIME_SECS as C_MIN_E_TIME_SECS,
  MAX_E_TIME_SECS as C_MAX_E_TIME_SECS,
  MAX_E_TIME_SECS_ALLOWED as C_MAX_E_TIME_SECS_ALLOWED,
  TS_CREATED_BY
} from "@/constants/schedule";
import PondTS from "./pondTS";
import dateUtils from "@/utils/dateUtils";
const MIN_S_TIME_SECS = C_MIN_S_TIME_SECS;
const MIN_S_TIME = castSecsHHmmStr(MIN_S_TIME_SECS);
const MIN_E_TIME_SECS = C_MIN_E_TIME_SECS;
const MIN_E_TIME = castSecsHHmmStr(MIN_E_TIME_SECS);
const cmCurrTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ;
export default class PondMotherTS {
  constructor(pm_id, code, kg_dispense_time_sec, mode, created_by) {
    // identification params
    this.ui_id = objectIdGenerator();
    this.bk_id = undefined;
    this.previousMode = undefined;
    // relationship params
    this.created_by = created_by;
    this.duration = 1;
    this.pond_mother_code = code;
    this.pond_mother_id = pm_id;
    this.shrimp_talk_id = undefined;
    this.mode = mode;
    this.running_mode = undefined;
    this.feeding_level = 0;
    // time params
    this.s_time = MIN_S_TIME;
    this.s_time_secs = MIN_S_TIME_SECS;
    this.bk_s_time = undefined;
    this.bk_s_time_secs = undefined;
    this.e_time = MIN_E_TIME;
    this.e_time_secs = MIN_E_TIME_SECS;
    this.bk_e_time = undefined;
    this.bk_e_time_secs = undefined;
    this.dateQueryType = "TODAY";
    // feed Params
    this.feed = 0;
    this.feed_gap = MIN_FEED_GAP_VALUE;
    this.ocf = INIT_OCF_VALUE;
    this.remaining_feed = 0;
    this.dispensed_feed = 0;
    this.kg_dispense_time_sec = kg_dispense_time_sec;
    // status params
    this.status = PM_TS_STATUS.TO_BE_RUN;
    this.ui_status = PM_TS_STATUS.TO_BE_RUN;
    this.SAVED_AT_DEVICE = false;
    // ui handling params
    this.is_user_edited = false;
    this.enabled = {
      from_time: true,
      to_time: true,
      feed: true,
      ocf: true,
      mode: true,
      feeding_level: true,
      feed_gap: false,
      act_btn_del: true
    };
  }

  get from_time() {
    return this.s_time;
  }

  get to_time() {
    return this.e_time;
  }

  get durationInMins() {
    if (this.s_time_secs > this.e_time_secs) {
      return -1;
    }
    return this.getDurationInMins(this.s_time_secs, this.e_time_secs);
  }

  get total_feed_kgs() {
    return this.feed;
  }

  get feed_gap_mins() {
    return this.feed_gap;
  }

  get ocf_g() {
    return this.ocf;
  }

  get mode_g() {
    return this.mode;
  }

  get feeding_level_g() {
    return this.feeding_level;
  }

  set from_time({ updtPropVal, eventType }) {
    // need to check the valid time updt
    this.is_user_edited = true;
    this.s_time = updtPropVal || this.s_time;
    this.s_time_secs = timeStrHHmmVal(this.s_time);
    this.updtToTime();
  }

  set to_time({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    this.e_time = updtPropVal || this.e_time;
    this.e_time_secs = timeStrHHmmVal(this.e_time);
  }

  set total_feed_kgs({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.feed = updtPropVal;
    } else {
      this.feed = +(+updtPropVal).toFixed(2);
    }
  }

  set feed_gap_mins(value) {
    this.feed_gap = value;
  }

  set ocf_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.ocf = updtPropVal;
    } else {
      this.ocf = +(+updtPropVal).toFixed(2);
    }
  }

  set mode_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.mode = updtPropVal;
    } else {
      this.mode = +(+updtPropVal).toFixed(2);
    }
  }

  set feeding_level_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.feeding_level = updtPropVal;
    } else {
      this.feeding_level = +(+updtPropVal).toFixed(2);
    }
  }

  set isEditable(value) {
    this.enabled = {
      ...this.enabled,
      from_time: value,
      to_time: value,
      feed: value,
      ocf: value,
      mode: value,
      feeding_level: value,
      act_btn_del: value
    };
  }

  getDurationInMins(startTimeSecs, endTimeSecs) {
    return Math.ceil((endTimeSecs - startTimeSecs) / 60);
  }

  updtToTime() {
    if (this.s_time_secs < this.e_time_secs) return;
    this.e_time = calcPMScheduleEndTime({
      STRTSecs: this.s_time_secs,
      FG: this.feed_gap,
      OCF: this.ocf,
      TF: this.feed
    });
    this.e_time_secs = timeStrHHmmVal(this.e_time);
    if (
      this.s_time_secs > C_MAX_E_TIME_SECS &&
      this.e_time_secs > C_MAX_E_TIME_SECS
    ) {
      this.s_time_secs = MIN_S_TIME_SECS;
      this.e_time_secs = MIN_E_TIME_SECS;
      this.s_time = MIN_S_TIME;
      this.e_time = MIN_E_TIME;
    } else if (this.e_time_secs > C_MAX_E_TIME_SECS) {
      this.e_time_secs = C_MAX_E_TIME_SECS_ALLOWED;
      this.e_time = castSecsHHmmStr(this.e_time_secs);
    }
  }

  updtFeedGap(userTimeZoneString) {
    if (this.e_time_secs - this.s_time_secs <= 0) {
      return;
    }
    let totalTimeInMins = Math.ceil((this.e_time_secs - this.s_time_secs) / 60);
    let totalFeed = this.feed - this.dispensed_feed;
    if (totalFeed <= 0) {
      return;
    }
    this.feed_gap = +calcFG({
      TT: totalTimeInMins,
      TF: totalFeed,
      OCF: this.ocf
    }).toFixed(1);
    if (this.ui_status !== PM_TS_STATUS.RUNNING) return;
    totalFeed = this.feed - this.dispensed_feed;
    const startTime = cmCurrTimeSecs(userTimeZoneString);
    const endTimeSecs =
      this.mode === PM_MODES.BASIC
        ? this.getBasicModeEtime(startTime, userTimeZoneString).e_time_secs
        : this.e_time_secs;
    totalTimeInMins = Math.ceil((endTimeSecs - startTime) / 60);
    this.feed_gap = calcFG({
      TT: totalTimeInMins,
      TF: totalFeed,
      OCF: this.ocf
    }).toFixed(1);
  }

  checkAndUpdateStatus(userTimeZoneString) {
    const currTimesecs = cmCurrTimeSecs(userTimeZoneString);
    const isStimeGreaterThanCurrTime = this.isTIntvlGreaterThanThreshold(
      this.bk_s_time_secs,
      currTimesecs,
      TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS
    );
    const isEtimeGreaterThanCurrTime = this.isTIntvlGreaterThanThreshold(
      this.bk_e_time_secs,
      currTimesecs,
      TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS
    );
    if (!isEtimeGreaterThanCurrTime) {
      this.ui_status = PM_TS_STATUS.UI_COMPLETED;
    } else if (!isStimeGreaterThanCurrTime) {
      this.ui_status = PM_TS_STATUS.INVALID;
    }
  }

  isETimeGrtrSTime() {
    const ts = this;
    if (ts.e_time_secs > ts.s_time_secs) {
      return true;
    }
    return false;
  }

  getBasicModeEtime(basicSTimeSecs, userTimeZoneString) {
    const STRTSecs = basicSTimeSecs;
    const eTimeStr = calcPMScheduleEndTime({
      STRTSecs,
      TF: this.feed,
      FG: MIN_FEED_GAP_VALUE,
      OCF: this.ocf
    });
    return {
      e_time: eTimeStr,
      e_time_secs: timeStrHHmmVal(eTimeStr)
    };
  }

  isValidScheduleTIntvl(userTimeZoneString, eventType) {
    const isCurrentDay = this.dateQueryType === "TODAY";
    const currIntvl = this;
    if (eventType === "init") return "NO_ERROR";
    if (currIntvl.s_time_secs < C_MIN_S_TIME_SECS) {
      return {
        type: "time",
        validation: "start_time_minimum"
      };
    }
    if (currIntvl.e_time_secs >= C_MAX_E_TIME_SECS) {
      return {
        type: "time",
        validation: "end_time_maximum"
      };
    }
    if (!currIntvl.isETimeGrtrSTime()) {
      return {
        type: "time",
        validation:
          [PM_TS_STATUS.RUNNING, PM_TS_STATUS.UI_RUNNING].indexOf(
            this.ui_status
          ) > -1
            ? "start_time_running_greater_end_time"
            : "end_time_greater_start_time"
      };
    }

    /**
     * if it is current day and it is either created by ui or (it is created at backend and not a runnig timeslot)
     * then check from_time is less than the current time or not
     */
    const notARunningTS =
      currIntvl.bk_id &&
      ![
        PM_TS_STATUS.RUNNING,
        PM_TS_STATUS.UI_RUNNING,
        PM_TS_STATUS.PAUSED
      ].includes(currIntvl.ui_status);
    const isUICreatedTS = !currIntvl.bk_id; // if there is no backend id then it is ui created one
    const isFromTimeLessCurrTime = currIntvl.isValidTimeSecsForCurrentDayByThreshold(
      this.s_time_secs,
      TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
      userTimeZoneString
    );
    if (
      isCurrentDay &&
      (notARunningTS || isUICreatedTS) &&
      !isFromTimeLessCurrTime
    ) {
      return {
        type: "time",
        validation: "start_time_less_from_time"
      };
    }
    const isRunningTS =
      this.bk_id &&
      (currIntvl.ui_status === PM_TS_STATUS.UI_RUNNING ||
        currIntvl.ui_status === PM_TS_STATUS.RUNNING);
    if (
      isCurrentDay &&
      isRunningTS &&
      !this.isValidTimeSecsForCurrentDayByThreshold(
        this.e_time_secs,
        TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
        userTimeZoneString
      )
    ) {
      // return false;
      return {
        type: "time",
        validation: "end_time_before_5min_completion"
      };
    }
    return "NO_ERROR";
  }

  isTSOverlapping(allIntvls) {
    const resp = this.isOverlap(allIntvls);
    if (resp[0] !== "NO_OVERLAP") {
      // it should not overlap with the prev timeslot
      // it should not overlap with the future timeslot
      return {
        type: "time",
        validation: "overlap",
        schedule_id: resp[0]
      };
    }
    return "NO_ERROR";
  }

  isTIntvlHasValidFromTimeForCurrentDay(ts, userTimeZoneString) {
    // if timeslot is creating or editing for current day then it should be greater than the present time
    const ptSecs = dateUtils.getCurrTimeSecsInGivenTZ(userTimeZoneString);
    const sTSecs = ts.s_time_secs;
    const eTSecs = ts.e_time_secs;
    if (sTSecs >= ptSecs && eTSecs > sTSecs) {
      return true;
    }
    return false;
  }

  isTIntvlGreaterThanThreshold(ptSecs, tSecs, thresholdSecs) {
    const time1Secs = ptSecs;
    const time2Secs = tSecs;
    if (time2Secs - time1Secs > thresholdSecs) {
      return true;
    }
    return false;
  }

  isValidTimeSecsForCurrentDayByThreshold(
    tSecs,
    thresholdSecs,
    userTimeZoneString
  ) {
    const ptSecs = dateUtils.getCurrTimeSecsInGivenTZ(userTimeZoneString);
    if (this.isTIntvlGreaterThanThreshold(ptSecs, tSecs, thresholdSecs)) {
      return true;
    }
    return false;
  }

  isOverlap(allIntvls) {
    const allIntvlsSize = allIntvls.length;
    const temp = [...allIntvls];
    for (let i = 0; i < allIntvlsSize; i++) {
      const prevTIntvl = temp[i];
      if (prevTIntvl.ui_id === this.ui_id) continue;
      const errMsgs = [];
      [[prevTIntvl, this]].forEach((tsIntvlPair, index) => {
        if (tsIntvlPair[0] && tsIntvlPair[1]) {
          const s1h1 = tsIntvlPair[0].s_time_secs;
          const s1h2 = tsIntvlPair[0].e_time_secs;
          const s2h1 = tsIntvlPair[1].s_time_secs;
          const s2h2 = tsIntvlPair[1].e_time_secs;
          const schedIndex = tsIntvlPair[0].ui_id;
          /**
           * Case:
           * ts 1:------S-------E------
           * ts 2:---------S------E----
           */
          if (s1h1 <= s2h1 && s2h1 <= s1h2) {
            errMsgs.push(schedIndex);
            return;
          }
          /**
           * Case:
           * ts 1:------S-------E------
           * ts 2:---S------E----------
           */
          if (s1h1 <= s2h2 && s2h2 <= s1h2) {
            errMsgs.push(schedIndex);
            return;
          }
          /**
           * Case:
           * ts 1:-----S-------E-----
           * ts 2:-------S---E-------
           */
          if (s2h1 <= s1h1 && s2h2 >= s1h2) {
            errMsgs.push(schedIndex);
          }
        }
      });
      const errMsgsLen = errMsgs.length;
      if (errMsgsLen > 0) {
        return errMsgs;
      }
    }
    // not overlapping
    return ["NO_OVERLAP"];
  }

  getFeedGapBasedOnTSStatus(userTimeZoneString) {
    if (this.ui_status !== PM_TS_STATUS.RUNNING) return this.feed_gap;
    const totalFeed = this.feed - this.dispensed_feed;
    const startTime = cmCurrTimeSecs(userTimeZoneString);
    const endTimeSecs =
      this.mode === PM_MODES.BASIC
        ? this.getBasicModeEtime(startTime, userTimeZoneString).e_time_secs
        : this.e_time_secs;
    const totalTimeInMins = Math.ceil((endTimeSecs - startTime) / 60);
    return calcFG({
      TT: totalTimeInMins,
      TF: totalFeed,
      OCF: this.ocf
    });
  }

  static getAllValidPmTSFromArrPmTSByTime(arrCurrPmDayTS) {
    const validTimeSlots = arrCurrPmDayTS.filter((ts, index) => {
      if (!ts.bk_id) {
        return true;
      }
      if (
        [
          PM_TS_STATUS.COMPLETED,
          PM_TS_STATUS.STOPPED,
          PM_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) === -1
      ) {
        return true;
      }
      return false;
    });
    return validTimeSlots;
  }

  static getAllValidPmTSFromArrPmTS(arrCurrPmDayTS) {
    arrCurrPmDayTS = arrCurrPmDayTS.sort(
      (a, b) => a.s_time_secs - b.s_time_secs
    );
    const arrEditedIndexes = arrCurrPmDayTS.reduce((acc, ts, index) => {
      if (ts.is_user_edited) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (arrEditedIndexes.length === 0) return [];
    const indexOfEditedRecord = Math.min(...arrEditedIndexes);
    const validTimeSlots = arrCurrPmDayTS.filter((ts, index) => {
      if (indexOfEditedRecord > index) {
        return false;
      }
      if (!ts.bk_id) {
        return true;
      }
      if (
        [
          PM_TS_STATUS.COMPLETED,
          PM_TS_STATUS.STOPPED,
          PM_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) === -1
      ) {
        return true;
      }
      return false;
    });
    return validTimeSlots;
  }

  isValidTotalFeedForAutomaticMode() {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    if (this.feed < MIN_TOTAL_FEED_VALUE_ST_MODE) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: MIN_TOTAL_FEED_VALUE_ST_MODE
      };
    } else if (this.feed > MAX_TOTAL_FEED_VALUE_ST_MODE) {
      return {
        type: "feed",
        validation: "maximum",
        threshold: MAX_TOTAL_FEED_VALUE_ST_MODE
      };
    }
    if (this.ui_status === PM_TS_STATUS.RUNNING) {
      if (this.feed - this.dispensed_feed < 0) {
        return {
          type: "feed",
          validation: "less_than_dispensed_feed",
          threshold: this.dispensed_feed + this.ocf / 1000
        };
      }
    }
    return "NO_ERROR";
  }

  isValidTotalFeedForBasicMode(userTimeZoneString) {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    if (this.ocf < 200 || this.ocf > MAX_OCF_VALUE) return "SKIP_ERROR";
    const sTime = castSecsHHmmStr(cmCurrTimeSecs(userTimeZoneString));
    const maxLimit = calcMaxTFForADay({
      STRTStr: sTime,
      FG: MIN_FEED_GAP_VALUE,
      OCF: this.ocf
    });
    const minLimit = calcTF({
      TT: Math.ceil(TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS / 60) + 1,
      FG: MIN_FEED_GAP_VALUE,
      OCF: this.ocf
    });
    const totalFeed = this.feed - this.dispensed_feed;
    if (minLimit >= maxLimit) {
      return {
        type: "time",
        validation: "possible_to_give_timeslots"
      };
    }
    if (totalFeed > maxLimit) {
      return {
        type: "feed",
        validation: "maximum",
        threshold: maxLimit
      };
    }
    if (totalFeed < minLimit) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: minLimit
      };
    }

    return "NO_ERROR";
  }

  isValidTotalFeedForScheduleMode() {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    if (this.ocf < 200 || this.ocf > MAX_OCF_VALUE) return "SKIP_ERROR";
    if (this.feed * 1000 < this.ocf) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: this.ocf / 1000
      };
    }
    if (this.ui_status === PM_TS_STATUS.RUNNING) {
      const remainingFeed = this.feed - this.dispensed_feed;
      if (remainingFeed < 0) {
        return {
          type: "feed",
          validation: "minimum",
          threshold: this.dispensed_feed + this.ocf / 1000
        };
      }
    }
    return "NO_ERROR";
  }

  isValidOCFForBasicMode(userTimeZoneString) {
    if (isNaN(+this.ocf)) {
      return {
        type: "ocf",
        validation: "empty"
      };
    }
    const isTSTFValid = this.feed > 0;
    const isOCFValid = paramOCF =>
      paramOCF >= INIT_OCF_VALUE && paramOCF <= MAX_OCF_VALUE;
    if (isOCFValid(this.ocf) && !isTSTFValid) {
      return "SKIP_ERROR";
    }
    if (this.ocf < INIT_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "minimum",
        threshold: INIT_OCF_VALUE
      };
    }
    if (this.ocf > MAX_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "maximum",
        threshold: MAX_OCF_VALUE
      };
    }
    return "NO_ERROR";
  }

  isValidOCFForScheduleMode(userTimeZoneString) {
    if (isNaN(+this.ocf)) {
      return {
        type: "ocf",
        validation: "empty"
      };
    }
    const totalFeed = this.feed - this.dispensed_feed;
    const isOCFValid = paramOCF =>
      paramOCF >= INIT_OCF_VALUE && paramOCF <= MAX_OCF_VALUE;
    const isValidFeedGap = paramFG => paramFG >= MIN_FEED_GAP_VALUE;
    const isTSTFValid = this.feed > 0;
    let start_time_secs = this.s_time_secs;
    if (
      [PM_TS_STATUS.RUNNING, PM_TS_STATUS.PAUSED].indexOf(this.ui_status) > -1
    ) {
      start_time_secs = cmCurrTimeSecs(userTimeZoneString);
    }
    const totalTimeInMins = Math.ceil(
      (this.e_time_secs - start_time_secs) / 60
    );
    const suggestOCF = suggestOCFForPm;
    if (isOCFValid(this.ocf) && !isTSTFValid) {
      return "SKIP_ERROR";
    }
    if (isOCFValid(this.ocf) && isTSTFValid) {
      const feedGap = calcFG({
        TT: totalTimeInMins,
        TF: totalFeed,
        OCF: this.ocf
      });
      if (isValidFeedGap(feedGap)) {
        return "NO_ERROR";
      }
      const suggestedOCF = suggestOCF(totalTimeInMins, totalFeed);
      if (isOCFValid(suggestedOCF)) {
        return {
          type: "ocf",
          validation: "minimum",
          threshold: suggestedOCF
        };
      }
      return {
        type: "feed_gap",
        validation: "minimum"
      };
    }
    if (!isOCFValid(this.ocf) && isTSTFValid) {
      const suggestedOCF = suggestOCF(totalTimeInMins, totalFeed);
      if (isOCFValid(suggestedOCF)) {
        return {
          type: "ocf",
          validation: "minimum",
          threshold: suggestedOCF
        };
      }
    }
    if (this.ocf < INIT_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "minimum",
        threshold: INIT_OCF_VALUE
      };
    }
    if (this.ocf > MAX_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "maximum",
        threshold: MAX_OCF_VALUE
      };
    }
  }

  createPondTSToJoin(pondId) {
    const pondTSObj = new PondTS(pondId, this.mode, TS_CREATED_BY.POND_MOTHER);
    // linking pond and pm
    pondTSObj.pmsSubscribe[this.pond_mother_id] = this.ui_id;
    pondTSObj.dateQueryType = this.dateQueryType;
    pondTSObj.isEditable = true;
    pondTSObj.s_time = this.s_time;
    pondTSObj.s_time_secs = this.s_time_secs;
    pondTSObj.feed = this.feed;
    pondTSObj.bk_id = this.bk_id;
    pondTSObj.previousMode = this.previousMode;
    pondTSObj.ui_status = this.ui_status;
    return pondTSObj;
  }

  unSubscribeFromPond(pondTS, indexOnPondTSToGroupPmTS) {
    const pmId = this.pond_mother_id;
    if (pondTS.pmsSubscribe[pmId]) {
      // removing the feed of the pm from pond totalFeed
      delete pondTS.pmsSubscribe[pmId];
      indexOnPondTSToGroupPmTS[pondTS.ui_id] = pondTS.pmsSubscribe;
    }
  }

  findPondTSHavingSameArrProps(arrPondTS, arrProps, currPondTSId) {
    const secsMap = {
      from_time: "s_time_secs",
      to_time: "e_time_secs",
      ui_status: "ui_status"
    };
    let foundPondTS;
    const pmValue = arrProps.map(prop => this[secsMap[prop]]).join("_");
    for (let i = 0; i < arrPondTS.length; i++) {
      const pondTS = arrPondTS[i];
      const pondValue = arrProps.map(prop => pondTS[secsMap[prop]]).join("_");
      const pondTSSubscribePmsCount = Object.keys(pondTS.pmsSubscribe).length;
      const isDiffTSOrSameTSWithMorePondMothers =
        (pondTS.ui_id !== currPondTSId &&
          !pondTS.time_slots[this.pond_mother_id]) ||
        (pondTS.ui_id === currPondTSId && pondTSSubscribePmsCount > 1);
      if (pondValue === pmValue && isDiffTSOrSameTSWithMorePondMothers) {
        foundPondTS = pondTS;
        break;
      }
    }
    return foundPondTS;
  }

  getStimeEtimeBasedOnMode(userTimeZoneString) {
    let s_time = this.s_time;
    let e_time = this.e_time;
    let s_time_secs = this.s_time_secs;
    let e_time_secs = this.e_time_secs;
    const isValidToChangeSTime = ![
      PM_TS_STATUS.UI_RUNNING,
      PM_TS_STATUS.RUNNING,
      PM_TS_STATUS.PAUSED
    ].includes(this.ui_status);
    const basicRunningSTime = cmCurrTimeSecs(userTimeZoneString);
    switch (this.mode) {
      case PM_MODES.BASIC:
        if (isValidToChangeSTime) {
          s_time_secs = cmCurrTimeSecs(userTimeZoneString);
          s_time = castSecsHHmmStr(s_time_secs);
        }
        e_time_secs = this.getBasicModeEtime(
          basicRunningSTime,
          userTimeZoneString
        ).e_time_secs;
        e_time = castSecsHHmmStr(e_time_secs);
        break;
      case PM_MODES.AUTOMATIC:
        if (isValidToChangeSTime) {
          s_time_secs = cmCurrTimeSecs(userTimeZoneString) + 60;
          s_time = castSecsHHmmStr(s_time_secs);
        }

        break;
    }
    console.log("this.mode", this.mode, e_time, s_time);
    return { s_time_secs, e_time_secs, e_time, s_time };
  }

  castToBackendObjType2(
    pmTS,
    pmMode,
    stDetails,
    action = "NEW",
    userTimeZoneString,
    pmDefaultMode
  ) {
    const { s_time, e_time } = this.getStimeEtimeBasedOnMode(
      userTimeZoneString
    );
    if (!["NEW"].includes(action)) return [];
    // init timeslots
    const timeSlot = {};
    if (TS_PM_MODES[pmTS.mode] === "SHRIMP_TALK") {
      // && pmTS.bk_id - removed due to pond level save s_time issue
      timeSlot.s_time = pmTS.s_time;
      timeSlot.e_time = pmTS.e_time;
    } else {
      timeSlot.s_time = s_time;
      timeSlot.e_time = e_time;
    }
    timeSlot.managed_by = TS_PM_MODES[pmMode];
    timeSlot.status = this.status;
    timeSlot.feed = this.feed;
    if (TS_PM_MODES[pmMode] !== "FARMER" && TS_PM_MODES[pmMode] !== "MANUAL") {
      timeSlot.feeding_level =
        pmTS.feeding_level === -1 ? 0 : pmTS.feeding_level;
    }
    timeSlot.mode = pmTS.mode;
    if (pmDefaultMode === "HYBRID") {
      if (TS_PM_MODES[pmMode] === PM_MODES.AUTOMATIC) {
        timeSlot.shrimp_talk_id = stDetails?.shrimp_talk_id;
        // timeSlot.s_time = stDetails.s_time;
        // timeSlot.e_time = stDetails.e_time;
      } else {
        timeSlot.feed_gap =
          this.getFeedGapBasedOnTSStatus(userTimeZoneString) * 60;
        timeSlot.ocf = this.ocf;
      }
      timeSlot.running_mode = "HYBRID";
    } else {
      if (pmMode === PM_MODES.AUTOMATIC) {
        timeSlot.shrimp_talk_id = stDetails.shrimp_talk_id;
        timeSlot.s_time = stDetails.s_time;
        timeSlot.e_time = stDetails.e_time;
      } else {
        timeSlot.feed_gap =
          this.getFeedGapBasedOnTSStatus(userTimeZoneString) * 60;
        timeSlot.ocf = this.ocf;
      }
    }
    return timeSlot;
  }

  setUIStatus(dateQueryType, userTimeZoneString) {
    const currentTimeWithDeviceDelay =
      cmCurrTimeSecs(userTimeZoneString) + TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS;
    const isPmTSIsScheduleMode = this.mode === PM_MODES.SCHEDULE;
    const isCompletedSched =
      isPmTSIsScheduleMode && this.e_time_secs < currentTimeWithDeviceDelay;
    const isRunningSched = this.s_time_secs <= currentTimeWithDeviceDelay;
    this.ui_status = this.status;
    switch (dateQueryType) {
      case "TODAY":
        if (
          isCompletedSched &&
          ![PM_TS_STATUS.COMPLETED, PM_TS_STATUS.STOPPED].includes(this.status)
        ) {
          this.ui_status = PM_TS_STATUS.UI_COMPLETED;
        } else if (isRunningSched && this.status === PM_TS_STATUS.TO_BE_RUN) {
          this.ui_status = PM_TS_STATUS.UI_RUNNING;
        }
        break;
      case "PAST":
        if (
          ![PM_TS_STATUS.STOPPED, PM_TS_STATUS.COMPLETED].includes(this.status)
        ) {
          this.ui_status = PM_TS_STATUS.UI_COMPLETED;
        }
    }
  }

  setFieldsEditableStatus() {
    if (this.ui_status === PM_TS_STATUS.TO_BE_RUN) return;
    if (
      [
        PM_TS_STATUS.UI_RUNNING,
        PM_TS_STATUS.PAUSED,
        PM_TS_STATUS.RUNNING
      ].includes(this.ui_status)
    ) {
      this.enabled.from_time = false;
      this.enabled.mode = false;
      this.enabled.act_btn_del = false;
    } else {
      this.isEditable = false;
    }
  }

  castBackendTSToUiTS(arrProperties, bkTS) {
    const bkToFrontend = {
      s_time: "bk_s_time",
      s_time_secs: "bk_s_time_secs",
      e_time: "bk_e_time",
      e_time_secs: "bk_e_time_secs"
    };
    arrProperties.forEach(property => {
      switch (property) {
        case "feed_gap":
          this[property] = +(bkTS[property] || 1).toFixed(1);
          this[property] = +this[property];
          break;
        case "feed":
        case "dispensed_feed":
        case "remaining_feed":
          this[property] = +(bkTS[property] || 0).toFixed(2);
          break;
        case "ocf":
          this[property] = +(bkTS[property] || 200).toFixed(2);
          break;
        case "s_time":
        case "s_time_secs":
        case "e_time":
        case "e_time_secs":
          this[property] = bkTS[property];
          this[bkToFrontend[property]] = bkTS[property];
          break;
        default:
          this[property] = bkTS[property];
      }
    });
  }

  static groupBySTimeWithThresholdAndStatus(arrPmTS, thresholdInMins) {
    if (arrPmTS.length === 0) return;
    const thresholdInSecs = thresholdInMins * 60;
    const sTimeArr = arrPmTS.sort((a, b) => a.s_time_secs - b.s_time_secs);
    let i = 0;
    let j = 0;
    const groupPmTSBySTimeAndStatus = {};
    while (i < sTimeArr.length && j < sTimeArr.length) {
      const ts1 = sTimeArr[i];
      const ts2 = sTimeArr[j];
      if (ts2.s_time_secs - ts1.s_time_secs < thresholdInSecs) {
        const key = `${ts1.s_time_secs}`; // _${ts2.status}
        if (!groupPmTSBySTimeAndStatus[key]) {
          groupPmTSBySTimeAndStatus[key] = [];
        }
        groupPmTSBySTimeAndStatus[key].push(ts2);
        j++;
      } else {
        i = j;
      }
    }
    return Object.values(groupPmTSBySTimeAndStatus);
  }

  static groupByKeys(arrPmTS, keys = "status") {
    if (arrPmTS.length === 0) return;
    const uniquePmIds = PondMotherTS.getPropertyValueInArrTS(
      arrPmTS,
      "pond_mother_id",
      "UNIQUE"
    ).reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, {});
    const groupedPmTS = arrPmTS.reduce(
      (acc, currTS) => {
        let combinedKey;
        switch (cmGetType(keys)) {
          case "array":
            combinedKey = keys.map(x => currTS[x]).join(",");
            break;
          case "function":
            combinedKey = keys(currTS);
            break;
          case "string":
            combinedKey = currTS[keys];
            break;
        }
        let key = `${combinedKey}__${acc.linear_probe[currTS.pond_mother_id]}`;
        if (!acc.groups[key]) {
          acc.groups[key] = { groupMemberIds: [], group: [] };
        } else if (
          acc.groups[key].groupMemberIds.includes(currTS.pond_mother_id)
        ) {
          acc.linear_probe[currTS.pond_mother_id]++;
          key = `${combinedKey}__${acc.linear_probe[currTS.pond_mother_id]}`;
          if (!acc.groups[key]) {
            acc.groups[key] = { groupMemberIds: [], group: [] };
          }
        }
        acc.groups[key].group.push(currTS);
        acc.groups[key].groupMemberIds.push(currTS.pond_mother_id);
        return acc;
      },
      { groups: {}, linear_probe: uniquePmIds }
    );
    console.log(groupedPmTS.groups);
    return Object.values(groupedPmTS.groups).map(x => x.group);
  }

  static getPropertyValueInArrTS(arrPmTS, property, operation) {
    const mapOperationToFunction = {
      SUMMATION: {
        operation: (acc, curr) => acc + curr[property],
        initAccValue: 0
      },
      MAX: {
        operation: (acc, curr) => Math.max(acc, curr[property]),
        initAccValue: Number.MIN_VALUE
      },
      MIN: {
        operation: (acc, curr) => Math.min(acc, curr[property]),
        initAccValue: Number.MAX_VALUE
      },
      UNIQUE: {
        operation: (acc, curr) => {
          if (curr[property] !== undefined && !acc.includes(curr[property])) {
            acc.push(curr[property]);
          }
          return acc;
        },
        initAccValue: []
      }
    };
    const reduceParams = mapOperationToFunction[operation];
    return arrPmTS.reduce(reduceParams.operation, reduceParams.initAccValue);
  }

  static getCurrentDayNewTS(pmDetails, pmMode, created_by, userTimeZoneString) {
    const tempDate = dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
    const newPmTS = new PondMotherTS(
      pmDetails._id,
      pmDetails.code,
      pmDetails.settings.kg_dispense_time_sec,
      pmMode,
      created_by
    );
    newPmTS.s_time = dateUtils.formatDate(
      dateUtils.add(tempDate, {
        minutes: INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS
      }),
      "HH:mm"
    );
    newPmTS.s_time_secs = timeStrHHmmVal(newPmTS.s_time);
    newPmTS.e_time = dateUtils.formatDate(
      dateUtils.add(tempDate, {
        minutes: INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS + 1
      }),
      "HH:mm"
    );
    newPmTS.e_time_secs = timeStrHHmmVal(newPmTS.e_time);
    newPmTS.feed = 0;
    newPmTS.ocf = INIT_OCF_VALUE;
    newPmTS.feed_gap = 1;
    newPmTS.shrimp_talk_id = pmDetails.shrimp_talk_id;
    return newPmTS;
  }

  static mergingStrategyMapper(strategy, arrStrategyParams) {
    const returnValue = (strategy, params) => {
      return { strategy, params };
    };
    switch (strategy) {
      case "STIME_THRESHOLD_STATUS":
        return returnValue(
          PondMotherTS.groupBySTimeWithThresholdAndStatus,
          arrStrategyParams
        );
      case "PM_TS_PROPERTIES":
        return returnValue(PondMotherTS.groupByKeys, arrStrategyParams);
      case "DEFAULT":
        return returnValue(PondMotherTS.groupByKeys, [
          currTS => {
            if (
              [PM_TS_STATUS.COMPLETED, PM_TS_STATUS.STOPPED].includes(
                currTS.status
              )
            ) {
              return `${currTS.s_time_secs}|${currTS.mode}|${currTS.feeding_level}`;
            }
            return ["s_time_secs", "e_time_secs", "mode", "feeding_level"]
              .map(key => currTS[key])
              .join("|");
          }
        ]);
      default:
        throw new Error("Merging Strategy Not Found");
    }
  }

  static castArrBackendTSToUiTS(
    pmDetails,
    arrTS,
    dateQueryType,
    pmIdToStTimings,
    userTimeZoneString,
    isPastDay
  ) {
    const keyArr = TS_BACKEND_KEYS;
    let objPmTSIdTS = {};
    const arrPmTS = [];
    const pmId = pmDetails._id;
    const pmMode = pmDetails.managed_by || "NO_MODE";
    arrTS.forEach(ts => {
      const newPmTS = new PondMotherTS(
        pmId,
        pmDetails.code,
        pmDetails.settings.kg_dispense_time_sec,
        isPastDay === true
          ? ts.managed_by
          : pmMode === "HYBRID"
          ? ts.managed_by
          : pmMode,
        TS_CREATED_BY.IT_SELF,
        undefined
      );
      newPmTS.running_mode = ts.running_mode;
      newPmTS.feeding_level = ts.feeding_level === -1 ? 0 : ts.feeding_level;
      newPmTS.castBackendTSToUiTS(keyArr, ts);
      newPmTS.dateQueryType = dateQueryType;
      newPmTS.SAVED_AT_DEVICE =
        ts.modified === undefined ? true : !ts.modified.status;
      newPmTS.bk_id = ts.ts_id;
      newPmTS.previousMode = ts.managed_by;
      newPmTS.setUIStatus(dateQueryType, userTimeZoneString);
      newPmTS.setFieldsEditableStatus();
      if (pmMode === PM_MODES.AUTOMATIC) {
        const { shrimp_talk_id } = pmIdToStTimings[pmId] || ts;
        newPmTS.shrimp_talk_id = shrimp_talk_id;
      }
      arrPmTS.push(newPmTS);
    });
    [objPmTSIdTS] = [arrPmTS].map(arr => {
      return arr
        .sort((a, b) => {
          return a.s_time_secs - b.s_time_secs || a.e_time_secs - b.e_time_secs;
        })
        .reduce((acc, curr) => {
          acc[curr.ui_id] = curr;
          return acc;
        }, {});
    });
    return { objPmTSIdTS };
  }
}
