/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: deviceTypes.js
Description: This file contains the device names and their related langauge keys as constant configuration object.
*/
import i18n from "@/config/i18n";
export const deviceTypes = {
  POND_GUARD: {
    name: "PondGuard",
    lang_key: "Comn_pond_guard",
    lang: i18n.t("Comn_pond_guard"),
    key: "POND_GUARD"
  },
  FEED_BLOWER: {
    name: "FeedBlower",
    lang_key: "Comn_feed_blower",
    lang: i18n.tc("Comn_feed_blower", 1),
    key: "FEED_BLOWER"
  },
  SHRIMP_SNAP: {
    name: "ShrimpSnap",
    lang_key: "Comn_shrimp_snap",
    lang: i18n.tc("Comn_shrimp_snap", 1),
    key: "SHRIMP_SNAP"
  },
  POND_MOTHER: {
    name: "PondMother",
    lang_key: "Comn_pond_mother",
    lang: i18n.t("Comn_pond_mother"),
    key: "POND_MOTHER"
  },
  SHRIMP_TALK: {
    name: "ShrimpTalk",
    lang_key: "Comn_shrimptalk",
    lang: i18n.t("Comn_shrimptalk"),
    key: "SHRIMP_TALK"
  },
  GATEWAY: {
    name: "Gateway",
    lang_key: "Comn_gateway",
    lang: i18n.t("Comn_gateway"),
    key: "GATEWAY"
  }
};

export const allDevicesAlertsMetaData = {
  GATEWAY: {
    device_type: deviceTypes.GATEWAY.key,
    bknd_alert_key: "gateway_alerts",
    bknd_total_alerts_device_key: "gateways",
    battery_voltage_func: function(dispBatteryVolt, batteryVolt) {
      return dispBatteryVolt || batteryVolt;
    },
    name: "Gateway",
    key: "GW"
  },
  POND_GUARD: {
    device_type: deviceTypes.POND_GUARD.key,
    bknd_alert_key: "pond_guard_alerts",
    bknd_total_alerts_device_key: "pond_guards",
    battery_voltage_func: function(dispBatteryVolt, batteryVolt) {
      return dispBatteryVolt || batteryVolt;
    },
    name: "PondGuard",
    key: "PG"
  },
  SHRIMP_TALK: {
    device_type: deviceTypes.SHRIMP_TALK.key,
    bknd_alert_key: "shrimp_talk_alerts",
    bknd_total_alerts_device_key: "shrimp_talks",
    battery_voltage_func: function(dispBatteryVolt, batteryVoltage) {
      if (dispBatteryVolt > 0) {
        return dispBatteryVolt + 1;
      }
      if (batteryVoltage > 0) {
        return batteryVoltage + 1;
      }
      return batteryVoltage;
    },
    name: "ShrimpTalk",
    key: "ST"
  },
  POND_MOTHER: {
    device_type: deviceTypes.POND_MOTHER.key,
    bknd_alert_key: "pond_mother_alerts",
    bknd_total_alerts_device_key: "pond_mothers",
    battery_voltage_func: function(dispBatteryVolt, batteryVolt) {
      return dispBatteryVolt || batteryVolt;
    },
    name: "PondMother",
    key: "PM"
  },
  FEED_BLOWER: {
    device_type: deviceTypes.FEED_BLOWER.key,
    bknd_alert_key: "feed_blower_alerts",
    bknd_total_alerts_device_key: "feed_blowers",
    battery_voltage_func: function(dispBatteryVolt, batteryVolt) {
      return dispBatteryVolt || batteryVolt;
    },
    name: "FeedBlower",
    key: "FB"
  },
  SHRIMP_SNAP: {
    device_type: deviceTypes.SHRIMP_SNAP.key,
    bknd_alert_key: "shrimp_snap_alerts",
    bknd_total_alerts_device_key: "shrimp_snap",
    battery_voltage_func: function(dispBatteryVolt, batteryVolt) {
      return dispBatteryVolt || batteryVolt;
    },
    name: "ShrimpSnap",
    key: "SS"
  }
};
export default { deviceTypes, allDevicesAlertsMetaData };
