import { $superSkrettingTechnicianAxios } from "../config/axios";

export default {
  fetchUserTokenService: async (params, userId) => {
    return $superSkrettingTechnicianAxios.get(
      `/account-manager/users/${userId}/signin`,
      {
        params
      }
    );
  },
  fetchUserProfileService: async () => {
    try {
      const response = await $superSkrettingTechnicianAxios.get("/profile");
      console.log("response", response);
      return response;
    } catch (err) {
      console.log("user profile fetch", err);
    }
  },
  skrettingTechnicianServiceNotifyBackendOnLogout: async () => {
    await $superSkrettingTechnicianAxios.post("/admin/users/logout");
  },
  // fetchAllAssingedSkrettingTechnicianUsers() {
  //   return $superSkrettingTechnicianAxios.get("account-manager/sub-user/users");
  // },
  fetchAlreadyAssingedUsersFromSubuser: async (params = {}) => {
    const query = { get_all: true };
    try {
      const response = await $superSkrettingTechnicianAxios.get(
        `account-manager/sub-user/${params.subuser_id}/users`,
        { params: query }
      );
      console.log("response", response);
      // context.commit(
      //   "SET_ALL_USERS_TO_SKRETTING_TECHNICIAN",
      //   response.data.users
      // );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
};
