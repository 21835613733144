/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to PondMother
*/
import { $axios } from "@/config/axios";

export default {
  // pgs will be repeated in the payload
  fetchPmDetailsByPondId(pondId, params) {
    return $axios.get(`/ponds/${pondId}/pond-mothers`, { params });
  },

  fetchAllPondMothers(params) {
    return $axios.get("/ponds/pond-mothers", {
      params
    });
  },
  fetchAPondMother(pondMotherId) {
    return $axios.get("/ponds/pond-mothers/" + pondMotherId);
  },
  fetchPondMothersOnPondId(pondId) {
    return $axios.get(`/ponds/${pondId}/pond-mothers`, {
      include: ["title", "code"]
    });
  },
  addPondMotherDetails(pondMothers) {
    return $axios.post("/ponds/pond-mothers", pondMothers);
  },
  updatePMSettings(pondMothers) {
    return $axios.patch("/ponds/pond-mothers/settings", pondMothers);
  },
  unAssignPondMothers(pondMother) {
    return $axios.delete(`/ponds/pond-mothers/${pondMother}`);
  },
  fetchSlotWisePMFeed(pondmother, params) {
    return $axios.get("/ponds/pond-mothers/" + pondmother + "/slotwise-feed", {
      params
    });
  },
  fetchPMCount(params) {
    return $axios.get("/pond-wise-pond-mothers-count", {
      params
    });
  }
};
