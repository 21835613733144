/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:importVuexModules.js
Description: This file contains the vuex objects useful in the router configuration for resgister the store at the time entering the selected url
*/
import pondInfoMod from "@/store/components/pondInfo";
import dashboardMod from "@/store/components/dashboard";
import alertsInfoMod from "@/store/components/alertsInfo";
import googleMapsMod from "@/store/components/googleMaps";
import pondmotherMod from "@/store/components/pondmother";
import schedulesMod from "@/store/components/schedules";
import pondguardMod from "@/store/modules/pondguard";
import shrimpSnapMod from "@/store/modules/shrimpsnap";
import pondDetailsMod from "@/store/components/pondDetails/index.js";
import resourceMod from "@/store/components/resource";
import stockMod from "@/store/components/stock";
import medicinesMod from "@/store/components/medicines";
import labTestsMod from "@/store/components/labTests";
import pondFeedingMod from "@/store/components/pondFeeding";
import pondMotherSchedulesMod from "@/store/components/pondMotherSchedules/index";
import feedInfoMod from "@/store/components/feedInfoMod";
import gatewayMod from "@/store/components/gateway";
import feedBlowerMod from "@/store/components/feedBlower";
import aquasimMod from "@/store/components/aquasim";
import userAccessManagementMod from "@/store/components/userAccessManagement";
export default {
  pondInfoMod,
  pondDetailsMod,
  stockMod,
  medicinesMod,
  resourceMod,
  labTestsMod,
  dashboardMod,
  alertsInfoMod,
  googleMapsMod,
  pondmotherMod,
  schedulesMod,
  pondguardMod,
  pondFeedingMod,
  pondMotherSchedulesMod,
  feedInfoMod,
  gatewayMod,
  feedBlowerMod,
  aquasimMod,
  userAccessManagementMod,
  shrimpSnapMod
};
