/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: activityLog.js
Description: This file contains all API service calls in relation to Superadmin Activity Log
*/

import { $superAdminAxios } from "@/config/axios";

export default {
  // location
  fetchActivityLog({ params }) {
    return $superAdminAxios.get(`admin/activity-logs`, {
      params
    });
  }
};
