/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2022
**/
/**
File Name: releaseNotes.js
Description: This file contains all the vuex store functions used in release notes page
*/

import AdminReleaseNotesService from "../../../services/superadmin/adminReleaseNotes";
import { $superAdminAxios } from "@/config/axios";

const state = {
  releaseListContainer: [],
  productManualsData: []
};

const getters = {
  getReleaseList: function(state) {
    return state.releaseListContainer;
  },
  getProductManualsData: function(state) {
    return state.productManualsData;
  }
};

const mutations = {
  SET_RELEASE_LIST: function(state, releaseList) {
    state.releaseListContainer = releaseList;
  },
  SET_PRODUCT_MANUALS_DATA: function(state, productManualsData) {
    state.productManualsData = productManualsData;
  }
};

const actions = {
  fetchReleaseList: async (context, params = {}) => {
    const response = await AdminReleaseNotesService.fetchReleaseList(params);
    context.commit("SET_RELEASE_LIST", response.data.data);
  },
  async fetchProductManualPDF(context, params = {}) {
    const response = await $superAdminAxios.get("admin/product-manuals", {
      params
    });
    context.commit("SET_PRODUCT_MANUALS_DATA", response.data.data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
