/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: highcharts.js
Description: This file registers the highcharts related components and imports highcharts libraries all over the application
*/
import "@/utils/multicolor_series";
import Vue from "vue";
import HighChartsVue from "highcharts-vue";
import HighCharts from "highcharts";
import StockInit from "highcharts/modules/stock";
import highchartMoreInit from "highcharts/highcharts-more";
import VariablePieInit from "highcharts/modules/variable-pie";
import SolidGuageInit from "highcharts/modules/solid-gauge";
import HighChartsNoData from "highcharts/modules/no-data-to-display";
import colorAxisInit from "highcharts/modules/coloraxis";
import HighChartsExporting from "highcharts/modules/exporting";
import ExportCSV from "highcharts/modules/export-data";
import HighChartsPattern from "highcharts/modules/pattern-fill";
import HighChartsBrokenAxis from "highcharts/modules/broken-axis";
import HighChartsAccessibility from "highcharts/modules/accessibility";
import dateUtils from "@/utils/dateUtils";
import i18n from "@/config/i18n";
const highChartsToDatefns = {
  a: "ccc",
  A: "EEEE",
  d: "dd",
  e: "d",
  w: "EEEEE",
  b: "MMM",
  B: "MMMM",
  m: "MM",
  y: "yy",
  Y: "yyyy",
  H: "HH",
  k: "H",
  I: "hh",
  l: "h",
  M: "mm",
  p: "aaa",
  P: "aaaaaaaa",
  S: "ss",
  L: "T"
};
for (const prop in highChartsToDatefns) {
  HighCharts.dateFormats[prop] = timestamp => {
    return dateUtils.formatTZ(
      dateUtils.utcToZonedTime(timestamp, "UTC"),
      highChartsToDatefns[prop],
      {
        timeZone: "UTC",
        locale: dateUtils.localesMap[i18n.locale]
      }
    );
  };
}
StockInit(HighCharts);
HighChartsAccessibility(HighCharts);
HighChartsBrokenAxis(HighCharts);
HighChartsPattern(HighCharts);
HighChartsNoData(HighCharts);
highchartMoreInit(HighCharts);
colorAxisInit(HighCharts);
HighChartsExporting(HighCharts);
ExportCSV(HighCharts);
VariablePieInit(HighCharts);
SolidGuageInit(HighCharts);

Vue.use(HighChartsVue, { tagName: "high-charts" });
