<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erServerSidePaginationTable.vue
Description: This file contains the table component which is extension of the data-tables-server table component which handovers the pagination, searching, filtering functionalities  to backend
-->
<template>
  <div class="server-side-pagination-table">
    <data-tables-server
      ref="dataTable"
      :class="computedTableTypeClass"
      :data="data"
      :total="total"
      :size="size"
      :page-size="$constants.table['page-size']"
      :pagination-props="$constants.table['pagination-props']"
      :table-props="computedTableProps"
      v-loading="loading"
      element-loading-background="white"
      @cell-click="handleCellClick"
      @current-page-change="currentPageChange"
      @query-change="onQueryChange"
    >
      <template slot="empty">
        <img class="no-data__img" src="@/assets/no_data.svg" />
        <span class="no-data__text">
          <h4>{{ $t("Comn_no_data_connection") }}</h4>
        </span>
      </template>
      <template v-for="(column, key) in columns">
        <el-table-column
          :key="`${key}`"
          v-show="column.visible"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable || false"
          :width="column.width || ''"
          :min-width="column.minWidth || ''"
        >
          <template v-slot:header="scope">
            <slot
              :name="`${key.toLowerCase()}_header`"
              v-bind:header="{
                column: scope.column,
                $index: scope.$index,
                label: column.label,
                sub_label: column.sub_label || ''
              }"
            >
              {{ column.label }}
              {{ column.sub_label }}
            </slot>
          </template>
          <template slot-scope="scope">
            <slot
              :name="key.toLowerCase()"
              v-bind:row="{
                data: scope.row,
                path: column.prop,
                $index: scope.$index
              }"
            >
              <template
                v-if="
                  getRowValue(scope.row, column.prop, column.type).type ===
                    'array'
                "
              >
                <el-tag
                  size="mini"
                  effect="dark"
                  v-for="(val, tagIndex) in getRowValue(
                    scope.row,
                    column.prop,
                    column.type
                  ).value"
                  :key="tagIndex"
                  >{{ val }}</el-tag
                >
              </template>
              <template v-else>{{
                getRowValue(scope.row, column.prop, column.type).value
              }}</template>
            </slot>
          </template>
          <slot :name="`${key.toLowerCase()}_NestedColumn`"></slot>
        </el-table-column>
      </template>
      <slot name="actionColumn">
        <el-table-column label="Actions" v-if="actionColumn">
          <el-button
            icon="el-icon-setting"
            size="small"
            :disabled="true"
          ></el-button>
        </el-table-column>
      </slot>
    </data-tables-server>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import table from "@/store/components/table";
import dateUtils from "@/utils/dateUtils";
export default {
  name: "erServerSidePaginationTable",
  props: {
    columns: {
      default: () => {}
    },
    data: {
      default: () => []
    },
    size: {
      default: ""
    },
    addPadingToSearch: {
      default: true
    },
    actionColumn: {
      default: true
    },
    searchField: {
      default: ""
    },
    currentPageChange: {
      default: () => () => {}
    },
    elTableProps: {
      default: () => ({})
    },
    type: {
      default: "none"
    },
    total: { default: 0 },
    loading: { default: true }
  },
  data: function() {
    return {
      columnsSelected: Object.values(this.columns || {}).filter(
        column => column.required
      ),
      searchString: "",
      query: {},
      page: 1,
      limit: 10,
      TableTypeClass: {
        "card-row-table": "card-row-table",
        "white-header-table": "white-header-table",
        "transparent-header-table": "transparent-header-table",
        "white-header-table-inner": "white-header-table-inner"
      }
    };
  },
  computed: {
    ...mapGetters("table", {
      getTotalRowCount: "getTotalRowCount"
    }),
    searchTranslationKeyName() {
      return this.$t("Comn_search");
    },
    computedTableTypeClass() {
      return [
        this.TableTypeClass[this.type] || "",
        this.computedTableProps.hasNestedColumn ? "no-borders" : ""
      ].join(" ");
    },
    computedTableProps() {
      const tableProps = this.elTableProps;
      tableProps.headerRowClassName = `server-side-pagination-table__header-row ${tableProps.headerRowClassName ||
        ""}`;
      tableProps.rowClassName = `server-side-pagination-table__row ${tableProps.rowClassName ||
        ""}`;
      tableProps.cellClassName = `${tableProps.cellClassName || ""}`;
      // tableProps.rowStyle = {
      //   padding: "12px",
      //   "box-shadow": "inset 0px -1px 3px 0px #21496c"
      // };
      return {
        ...tableProps
      };
    }
  },
  created() {
    this.$store.registerModule("table", table);
  },
  watch: {
    total: function(val) {
      this.handlePaginationHideAndUnhide();
    }
  },
  mounted() {
    this.handlePaginationHideAndUnhide();
  },
  beforeDestroy() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
    this.$store.unregisterModule("table");
  },
  methods: {
    ...mapActions("table", {
      changePage: "changePage",
      changeLimit: "changeLimit",
      changeSort: "changeSort"
    }),
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName("pagination-bar");
      if (this.total === 0) {
        ele.item(0).style.display = "none";
      } else {
        ele.item(0).style.display = "block";
      }
    },
    getRowValue(object, path, type) {
      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        return value && value !== "--"
          ? {
              type: "string",
              value: dateUtils.formatDate(
                new Date(value),
                this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
              )
            }
          : { type: "string", value: "--" };
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return {
        type: "string",
        value: Number.isFinite(value) ? Number(value).toFixed(2) : value
      };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case "sort":
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case "size":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "page":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "init":
          break;
        default:
          throw new Error(
            "Not implemented onquerychange for " + queryInfo.type
          );
      }
      this.$emit("query-change", queryInfo);
    },
    sort(property, orderType) {
      const order_by = property;
      let order_type = "asc";
      if (orderType === "descending") {
        order_type = "desc";
      }
      this.changeSort({ order_by, order_type });
    },
    pagination(page, pageSize) {
      this.page = page;
      this.limit = pageSize;
      this.changePage({ current_page: page });
      this.changeLimit({ current_limit: this.limit });
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("cell-click", row, column, cell, event);
    }
  }
};
</script>

<style lang="scss">
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}

.server-side-pagination-table {
  .server-side-pagination-table__row {
    // box-shadow: 1px 1px 6px 2px #e3d1d1;
    td:first-child {
      .cell {
        // margin-left: 10px !important;
      }
    }
  }
  // width: 1300px;
  .no-data__img {
    width: 50px;
    height: 50px;
  }
  .no-data__text {
    text-transform: capitalize;
    // font-size: 11px;
    @include responsiveProperty(font-size, 11px, 11px, 0.8em);
  }
  // .padding-10-0 {
  //   padding: 10px 0;
  // }
  .server-side-pagination-table > .el-table {
    font-size: 11px !important;
  }
  .el-select {
    width: 100%;
  }
  .server-side-pagination-table > .pagination-bar {
    margin-top: 10px;
  }
  .el-table .caret-wrapper {
    height: 22px;
    width: 10px;
    padding-left: 2px;
    .sort-caret {
      position: relative;
      &.ascending {
        left: 0px;
        top: 0px;
        margin-bottom: 2px;
      }
      &.descending {
        left: 0px;
        bottom: 0px;
      }
    }
  }
  .el-pagination {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
    .el-input--mini .el-input__icon {
      line-height: 22px;
    }
  }
  .el-table th .cell {
    font-weight: 600;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-table__header .cell {
    background-color: white;
    color: #233a73 !important;

    & > .cell {
      display: flex;
      -webkit-box-sizing: border-box;
      // border-right: 1px solid white;
      box-sizing: border-box;
      position: relative;
      vertical-align: middle;
      // padding-left: 10px;
      // padding-right: 10px;
      width: 100%;
      font-weight: 600;
      // height: 32px;
      line-height: 20px;
    }
  }
  .el-table__body .cell,
  .el-table__header .cell {
    font-weight: 500;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .pagination-bar {
    margin-top: 10px;
    .el-pagination {
      .el-pagination__total {
        margin-left: 24px;
        align-self: center;
        @include cell-font-size;
      }
      .el-pagination__sizes,
      .el-pagination__jump {
        @include cell-font-size;
        align-self: center;
        .el-input {
          .el-input__inner {
            height: 22px;
            line-height: 22px;
            @include cell-font-size;
          }
          .el-input__icon {
            line-height: 22px;
            @include cell-font-size;
          }
        }
      }
      &.el-pagination {
        .el-select .el-input {
          @include responsiveProperty(min-width, 90px, 110px, 120px);
          margin: unset;
        }
      }
      &.pagination-with-slot {
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-prev .el-icon,
        .btn-next .el-icon,
        .el-pager .number {
          @include cell-font-size;
        }
        .el-pagination__rightwrapper {
          display: flex;
          width: 60%;
          justify-content: flex-end;
        }
        .el-pagination__leftwrapper {
          width: 40%;
          justify-content: flex-end;
          display: flex;
        }
        &.el-pagination button {
          display: inline-block;
          @include cell-font-size;
          height: unset;
          line-height: unset;
          vertical-align: top;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          &.er-button {
            display: inline-flex;
            align-items: center;
            span {
              height: unset;
              line-height: unset;
              @include responsiveProperty(font-size, 11px, 13px, 15px);
            }
          }
          span.material-icons-round {
            min-width: 16px;
          }
        }
      }
    }
  }
  &.card-row-table {
    .server-side-pagination-table__header-row {
      box-shadow: 1px 1px 6px 2px #e3d1d1;
    }
    .server-side-pagination-table__row {
      box-shadow: 1px 1px 6px 2px #e3d1d1;
      .el-table_1_column_1 {
        .cell {
          margin-left: 10px !important;
        }
      }
      td:first-child {
        .cell {
          margin-left: 10px !important;
        }
      }
    }
    .el-table__body,
    .el-table__header {
      border-spacing: 0px 12px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: unset;
    }

    .el-table td,
    .el-table th {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .el-table--mini td,
    .el-table--mini th {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .white-header-table {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #1966a1;
      color: #fff;
    }

    .el-table .descending .sort-caret.descending {
      border-top-color: #1966a1;
    }

    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: #1966a1;
    }
    .er-data-tables__border {
      .el-data-table--border {
        border: none;
      }
    }
    .el-table__header-wrapper {
      border-bottom: 0.9px solid #6c7b8a3b;
    }

    .el-table td,
    .el-table th {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .el-table--mini td,
    .el-table--mini th {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .el-table {
      .cell {
        @include responsiveProperty(line-height, 20px, 25px, 30px);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6px;
        padding-right: 6px;
        border: none;
        .el-tag {
          @include small-text;
          display: inline-flex;
          line-height: 20px;
          align-items: center;
          & + .el-tag {
            margin-right: 2px;
            margin-left: 2px;
          }
        }
        .el-input {
          @include cell-font-size;
        }
      }

      th.is-leaf {
        border-bottom: unset;
      }

      .el-table__header {
        th {
          background-color: white;
          color: #233a73;
          & > .cell {
            display: flex;
            -webkit-box-sizing: border-box;
            // border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            // padding-left: 10px;
            // padding-right: 10px;
            width: 100%;
            font-weight: 600;
            // height: 32px;
            line-height: 20px;
          }
        }
      }
      .el-table__expanded-cell {
        padding: 20px 28px;
      }

      .er-data-tables__header-row:nth-child(2) {
        display: none;
      }
    }
    .el-table td .cell:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-table__fixed-footer-wrapper tbody td {
      // border-top: 1px solid #ebeef5;
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table__footer-wrapper tbody td {
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table::before {
      background-color: #f9f9f9;
    }
    .el-table__fixed::before {
      background-color: #f9f9f9;
    }

    .er-button.el-button--text i {
      color: #66b1ff;
    }
  }
}
</style>
