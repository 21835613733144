/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: renderMenuIcon
Description: This file contains the imports for icons in the navigation menu as javascript objects.
*/
import STOCK from "@/assets/navigation/nav-medicine-stock.svg";
import GATEWAY from "@/assets/navigation/nav-gateway.svg";
import REPORTS from "@/assets/navigation/nav-reports.svg";
import RESOURCES from "@/assets/navigation/nav-resources.svg";
import FEEDING from "@/assets/navigation/nav-pondmother.svg";
import MEDICINE from "@/assets/navigation/nav-medicine.svg";
import ABW from "@/assets/navigation/nav-abw.svg";
import LAB_TESTS from "@/assets/navigation/nav-labtest.svg";
import YIELD from "@/assets/navigation/nav-yield.svg";
import BATTERY from "@/assets/navigation/nav-battery.svg";
import PONDS from "@/assets/navigation/nav-ponds.svg";
import MAPS from "@/assets/navigation/nav-map.svg";
import DASHBOARD from "@/assets/navigation/nav-dashboard.svg";
import SETTINGS from "@/assets/navigation/nav-settings.svg";
export default function(data) {
  switch (data) {
    case "STOCK":
      return STOCK;
    case "GATEWAY":
      return GATEWAY;
    case "REPORTS":
      return REPORTS;
    case "RESOURCES":
      return RESOURCES;
    case "SETTINGS":
      return SETTINGS;
    case "YIELD":
      return YIELD;
    case "LAB_TESTS":
      return LAB_TESTS;
    case "ABW":
      return ABW;
    case "MEDICINE":
      return MEDICINE;
    case "FEEDING":
      return FEEDING;
    case "BATTERY":
      return BATTERY;
    case "PONDS":
      return PONDS;
    case "MAPS":
      return MAPS;
    case "DASHBOARD":
      return DASHBOARD;
    default:
      return DASHBOARD;
  }
}
