/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondlogsRoutes.js
Description: This file contains the router configurations useful for navigating over the pondlogs customer site
*/
import index from "@/views/index";
import view404 from "@/views/view404";
import viewNoInternet from "@/views/viewNoInternet";
import store from "@/store";
import localStore from "@/utils/localStore";
import {
  defaultPageBasedOnPermissions,
  permissionsToStringMap as permissions,
  userTypes,
  userTypesWithAccess
} from "@/middleware/pageAccessManager";

import noPondsView from "@/views/noPondsView";
import vuexModules from "./importVuexModules";

/* user signUp and its related components */
const v2Dashboard = () =>
  import(/* webpackChunkName: "v2Dashboard" */ "@/views/v2Views/v2Dashboard");

export const settings = () =>
  import(/* webpackChunkName: "settings" */ "@/views/manage/settings");
export const helpSection = () =>
  import(/* webpackChunkName: "settings" */ "@/views/manage/helpSection");
export const resources = () =>
  import(/* webpackChunkName: "resources" */ "@/views/manage/resources");
export const reports = () =>
  import(/* webpackChunkName: "reports" */ "@/views/manage/reports");
export const manualFeeding = () =>
  import(
    /* webpackChunkName: "manualFeeding" */ "@/views/culture/manualFeeding"
  );
export const allStocks = () =>
  import(/* webpackChunkName: "allStocks" */ "@/views/stock/allStocks");
export const stockMedicine = () =>
  import(/* webpackChunkName: "stockMedicine" */ "@/views/stock/medicine");
export const stockFeed = () =>
  import(/* webpackChunkName: "stockFeed" */ "@/views/stock/feed");
export const gatewayView = () =>
  import(/* webpackChunkName: "gatewayView" */ "@/views/manage/gateway");
// Overview Menu Group
export const pondsMap = () =>
  import(
    /* webpackChunkName: "pondsMapAndFeeding" */ "@/views/overview/pondsMap"
  );
export const DeviceHealth = () =>
  import(/* webpackChunkName: "battery" */ "@/views/overview/deviceHealth");

export const pondDetails = () =>
  import(/* webpackChunkName: "pondDetails" */ "@/views/overview/pondDetails");
// Culture Menu Group
export const abw = () =>
  import(/* webpackChunkName: "pondDetails" */ "@/views/culture/abw");
export const harvestYield = () =>
  import(/* webpackChunkName: "harvestYield" */ "@/views/culture/harvestYield");
export const pondFeeding = () =>
  import(
    /* webpackChunkName: "pondsMapAndFeeding" */ "@/views/culture/pondFeeding"
  );
export const feeding = () =>
  import(
    /* webpackChunkName: "pondsMapAndFeeding" */ "@/views/culture/feeding"
  );
export const labTests = () =>
  import(/* webpackChunkName: "labTests" */ "@/views/culture/labTests");
export const cultureMedicine = () =>
  import(/* webpackChunkName: "cultureMedicine" */ "@/views/culture/medicine");
export const userProfile = () =>
  import(
    /* webpackChunkName: "userProfile" */ "@/components/settings/userProfile"
  );
const signout = () => {
  store.dispatch("auth/signOut").catch(err => {
    throw err;
  });
};

const defaultPagesBasedOnUserType = (userType, parsedUserPermissions) => {
  const route = defaultPageBasedOnPermissions(parsedUserPermissions);
  switch (userType) {
    case userTypes.super_admin:
    case userTypes.no_access:
      signout();
      return "/sign-in";
    case userTypes.admin:
    case userTypes.manager:
    case userTypes.pond_owner:
      return "/user/dashboard";
    default:
      if (route) {
        return route;
      }
      signout();
      return "/sign-in";
  }
};

const validateForSetupRoute = async function(to, from, next) {
  try {
    const currUserType = localStore.getItem("curr-user-type");
    const userId = localStore.getItem(`${currUserType}-id`);
    if (
      currUserType === undefined ||
      !userTypesWithAccess.includes(currUserType) ||
      !userId
    ) {
      signout();
      return next("/sign-in");
    }
    await store.dispatch("user/fetchUserProfile");
    await store.dispatch("user/fetchUserLocations");
    await store.dispatch("user/setCurrUserLocation", to.query.location_id);
    // const hasLocations = Object.keys(store.getters["user/getUserLocationsObj"])
    //   .length;
    // if (to.meta.dependOnLocations && !hasLocations) {
    //   return next("/user/no-ponds");
    // }
    return next();
  } catch (err) {
    console.error(
      "Check the flow logic and user permissions of the router",
      err
    );
    signout();
    return next("/sign-in");
  }
};
const doesUserCanAccess = function(to, from, next) {
  try {
    const currUserType = localStore.getItem("curr-user-type");
    const userId = localStore.getItem(`${currUserType}-id`);
    if (!currUserType || !userId) {
      signout();
      return next("/sign-in");
    }
    const userProfileObj = store.getters["user/getUserProfile"];
    if (!userProfileObj) {
      return next("/");
    }
    const parsedUserPermissions = store.getters["user/getViewPermissions"];
    // const hasLocations = Object.keys(store.getters["user/getUserLocationsObj"])
    //   .length;
    // if (to.meta.dependOnLocations && !hasLocations) {
    //   return next("/user/no-ponds");
    // }
    const prsntUserType = currUserType;
    const keyToValidateAccess = to.meta.keyToValidateAccess;
    const methodToValidate =
      keyToValidateAccess === "PERMISSIONS"
        ? x => parsedUserPermissions[x]
        : x => x === prsntUserType;
    const canViewItem = to.meta[keyToValidateAccess].some(methodToValidate);
    if (!canViewItem) {
      return next(
        defaultPagesBasedOnUserType(prsntUserType, parsedUserPermissions)
      );
    }
    return next();
  } catch (err) {
    console.error(
      "Check the flow logic and user permissions of the router",
      err
    );
  }
};

export default {
  path: "/user",
  component: index,
  children: [
    {
      path: "/",
      name: "dashboard",
      component: v2Dashboard,
      alias: "dashboard",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [
          permissions.MANAGE_OVERVIEW,
          permissions.MANAGE_CULTURE,
          permissions.MANAGE_DEVICES,
          permissions.MANAGE_ABW,
          permissions.MANAGE_HARVEST
        ],
        storeModules: {
          dashboardTab: {
            name: "dashboard",
            module: vuexModules.dashboardMod
          },
          pondInfoTab: {
            name: "pondInfo",
            module: vuexModules.pondInfoMod
          },
          alertsInfoTab: {
            name: "alertsInfo",
            module: vuexModules.alertsInfoMod
          }
        },
        dependOnLocations: true,
        langKey: "Comn_dashboard"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "map-view",
      name: "map",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
        dependOnLocations: false,
        langKey: "Comn_map",
        storeModules: {
          googleMaps: {
            name: "googleMaps",
            module: vuexModules.googleMapsMod
          },
          pondmother: { name: "pondmother", module: vuexModules.pondmotherMod },
          schedules: { name: "schedules", module: vuexModules.schedulesMod },
          pondguard: { name: "pondguard", module: vuexModules.pondguardMod }
        }
      },
      component: pondsMap,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pond-details",
      name: "pondsDetails",
      component: pondDetails,
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [
          permissions.MANAGE_ABW,
          permissions.MANAGE_CULTURE,
          permissions.MANAGE_SCHEDULES,
          permissions.MANAGE_MEDICINE,
          permissions.MANAGE_LAB_TESTS,
          permissions.MANAGE_DEVICES
        ],
        dependOnLocations: true,
        storeModules: {
          pondDetails: {
            name: "pondDetails",
            module: vuexModules.pondDetailsMod
          },
          pondmother: { name: "pondmother", module: vuexModules.pondmotherMod },
          resource: { name: "resource", module: vuexModules.resourceMod },
          medicines: { name: "medicines", module: vuexModules.medicinesMod },
          labTests: { name: "labTests", module: vuexModules.labTestsMod }
        },
        langKey: "Comn_ponds"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "device-health",
      name: "deviceHealth",
      component: DeviceHealth,
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
        dependOnLocations: true,
        langKey: "Comn_device_health"
      },
      beforeEnter: doesUserCanAccess
    },

    {
      path: "feeding",
      component: feeding,
      name: "feeding",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [
          permissions.MANAGE_CULTURE,
          permissions.MANAGE_SCHEDULES,
          permissions.MANAGE_DEVICES
        ],
        dependOnLocations: true,
        storeModules: {
          pondmother: { name: "pondmother", module: vuexModules.pondmotherMod },
          resource: { name: "resource", module: vuexModules.resourceMod },
          schedules: { name: "schedules", module: vuexModules.schedulesMod }
        },
        childStoreModules: {
          feedInfoTab: {
            module: vuexModules.feedInfoMod,
            name: "feedInfo"
          },
          pondFeeding: {
            module: vuexModules.pondFeedingMod,
            name: "pondFeeding"
          },
          pmFeedingTab: {
            module: vuexModules.pondMotherSchedulesMod,
            name: "pondMotherSchedules"
          }
        },
        langKey: "Comn_feeding"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "culture-medicine",
      component: cultureMedicine,
      name: "cultureMedicine",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_MEDICINE],
        dependOnLocations: true,
        langKey: "Comn_culture_medicine",
        storeModules: {
          resource: { name: "resource", module: vuexModules.resourceMod },
          medicines: { name: "medicines", module: vuexModules.medicinesMod },
          stock: { name: "stock", module: vuexModules.stockMod }
        }
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "abw",
      component: abw,
      name: "abw",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_ABW],
        dependOnLocations: true,
        langKey: "ABW_abw"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "lab-tests",
      component: labTests,
      name: "labTests",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_LAB_TESTS],
        dependOnLocations: true,
        langKey: "LabTst_lab_test",
        storeModules: {
          resource: { name: "resource", module: vuexModules.resourceMod },
          labTests: { name: "labTests", module: vuexModules.labTestsMod }
        }
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "yield",
      component: harvestYield,
      name: "yield",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_HARVEST],
        dependOnLocations: true,
        langKey: "Comn_yield"
      },
      beforeEnter: doesUserCanAccess
    },

    {
      path: "stock",
      name: "stock",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_STOCK],
        dependOnLocations: false,
        langKey: "Stock_stock",
        storeModules: {
          resource: { name: "resource", module: vuexModules.resourceMod },
          stock: { name: "stock", module: vuexModules.stockMod }
        }
      },
      component: allStocks,
      beforeEnter: doesUserCanAccess
    },

    {
      path: "resources",
      name: "resources",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [
          permissions.MANAGE_MEDICINE,
          permissions.MANAGE_LAB_TESTS,
          permissions.MANAGE_SCHEDULES,
          permissions.MANAGE_STOCK
        ],
        dependOnLocations: false,
        storeModules: {
          resource: { name: "resource", module: vuexModules.resourceMod }
        },
        langKey: "Comn_resources"
      },
      component: resources,
      beforeEnter: doesUserCanAccess
    },
    {
      path: "reports",
      name: "reports",
      component: reports,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: [
          userTypes.admin,
          userTypes.pond_manager,
          userTypes.pond_owner
        ],
        dependOnLocations: true,
        langKey: "Comn_reports"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "gateway",
      component: gatewayView,
      name: "gatewayView",
      meta: {
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [
          permissions.MANAGE_CULTURE,
          permissions.MANAGE_SCHEDULES,
          permissions.MANAGE_DEVICES
        ],
        dependOnLocations: true,
        storeModules: {
          gateway: { name: "gateway", module: vuexModules.gatewayMod }
        },
        langKey: "Comn_gateway"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "help",
      name: "help",
      component: helpSection,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: userTypesWithAccess,
        dependOnLocations: false,
        storeModules: {
          schedules: { name: "schedules", module: vuexModules.schedulesMod },
          pondguard: { name: "pondguard", module: vuexModules.pondguardMod },
          shrimpsnap: { name: "shrimpsnap", module: vuexModules.shrimpSnapMod },
          pondmother: { name: "pondmother", module: vuexModules.pondmotherMod },
          resource: { name: "resource", module: vuexModules.resourceMod },
          gateway: { name: "gateway", module: vuexModules.gatewayMod },
          stock: { name: "stock", module: vuexModules.stockMod },
          feedblower: { name: "feedblower", module: vuexModules.feedBlowerMod },
          aquasim: { name: "aquasim", module: vuexModules.aquasimMod },
          userAccessManagement: {
            name: "userAccessManagement",
            module: vuexModules.userAccessManagementMod
          }
        },
        langKey: "Comn_help-section"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "settings",
      name: "settings",
      component: settings,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: userTypesWithAccess,
        dependOnLocations: false,
        storeModules: {
          schedules: { name: "schedules", module: vuexModules.schedulesMod },
          pondguard: { name: "pondguard", module: vuexModules.pondguardMod },
          shrimpsnap: { name: "shrimpsnap", module: vuexModules.shrimpSnapMod },
          pondmother: { name: "pondmother", module: vuexModules.pondmotherMod },
          resource: { name: "resource", module: vuexModules.resourceMod },
          gateway: { name: "gateway", module: vuexModules.gatewayMod },
          stock: { name: "stock", module: vuexModules.stockMod },
          feedblower: { name: "feedblower", module: vuexModules.feedBlowerMod },
          aquasim: { name: "aquasim", module: vuexModules.aquasimMod },
          userAccessManagement: {
            name: "userAccessManagement",
            module: vuexModules.userAccessManagementMod
          }
        },
        langKey: "Comn_settings"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "no-ponds",
      name: "noPondsView",
      component: noPondsView,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: userTypesWithAccess,
        dependOnLocations: false,
        langKey: "PondLogs"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "404",
      name: "pondlogs404",
      component: view404,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: userTypesWithAccess,
        langKey: "PondLogs"
      }
    },
    {
      path: "nointernet",
      name: "nointernet",
      component: viewNoInternet,
      meta: {
        keyToValidateAccess: "USER_TYPES",
        USER_TYPES: userTypesWithAccess,
        langKey: "PondLogs"
      }
    }
  ],
  beforeEnter: validateForSetupRoute
};
