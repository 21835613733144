/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Feed Managemnet
*/
import { $axios } from "../config/axios";

// This service is to get feed varying with time
export default {
  fetchScheduleFeed(params) {
    return $axios.get("ponds/schedule-feed-data", { params });
  },
  fetchAllPondsWithSchedFeedDetails(params) {
    return $axios.get("/ponds/schedules/meta-data", { params });
  },
  fetchSlotWisePondFeed(pondId, params) {
    return $axios.get(`/ponds/${pondId}/slotwise-feed`, { params });
  },
  fetchHourWisePondFeed(pondId, params) {
    return $axios.get(`/ponds/${pondId}/hourly-feed`, { params });
  },
  fetchAllManualFeedingRecords(params) {
    return $axios.get("/ponds/manual-feed", { params });
  },
  fetchAPondManualFeedingRecords(pondId, params) {
    return $axios.get(`/ponds/${pondId}/manual-feed`, {
      params
    });
  },
  saveManualFeedingRecords(feedRecords) {
    return $axios.post("/ponds/manual-feed", feedRecords);
  }
};
