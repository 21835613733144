<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: notificationListItem.vue
Description: This file is a component that part of the notification view. it contains the logic and styling for single list item in the notification view.
-->
<template>
  <el-row class="notification-list-item">
    <!-- <slot
      name="item.icon"
      v-if="$slots['item.icon'] || $slots['item.icon.name'] || listItem.icon"
    >
      <span class="material-icons-outlined notification-list-item__icon">
        <slot name="item.icon.name"> {{ listItem.icon }} </slot>
      </span>
    </slot>
    <slot>
      <el-col>
        <el-row class="notification-list-item__title">
          <b>{{ listItem.title }}</b>
        </el-row>
        <el-row
          :class="[
            'notification-list-item__description',
            isShowMore ? 'is-more' : 'is-less',
          ]"
          ref="nlt__description"
        >
          <span
            :class="['notification-list-item__description-text']"
            ref="nlt__description-text"
          >
            {{ listItem.description }}
          </span>
          <label :class="['notification-list-item__description-show']">
            <input type="checkbox" v-model="isShowMore" />
            {{ !this.isShowMore ? $t("show_more") : $t("show_less") }}
          </label>
        </el-row>
      </el-col>
    </slot> -->
    <el-col :span="24">
      <div class="main-container">
        <el-col :span="24" class="main-container_date">
          {{ getReleaseDate(listItem.created_at) }}
        </el-col>
      </div>
      <el-col
        :span="24"
        v-for="content in listItem.features"
        :name="content.name"
        :key="content._id"
      >
        <div class="main-container_content">
          <el-col :span="1">
            <div class="main-container_content_icon">
              <img
                src="@/assets/help/New Feature.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
          </el-col>
          <el-col :span="23">
            <div
              class="main-container_content_title"
              @click="handleItemClick(listItem, content)"
            >
              {{ content.title }}
            </div>
          </el-col>
        </div>
      </el-col>
      <el-col
        :span="24"
        v-for="content in listItem.enhancements"
        :name="content.title"
        :key="content._id"
      >
        <div class="main-container_content">
          <el-col :span="1">
            <div class="main-container_content_icon">
              <img
                src="@/assets/help/Enhancement.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
          </el-col>
          <el-col :span="23">
            <div
              class="main-container_content_title"
              @click="handleItemClick(listItem, content)"
            >
              {{ content.title }}
            </div>
          </el-col>
        </div>
      </el-col>
      <el-col
        :span="24"
        v-for="content in listItem.bugs"
        :name="content.title"
        :key="content._id"
      >
        <div class="main-container_content">
          <el-col :span="1">
            <div class="main-container_content_icon">
              <img
                src="@/assets/help/Bug.svg"
                style="width: 13px; height: 13px;"
              />
            </div>
          </el-col>
          <el-col :span="23">
            <div
              class="main-container_content_title"
              @click="handleItemClick(listItem, content)"
            >
              {{ content.title }}
            </div>
          </el-col>
        </div>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import eventBus from "@/components/shared/eventBus";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  mixins: [datesHandlerMixin, redirectsMixin],
  props: {
    listItem: {
      type: Object,
      default: () => ({})
    }
  },
  data: function() {
    return {
      isShowMore: false,
      hasMoreContent: true
    };
  },
  computed: {
    getReleaseDate() {
      return (data) => {
        if (data && this.dhm__dateUtilsLib.isValid(new Date(data))) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            data,
            "dd-MMM-yy",
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
      };
    }
  },
  methods: {
    checkHasMoreContent: function() {
      if (
        !this.$refs["nlt__description-text"] &&
        !this.$refs.nlt__description
      ) {
        return true;
      }
      console.log(
        this.$refs["nlt__description-text"].scrollWidth,
        this.$refs.nlt__description.$el.getBoundingClientRect().width
      );
      return (
        this.$refs["nlt__description-text"].scrollWidth >
        this.$refs.nlt__description.$el.getBoundingClientRect().width
      );
    },
    handleItemClick(listItem, item) {
      // alert('item clicked');
      this.rdm__navigateToPathWithLocationId({
        path: "/user/help",
        query: {
          tab: "releaseNotes",
          releaseId: listItem._id
        }
      });
      const eventPayload = {
        listItem,
        item
      };
      eventBus.$emit("notification-item-clicked", eventPayload);
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-list-item {
  display: flex;
  &__icon {
    font-size: 30px;
    margin-right: 10px;
    margin-top: -2px;
    align-self: center;
  }
  &__title,
  &__description {
    max-width: 360px;
    @include small-text;
    text-align: left;
  }
  &__description {
    $description: &;
    &.is-less {
      @include responsiveProperty(max-height, 20px, 23px, 26px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      #{$description}-text {
        display: inline-block;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      #{$description}-show {
        vertical-align: middle;
      }
    }

    &-show {
      position: relative;
      display: inline-block;
      text-decoration: underline;
      color: $primary-color;
      word-break: break-all;
      cursor: pointer;
      input[type^="checkbox"] {
        opacity: 0;
        outline: 0;
        position: absolute;
        margin: 0;
        z-index: -1;
      }
    }
  }
  .main-container {
    &_date {
      margin: 6px 0;
      $text-color-with-dark-bg: #0a2463;
      color: $text-color-with-dark-bg;
      font-weight: 600;
      background-color: transparent;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    &_content {
      display: flex;
      justify-content: space-between;
      margin: 2px 0;
      width: 97%;
      &_icon {
      }
      &_title {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        margin-left: 5px;
        font-family: "Lato";
        font-weight: 400;
        cursor: pointer;
        word-break: break-word;
        $text-color-with-dark-bg: rgb(48, 49, 51);
        color: $text-color-with-dark-bg;
      }
      &_title:hover {
        $text-color-with-dark-bg: #0a2463;
        color: $text-color-with-dark-bg;
      }
    }
  }
}
</style>
