import { render, staticRenderFns } from "./erOptionGroup.vue?vue&type=template&id=8c2cb1bc&scoped=true&"
import script from "./erOptionGroup.vue?vue&type=script&lang=js&"
export * from "./erOptionGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c2cb1bc",
  null
  
)

export default component.exports