/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: headerService.js
Description: This file contains all API service calls in relation to Header inside user screen
*/
import { $axios } from "../config/axios";
export default {
  fetchReleaseNotificationCountService(params) {
    // params: type=WEB
    return $axios.get("ui-version-notifications/count", params);
  },
  fetchReleaseNotificationDataService(params) {
    // params: type=WEB
    return $axios.get("ui-version-notifications", params);
  },
  setReleaseNotificationWatchStatusService(params) {
    // params : {
    //   "ui_version_id": "636363efe63d34051974067b",
    //   "ui_version_type": "WEB"
    // }
    return $axios.post("ui-version-notifications/count", params);
  }
};
