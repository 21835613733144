/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: gateway.js
Description: This file contains all the vuex store functions used in relation to gateways
*/
import GatewayService from "@/services/GatewayService";
import dateUtils from "@/utils/dateUtils";
import { get } from "lodash";
const state = {
  gateways: [],
  gatewayDeviceInfo: [],
  gatewayInfo: []
};
const getters = {
  getGatewayData: state => state.gateways,
  getGatewayDeviceInfo: state => state.gatewayInfo,
  getGatewayInfoPondWise: state => {
    const keyToDeviceMap = {
      pond_guards: "PondGuard",
      pond_mothers: "PondMother",
      shrimp_talks: "ShrimpTalk"
    };
    return state.gatewayDeviceInfo.reduce(
      (acc, { gateway_data = [], status: pond_status, title: pond_title }) => {
        gateway_data.forEach(
          ({ code: gateway_code, devices = {}, title: gateway_title }) => {
            ["pond_mothers", "pond_guards", "shrimp_talks"].forEach(key => {
              (devices[key] || []).forEach(
                ({
                  code: device_code,
                  title: device_title,
                  status: device_status
                }) => {
                  acc.push({
                    pond_title,
                    pond_status,
                    device_title,
                    device_code,
                    sort_device_code: `${pond_title}|${keyToDeviceMap[key]}|${device_code}`,
                    sort_device_title: `${pond_title}|${
                      keyToDeviceMap[key]
                    }|${device_title || device_code}`,
                    device_type: keyToDeviceMap[key],
                    device_status,
                    gateway_code,
                    gateway_title
                  });
                }
              );
            });
          }
        );
        return acc;
      },
      []
    );
  },
  getGatewayInfoTabData: (state, getters) => {
    const arrGateways = getters.getGatewayDeviceInfo;
    return arrGateways.map(gateway => {
      const utc_last_comm_time = gateway.utc_last_communicated_at;
      const timeDiff = dateUtils.differenceInMinutes(
        new Date(utc_last_comm_time || dateUtils.minTimeISO),
        new Date()
      );
      [
        ["lora_channel", "settings.lora_channel"],
        ["ip_address", "settings.ip_address"],
        ["wifi_networks", "settings.wifi_networks"],
        ["mac_address", "settings.mac_address"],
        ["signal", "sensor_values.wifi_signal_RSSI"],
        ["wifi_connection", "sensor_values.wifi_connection"]
      ].reduce((gateway, keyPath) => {
        gateway[keyPath[0]] = get(gateway, keyPath[1], undefined);
        return gateway;
      }, gateway);
      gateway.comm_time_diff = timeDiff;
      return gateway;
    });
  }
};
const mutations = {
  SET_GATEWAY_DATA(state, gatewaysData) {
    state.gateways = gatewaysData;
  },

  SET_GATEWAY_DEVICE_INFO_DATA(state, gatewayDeviceInfo) {
    state.gatewayDeviceInfo = gatewayDeviceInfo;
  },
  SET_GATEWAY_INFO_DATA(state, gatewayInfo) {
    state.gatewayInfo = gatewayInfo;
  }
};
const actions = {
  async fetchGatewayData(context) {
    context.commit("SET_GATEWAY_DATA", []);
    const response = await GatewayService.fetchGatewayData();
    context.commit("SET_GATEWAY_DATA", response.data.gateways);
  },
  async fetchGatewayInfoData(context, params) {
    const response = await GatewayService.fetchGatewayInfoData(params);
    context.commit("SET_GATEWAY_INFO_DATA", response.data.gateways);
  },
  async fetchGatewayDeviceInfoData(context, params) {
    const response = await GatewayService.fetchGatewayDeviceInfoData(params);
    context.commit("SET_GATEWAY_DEVICE_INFO_DATA", response.data.pond_data);
  },
  async saveGatewayDetails(context, payload) {
    await GatewayService.saveGatewayDetails(payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
