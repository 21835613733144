/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: componentColors.js
Description: Contains stateless helper functions used to render colors
*/
import i18n from "@/config/i18n";
export const stIntakeGraphColors = {
  0: { color: "#e64a5d", msg: "No" },
  1: { color: "#f5f559", msg: "Poor" },
  2: { color: "#29fc29", msg: "Good" },
  3: { color: "#000000  ", msg: "Noise" }
};
export const generateColorForMode = function(mode) {
  if (["SHRIMP_TALK", "AUTOMATIC"].indexOf(mode) > -1) {
    return { color: "#39cc64", translation: i18n.t("PM_automatic") };
  } else if (["HYBRID", "ALL_MODES", "MIXED"].indexOf(mode) > -1) {
    return { color: "#F9327A", translation: i18n.t("hybrid") };
  } else if (["FARMER", "SCHEDULE"].indexOf(mode) > -1) {
    return { color: "#F5A623", translation: i18n.t("Comn_schedule") };
  } else if (["BASIC", "MANUAL"].indexOf(mode) > -1) {
    return { color: "#8c92ac", translation: i18n.t("PM_sch_mode4") };
  }
  return { color: "#000000", translation: i18n.t("no_mode") };
};
export const generateColorForModeInGraph = function(mode) {
  if (["SHRIMP_TALK", "AUTOMATIC"].indexOf(mode) > -1) {
    return { color: "#758e32", translation: i18n.t("PM_automatic") };
  } else if (["HYBRID", "ALL_MODES", "MIXED"].indexOf(mode) > -1) {
    return { color: "#F9327A", translation: i18n.t("hybrid") };
  } else if (["FARMER", "SCHEDULE"].indexOf(mode) > -1) {
    return { color: "#f1b727", translation: i18n.t("Comn_schedule") };
  } else if (["BASIC", "MANUAL"].indexOf(mode) > -1) {
    return { color: "#8c92ac", translation: i18n.t("PM_sch_mode4") };
  }
  return { color: "#000000", translation: "-" };
};
export default {
  stIntakeGraphColors,
  generateColorForMode,
  generateColorForModeInGraph
};
