<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erInput.vue
Description:This file contains component which is the extension of  the element ui input component to use as a base component
-->
<template>
  <el-input
    v-bind="$attrs"
    v-on="inputListeners"
    ref="ErInput"
    :class="computedClass"
  >
    <template slot="prepend">
      <slot name="prepend"></slot>
    </template>
    <!-- {{$slots}} -->
  </el-input>
</template>
<script>
export default {
  inheritAttrs: false,
  data: function () {
    return {};
  },
  created () {},
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    },
    computedClass () {
      if (this.$attrs.inputType === "search") {
        return "er-input input--search";
      }
      return "er-input";
    }
  }
};
</script>
<style lang="scss">
.input--search {
  position: relative;
  padding-right: 4px;
  width: $search-input-size;
}
</style>
