/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: superadmin.js
Description:  Contains all vuex store functions of super admin
*/
import { $superAdminAxios } from "../../../config/axios";
import dateUtils from "@/utils/dateUtils";
import {
  saveAccessTokenToLocalStorage,
  saveUserDetailsToLocalStorage,
  setImpersonation,
  setImpersonationBy
} from "@/middleware/auth";
import localStore from "@/utils/localStore";
import activityLog from "./activityLog";
import adminReleaseNotes from "./adminReleaseNotes";
import newRelease from "./newRelease";
import pmStats from "./pmStats";
import inquiryLog from "./inquiryLog";
import { ADMIN_FILTER_TYPES } from "@/constants/filterTypes";
import { lowercase } from "@/utils/filterUtils";
import User from "../../../model/user";
import { ADMIN_USER_UI_STATES } from "@/constants/uiStates";
// import moment from "moment";
/**
 * Return the unix timestamp
 * @param{iso_date_string} isoDateStr
 * @param{time_string} timeStr it is of format HH:mm:ss
 * @return unix time stamp in milliseconds
 */
const getUnixTimeStamp = (isoDateStr, timeStr) => {
  const [hour, mins, secs] = timeStr.split(":");
  const date = dateUtils.zonedTimeToUtc(
    dateUtils.set(
      dateUtils.utcToZonedTime(dateUtils.parseISO(isoDateStr), "UTC"),
      {
        hours: Number(hour),
        minutes: Number(mins),
        seconds: Number(secs)
      }
    ),
    "UTC"
  );
  return date.getTime();
};
const parseInt = string => {
  return Number(string);
};
const getXYValues = (date, time = "00:00:00", parameter) => {
  return {
    x: getUnixTimeStamp(date, time),
    y: parseInt(parameter || 0)
  };
};
const getPmLogsDateFormat = date => {
  const newDate = dateUtils.parse(date, "dd-MM-yy", new Date());
  return dateUtils.formatDate(newDate, dateUtils.isoFormatString);
};

const getStLogsDateFormat = date => {
  const newDate = dateUtils.parse(date, "dd-MM-yyyy", new Date());
  return dateUtils.formatDate(newDate, dateUtils.isoFormatString);
};

const DEFAULT_PM_FEED_DATA = {
  battery_voltage: [],
  dispenser_current: [],
  standard_deviation: [],
  error_code: [],
  motor_status: [],
  dispenser_impedance: [],
  hopper_empty_height: [],
  doser_hall_status: [],
  feed_level_sensor_status: [],
  doser_current: []
};

const DEFAULT_ST_FEED_DATA = {
  battery_voltage: [],
  wifi_signal_RSSI: [],
  sound_intensity: [],
  st_signature_data: []
};

const state = {
  userlocations: [],
  userallponds: [],
  pondguardsensorvalues: [],
  accountmanagerassignedusers: [],
  accountmanagerassignedSkrtUsers: [],
  accountmanagers: [],
  dealerAssignedPondMothers: [],
  dealerAssignedPondGuards: [],
  dealerAssignedShrimpTalks: [],
  dealerAssignedGateways: [],
  unassignedGateways: [],
  unassignedPondMothers: [],
  unassignedPondGuards: [],
  unassignedShrimpTalks: [],
  unassignedFeedBlowers: [],
  unassigned_shrimp_snaps: [],
  gateways: [],
  pondmothers: [],
  pondguards: [],
  shrimptalks: [],
  feedblowers: [],
  shrimp_snaps: [],
  ponds: [],
  users: [],
  countries: [],
  dealers: [],
  clients: [],
  usersOfClient: [],
  userparsingkeys: {},
  useroffsettime: {},
  unassignedUsers: [],
  dealerUsers: [],
  particulardealer: [],
  superAdminProfile: {},
  gateway_sensor_data: { battery_voltage: [] },
  pg_sensor_data: {
    ph: [],
    temperature: [],
    disolve_oxygen: [],
    solar_voltage: [],
    battery_voltage: [],
    charging_current: [],
    load_current: [],
    do_saturation: [],
    electrode_resistance: [],
    temperature_resistance: [],
    salinity: [],
    conductivity: [],
    tds: [],
    error_code: [],
    do_sensor_disconnected: [],
    do_sensor_no_response: [],
    do_invalid_reading: [],
    do_calibration_failed: [],
    solar_disconnected: []
  },
  pm_sensor_data: {
    pm_code: "",
    ph: [],
    temperature: [],
    oxygen: [],
    air_pressure: [],
    air_temperature: [],
    solar_voltage: [],
    lithium_voltage: [],
    hourly_feed: [],
    slot_wise_feed: []
  },
  pm_feed_logs_data: { ...DEFAULT_PM_FEED_DATA },

  st_feed_logs_data: { ...DEFAULT_ST_FEED_DATA },
  pmBatteryVoltageData: [],
  pm_feed_logs_download_csv_status: true,
  shrimpTalkId: "",
  pmId: "",
  pgId: "",
  pg_salinity_Data: [],
  dealerSubscriptionReceivables: [],
  accountManagerUsersAndDevicesRawData: [],
  dealerCustomerSubscriptionReceivables: [],
  dealerCustomerSubscriptionUnassignedCount: "",
  dealerCustomerSubscriptionUnassignedDetails: "",
  costumerDealerAndGroupDetails: {},
  dealerCustomerPondShrmiptalks: [],
  accountManagerExistingRemovedStCounts: {},
  pondShrimptalksBlockData: [],
  userLocationsData: [],
  device_users: [],
  st_group_settings_users: [],
  superAdminUsersData: [],
  filters: {
    [ADMIN_FILTER_TYPES.USER_TYPE]: [],
    [ADMIN_FILTER_TYPES.SEARCH_STRING]: ""
  },
  sidePanel: {
    UI_STATE: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS,
    userDetails: new User()
  },
  updatedPermissions: [],
  permissions: [
    {
      name: "Customers",
      subTabItems: false,
      value: false,
      key: "CUSTOMERS"
    },
    {
      name: "Devices",
      subTabItems: false,
      value: false,
      key: "DEVICES"
    },
    {
      name: "Gateway",
      subTabItems: true,
      value: false,
      key: "GATEWAY"
    },
    {
      name: "PondMother",
      subTabItems: true,
      value: false,
      key: "PONDMOTHER"
    },
    {
      name: "PondGuard",
      subTabItems: true,
      value: false,
      key: "PONDGUARD"
    },
    {
      name: "ShrimpTalk",
      subTabItems: true,
      value: false,
      key: "SHRIMPTALK"
    },
    {
      name: "FeedBlower",
      subTabItems: true,
      value: false,
      key: "FEEDBLOWER"
    },
    {
      name: "ShrimpSnap",
      subTabItems: true,
      value: false,
      key: "SHRIMPSNAP"
    },
    {
      name: "Client",
      subTabItems: false,
      value: false,
      key: "CLIENT"
    },
    {
      name: "Dealer",
      subTabItems: false,
      value: false,
      key: "DEALER"
    },
    {
      name: "Account Manager",
      subTabItems: false,
      value: false,
      key: "ACCOUNT_MANAGER"
    },
    {
      name: "Activity Log",
      subTabItems: false,
      value: false,
      key: "ACTIVITY_LOG"
    },
    {
      name: "Help",
      subTabItems: false,
      value: true,
      key: "HELP"
    },
    {
      name: "Inquiry Log",
      subTabItems: false,
      value: false,
      key: "INQUIRY_LOG"
    },
    {
      name: "Careers",
      subTabItems: false,
      value: false,
      key: "CAREERS"
    },
    {
      name: "Subscriptions",
      subTabItems: false,
      value: false,
      key: "SUBSCRIPTIONS"
    },
    {
      name: "Users",
      subTabItems: false,
      value: false,
      key: "USERS"
    },
    {
      name: "Warranty",
      subTabItems: false,
      value: false,
      key: "WARRANTY"
    },
    {
      name: "L1",
      subTabItems: false,
      value: false,
      key: "L1"
    },
    {
      name: "L2",
      subTabItems: false,
      value: false,
      key: "L2"
    },
    {
      name: "Customer Login",
      subTabItems: false,
      value: false,
      key: "CUSTOMER_LOGIN"
    },
    {
      name: "Add/Remove Devices from Customer",
      subTabItems: false,
      value: false,
      key: "ADD/REMOVE_DEVICES_FROM_CUSTOMER"
    },
    {
      name: "Create New Devices(All)",
      subTabItems: false,
      value: false,
      key: "CREATE_NEW_DEVICES_ALL"
    },
    {
      name: "Update Device Settings(All)",
      subTabItems: false,
      value: false,
      key: "UPDATE_DEVICE_SETTINGS_ALL"
    }
  ],
  stFeedLogsDataLoading: false,
  applicantsData: [],
  jobsData: [],
  claimsData: [],
  partsData: [],
  motorsData: [],
  pmDevicesData: [],
  skrtUserData: [],
  filteredSkrtUserData: []
};

const getters = {
  getShrimpTalkId: state => state.shrimpTalkId,
  getPondGuardId: state => state.pgId,
  getPmId: state => state.pmId,
  getUserParsingKeysCount: state => state.userparsingkeys,
  getAllLocationsofUser: state => state.userlocations,
  getAllPondsofUser: state => state.userallponds,
  getAccountManagerAssingedUsers: state => state.accountmanagerassignedusers,
  getAccountManagerAssingedSkrtUsers: state =>
    state.accountmanagerassignedSkrtUsers,
  getAccountManagers: state => state.accountmanagers,
  getDealerAssignedShrimpTalks: state => state.dealerAssignedShrimpTalks,
  getDealerAssignedGateways: state => state.dealerAssignedGateways,
  getDealerAssignedPondGuards: state => state.dealerAssignedPondGuards,
  getDealerAssignedPondMothers: state => state.dealerAssignedPondMothers,
  getUnassignedPondMothers: state => state.unassignedPondMothers,
  getUnassignedShrimpTalks: state => state.unassignedShrimpTalks,
  getUnassignedPondGuards: state => state.unassignedPondGuards,
  getUnassignedGateways: state => state.unassignedGateways,
  getUnassignedFeedBlowers: state => state.unassignedFeedBlowers,
  getUnassignedShrimpSnaps: state => state.unassigned_shrimp_snaps,
  getUnassignedUsers: state => state.unassignedUsers,
  getFeedBlowers: state => state.feedblowers,
  getDealerUsers: state => state.dealerUsers,
  getSuperAdminProfile(state) {
    return state.superAdminProfile;
  },
  getUserOffSetTime: state => state.useroffsettime,
  getParticularDealer: state => state.particulardealer,
  getDealers: state => state.dealers,
  getGateways: state => state.gateways,
  getPonds: state => state.ponds,
  getUsers: state => state.users,
  getDeviceUsers: state => state.device_users,
  getStGrpSettingUsers: state => state.st_group_settings_users,
  getUsersOfClient: state => state.usersOfClient,
  getClients: state => state.clients,
  getPondMothers: state => state.pondmothers,
  getPondGuards: state => state.pondguards,
  getShrimpTalks: state => state.shrimptalks,
  getShrimpSnaps: state => state.shrimp_snaps,
  getSensorData: state => state.pg_sensor_data,
  getUserIdIndexPmsObj: function(state, getters) {
    const pms = getters.getPondMothers;
    const length = pms.length;
    const userIdPmsObj = {};
    for (let pm = 0; pm < length; pm++) {
      const pmObj = pms[pm];
      if (typeof userIdPmsObj[pmObj.user_id] === "undefined") {
        userIdPmsObj[pmObj.user_id] = [];
      }
      userIdPmsObj[pmObj.user_id].push(pmObj);
    }
    return userIdPmsObj;
  },
  getUserIdIndexPgsObj: function(state, getters) {
    const pgs = getters.getPondGuards;
    const length = pgs.length;
    const userIdPgsObj = {};
    for (let pg = 0; pg < length; pg++) {
      const pgObj = pgs[pg];
      if (typeof userIdPgsObj[pgObj.user_id] === "undefined") {
        userIdPgsObj[pgObj.user_id] = [];
      }
      userIdPgsObj[pgObj.user_id].push(pgObj);
    }
    return userIdPgsObj;
  },
  getUserIdIndexStsObj: function(state, getters) {
    const sts = getters.getShrimpTalks;
    const length = sts.length;
    const userIdStsObj = {};
    for (let st = 0; st < length; st++) {
      const stObj = sts[st];
      if (typeof userIdStsObj[stObj.user_id] === "undefined") {
        userIdStsObj[stObj.user_id] = [];
      }
      userIdStsObj[stObj.user_id].push(stObj);
    }
    return userIdStsObj;
  },
  getUserIdIndexGatewaysObj: function(state, getters) {
    const gateways = getters.getGateways;
    const length = gateways.length;
    const userIdGatewaysObj = {};
    for (let gateway = 0; gateway < length; gateway++) {
      const gatewayObj = gateways[gateway];
      if (typeof userIdGatewaysObj[gatewayObj.user_id] === "undefined") {
        userIdGatewaysObj[gatewayObj.user_id] = [];
      }
      userIdGatewaysObj[gatewayObj.user_id].push(gatewayObj);
    }
    return userIdGatewaysObj;
  },
  getPondsObj: function(state) {
    const arrPonds = state.ponds;
    const length = arrPonds.length;
    const pondsObj = {};
    for (let i = 0; i < length; i++) {
      const user = arrPonds[i];
      pondsObj[user._id] = user;
    }
    return pondsObj;
  },
  getUsersObj: function(state) {
    const arrUsers = state.users;
    const length = arrUsers.length;
    const usersObj = {};
    for (let i = 0; i < length; i++) {
      const user = arrUsers[i];
      usersObj[user._id] = user;
    }
    return usersObj;
  },
  getClientsObj: function(state) {
    const arrClients = state.clients;
    const length = arrClients.length;
    const clientsObj = {};
    for (let i = 0; i < length; i++) {
      const user = arrClients[i];
      clientsObj[user._id] = user;
    }
    return clientsObj;
  },
  getPgsObj(state, getters) {
    const pgs = getters.getPondGuards;
    const pgLength = pgs.length;
    const pgsObj = {};
    for (let pgd = 0; pgd < pgLength; pgd++) {
      pgsObj[pgs[pgd]._id] = pgs[pgd];
    }
    return pgsObj;
  },
  getGatewaysObj: function(state, getters) {
    const gateways = getters.getGateways;
    const gatewayLength = gateways.length;
    const gatewaysObj = {};
    for (let gatewayd = 0; gatewayd < gatewayLength; gatewayd++) {
      gatewaysObj[gateways[gatewayd]._id] = gateways[gatewayd];
    }
    return gatewaysObj;
  },
  getPmsObj: function(state, getters) {
    const pms = getters.getPondMothers;
    const pmsLength = pms.length;
    const pmsObj = {};
    for (let pmd = 0; pmd < pmsLength; pmd++) {
      pmsObj[pms[pmd]._id] = pms[pmd];
    }
    return pmsObj;
  },
  getStsObj: function(state, getters) {
    const sts = getters.getShrimpTalks;
    const stsLength = sts.length;
    const stsObj = {};
    for (let std = 0; std < stsLength; std++) {
      stsObj[sts[std]._id] = sts[std];
    }
    return stsObj;
  },
  getSSsObj: function(state, getters) {
    const sss = getters.getShrimpSnaps;
    const sssLength = sss.length;
    const sssObj = {};
    for (let ssd = 0; ssd < sssLength; ssd++) {
      sssObj[sss[ssd]._id] = sss[ssd];
    }
    return sssObj;
  },
  getPgPHValues: function(state) {
    const data = state.pg_sensor_data.ph
      .map(pgData => {
        // return pgData.data.map((pgData) => {

        return getXYValues(pgData.date, pgData.data.time, pgData.data.ph);
        // });
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
    return data;
  },
  getPgTempValues: function(state) {
    return state.pg_sensor_data.temperature
      .map(pgData => {
        // return pgData.data.map((pgData) => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.temperature
        );
        // });
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDisolveOxygenValues: function(state) {
    return state.pg_sensor_data.disolve_oxygen
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.dissolved_oxygen
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDoSensorDisconnectedValues: function(state) {
    return state.pg_sensor_data.do_sensor_disconnected
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.do_sensor_disconnected
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDoSensorNoResponseValues: function(state) {
    return state.pg_sensor_data.do_sensor_no_response
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.do_sensor_no_response
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDoInvalidReadingValues: function(state) {
    return state.pg_sensor_data.do_invalid_reading
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.do_invalid_reading
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDoCalibrationFailedValues: function(state) {
    return state.pg_sensor_data.do_calibration_failed
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.do_calibration_failed
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgSolarDisconnectedValues: function(state) {
    return state.pg_sensor_data.solar_disconnected
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.solar_disconnected
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgSalinityValues: function(state) {
    return state.pg_salinity_Data
      .map(pgData => {
        return getXYValues(
          pgData.added_date,
          pgData.added_date.split("T")[1].split(".")[0],
          // pgData.data.time,
          pgData.salinity
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgErrorCodeValues: function(state) {
    return state.pg_sensor_data.error_code
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.error_code
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgSolarVoltageValues: function(state) {
    return state.pg_sensor_data.solar_voltage
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.solar_voltage
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgBatteryVoltageValues: function(state) {
    return state.pg_sensor_data.battery_voltage
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.battery_voltage
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgChargingCurrentValues: function(state) {
    return state.pg_sensor_data.charging_current
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.charging_current
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgLoadCurrentValues: function(state) {
    return state.pg_sensor_data.load_current
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.load_current
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgDoSaturationValues: function(state) {
    return state.pg_sensor_data.do_saturation
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.do_saturation
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgElectrodeResistanceValues: function(state) {
    return state.pg_sensor_data.electrode_resistance
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.electrode_resistance
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgTemperatureResistanceValues: function(state) {
    return state.pg_sensor_data.temperature_resistance
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.temperature_resistance
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgConductivityValues: function(state) {
    return state.pg_sensor_data.conductivity
      .map(pgData => {
        return getXYValues(
          pgData.date,
          pgData.data.time,
          pgData.data.conductivity
        );
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgTdsValues: function(state) {
    return state.pg_sensor_data.tds
      .map(pgData => {
        return getXYValues(pgData.date, pgData.data.time, pgData.data.tds);
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPgSalinitySensorValues: function(state) {
    return state.pg_sensor_data.salinity
      .map(pgData => {
        return getXYValues(pgData.date, pgData.data.time, pgData.data.salinity);
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPmLithiumVoltageValues: function(state) {
    return state.pm_sensor_data.lithium_voltage
      .map(pmData => {
        return pmData.data.map(pmTimeData => {
          return getXYValues(
            pmData.date,
            pmTimeData.time,
            pmTimeData.lithium_voltage
          );
        });
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getGatewayLithiumVoltageValues: function(state) {
    return state.gateway_sensor_data.battery_voltage
      .map(gatewayData => {
        return gatewayData.data.map(gatewayTimeData => {
          return getXYValues(
            gatewayData.date,
            gatewayTimeData.time,
            gatewayTimeData.battery_voltage
          );
        });
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPmHourlyFeedData: function(state) {
    const dataHourlyFeed = {};
    const dataHourlyDispFeed = {};
    state.pm_sensor_data.hourly_feed.forEach(day => {
      const date = dateUtils.getUnixTime(dateUtils.parseISO(day.date));
      day.time_slots.forEach(ts => {
        const s_time = ts.s_time;
        const e_time = ts.e_time;
        const s_hour =
          typeof s_time === "string"
            ? Number(s_time.split(":")[0])
            : Number(s_time);
        const e_hour =
          typeof e_time === "string"
            ? Number(e_time.split(":")[0])
            : Number(e_time);
        let dateTimeFeed = null;
        let dateStartFeed = null;
        if (s_hour === 24) {
          dateStartFeed = date + 24 * 60 * 60;
        } else {
          dateStartFeed = date + ts.s_time_in_seconds;
        }
        if (e_hour === 24) {
          dateTimeFeed = date + 24 * 60 * 60;
        } else {
          dateTimeFeed = date + ts.e_time_in_seconds;
        }
        ts.pond_mothers.forEach(pm => {
          if (pm.pond_mother_code === state.pm_sensor_data.pm_code) {
            dataHourlyFeed[dateStartFeed] = ts.feed;
            dataHourlyDispFeed[dateTimeFeed] = ts.dispensed_feed;
          }
        });
      });
    });
    let hourlyFeed = Object.keys(dataHourlyFeed).map(key => {
      return { x: Number(key) * 1000, y: dataHourlyFeed[key] };
    });
    let dispensedHourlyFeed = Object.keys(dataHourlyDispFeed).map(key => {
      return { x: Number(key) * 1000, y: dataHourlyDispFeed[key] };
    });
    hourlyFeed = hourlyFeed.sort((a, b) => a.x - b.x);
    dispensedHourlyFeed = dispensedHourlyFeed.sort((a, b) => a.x - b.x);
    return { hourlyFeed, dispensedHourlyFeed };
  },
  getPmSlotFeedData: function(state) {
    // data necessary
    // total feed for all time slots
    const pmsTotalTimeSlotDFeed = {};
    const pondFeedDaysWise = state.pm_sensor_data.slot_wise_feed;
    // const pmsScheduleDFeed = {};
    for (let iday = 0; iday < pondFeedDaysWise.length; iday++) {
      const day = pondFeedDaysWise[iday];
      const dateEpoch = dateUtils.getTime(dateUtils.parseISO(day.date));
      for (let iTimeSlot = 0; iTimeSlot < day.time_slots.length; iTimeSlot++) {
        const timeSlot = day.time_slots[iTimeSlot];
        const strSTimeETime = timeSlot.s_time + " - " + timeSlot.e_time;
        for (let iPM = 0; iPM < timeSlot.pond_mothers.length; iPM++) {
          const pm = timeSlot.pond_mothers[iPM];
          // if the pm exists and the day exists in the pmsTotalTimeslotDFeed
          if (
            typeof pmsTotalTimeSlotDFeed[pm.pond_mother_code] !== "undefined" &&
            typeof pmsTotalTimeSlotDFeed[pm.pond_mother_code][
              dateEpoch.toString()
            ] !== "undefined"
          ) {
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch].tdfeed +=
              pm.dispensed_feed;
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch].tfeed +=
              pm.feed;
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch][
              strSTimeETime
            ] = {
              timeslot: strSTimeETime,
              dfeed: pm.dispensed_feed,
              feed: pm.feed
            };
          } else {
            // found a new pond mother
            if (
              typeof pmsTotalTimeSlotDFeed[pm.pond_mother_code] === "undefined"
            ) {
              pmsTotalTimeSlotDFeed[pm.pond_mother_code] = {};
            }
            // found a new date
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch] = {};
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch].tdfeed =
              pm.dispensed_feed;
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch].tfeed =
              pm.feed;
            pmsTotalTimeSlotDFeed[pm.pond_mother_code][dateEpoch][
              strSTimeETime
            ] = {
              timeslot: strSTimeETime,
              dfeed: pm.dispensed_feed,
              feed: pm.feed
            };
          }
        }
      }
    }
    let series = [];
    Object.keys(pmsTotalTimeSlotDFeed).forEach((pm_code, index) => {
      if (pm_code !== state.pm_sensor_data.pm_code) {
        return;
      }
      const dayEpochs = pmsTotalTimeSlotDFeed[pm_code];
      const dispensedFeed = Object.keys(dayEpochs).map(dayEpoch => {
        const tdfeed = dayEpochs[dayEpoch].tdfeed;
        const tfeed = dayEpochs[dayEpoch].tfeed;
        delete dayEpochs[dayEpoch].tdfeed;
        delete dayEpochs[dayEpoch].tfeed;

        const timeSlots = Object.values(dayEpochs[dayEpoch]);

        const htmlToolTipStr = [];

        timeSlots.forEach(({ timeslot, dfeed, feed }, index) => {
          // const rowStr = `<tr>
          //   <th scope="row">S${index + 1}</td>
          //   <td>${timeslot.split("-")[0]}</td>
          //   <td>${timeslot.split("-")[1]}</td>
          //   <td>${Number(feed).toFixed(3)}</td>
          //   <td>${Number(dfeed).toFixed(3)}</td>
          //   </tr>`;
          const obj = {
            start_time: timeslot.split("-")[0],
            end_time: timeslot.split("-")[1],
            feed: Number(feed).toFixed(3),
            dFeed: Number(dfeed).toFixed(3)
          };
          htmlToolTipStr.push(obj);
        });
        // htmlToolTipStr += "</table>";
        return {
          x: Number(dayEpoch),
          y: tdfeed,
          tooltip_value: {
            totalFeed: tfeed,
            tdfeed: tdfeed,
            timeSlots: htmlToolTipStr
          }
        };
      });
      dispensedFeed.sort((a, b) => a.x - b.x);
      series.push({
        name: pm_code,
        data: dispensedFeed,
        // type: "areaspline",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          }
        },
        // tooltip: {
        //   pointFormat: "{point.tooltip_value}"
        // },
        visible: false
      });
    });
    if (series.length > 0) {
      series[0].visible = true;
    } else {
      series = [];
    }
    return series;
  },
  getTotalFeedAndConsumedFeed(state, getters) {
    const feed = state.pm_sensor_data.slot_wise_feed;
    return {
      total_feed:
        feed.length && feed.map(c => c.feed).reduce((acc, c) => c + acc, 0),
      consumed_feed:
        feed.length &&
        feed.map(c => c.dispensed_feed).reduce((acc, c) => c + acc, 0)
    };
  },
  getCountries: state => {
    const countries = state.countries;
    const countriesObj = {};
    countries.forEach(({ country_code, country_name }) => {
      countriesObj[country_code] = country_name;
    });
    return countriesObj;
  },
  // pm feed logs sensor data
  getPMBatteryVoltageData: function(state) {
    return state.pm_feed_logs_data.battery_voltage
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPMDispenserCurrentData: function(state) {
    return state.pm_feed_logs_data.dispenser_current
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPMStandardDeviationData: function(state) {
    return state.pm_feed_logs_data.standard_deviation
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPMErrorCodeData: function(state) {
    return state.pm_feed_logs_data.error_code
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPMMotorStatusData: function(state) {
    return state.pm_feed_logs_data.motor_status
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getPMDispenserImpedanceData: function(state) {
    return state.pm_feed_logs_data.dispenser_impedance
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },

  getPMHopperEmptyHeightData: function(state) {
    return state.pm_feed_logs_data.hopper_empty_height
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },

  getPMDoserHallStatusData: function(state) {
    return state.pm_feed_logs_data.doser_hall_status
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },

  getPMFeedLevelSensorStatusData: function(state) {
    return state.pm_feed_logs_data.feed_level_sensor_status
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },

  getPMFeedDoserCurrentData: function(state) {
    return state.pm_feed_logs_data.doser_current
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getPmLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },

  getSTBatteryVoltageData: function(state) {
    return state.st_feed_logs_data.battery_voltage
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getStLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getSTRawDataArray: function(state) {
    return state.st_feed_logs_data?.sound_intensity;
  },
  getStFeedLogsDataLoading: state => state.stFeedLogsDataLoading,
  getSTRawData: function(state) {
    return state.st_feed_logs_data?.sound_intensity.map(element => [
      element[0],
      element[1]
    ]);
    // const arrCopy = [];
    // const data = state.st_feed_logs_data?.sound_intensity || [];
    // data.forEach(element => {
    //   arrCopy.push([element[0], element[1]]);
    // });
    // return arrCopy;
  },
  getSTRawDataSignature: function(state) {
    return state.st_feed_logs_data?.sound_intensity.map(element => [
      element[0],
      element[2]
    ]);
    // const arrCopy = [];
    // const data = state.st_feed_logs_data?.sound_intensity || [];
    // data.forEach(element => {
    //   arrCopy.push([element[0], element[2]]);
    // });
    // return arrCopy;
  },
  getSTWifiSignalRSSIData: function(state) {
    return state.st_feed_logs_data.wifi_signal_RSSI
      .map(pmData => {
        if (pmData) {
          return getXYValues(
            getStLogsDateFormat(pmData.date),
            pmData.time,
            pmData.value
          );
        }
      })
      .flat(1)
      .sort((a, b) => a.x - b.x);
  },
  getDealerSubscriptionReceivables: state =>
    state.dealerSubscriptionReceivables,
  getAccountManagerUsersDevicesRawData: state =>
    state.accountManagerUsersAndDevicesRawData,
  getDealerCustomerSubscriptionReceivables: state =>
    state.dealerCustomerSubscriptionReceivables,
  getDealerCustomerSubscriptionUnassignedCount: state =>
    state.dealerCustomerSubscriptionUnassignedCount,
  getDealerCustomerSubscriptionUnassignedDetails: state =>
    state.dealerCustomerSubscriptionUnassignedDetails,
  getCustomersDealerAndGroupDetails: state =>
    state.costumerDealerAndGroupDetails,
  getCustomerPondShrimptalks: state => state.dealerCustomerPondShrmiptalks,
  getCustomerPondExistingRemovedShrimptalks: state =>
    state.accountManagerExistingRemovedStCounts,
  getCustomerPondBlockShrimptalksData: state => state.pondShrimptalksBlockData,
  getUserLocationsData: state => state.userLocationsData,
  getSuperAdminUsersData: state => state.superAdminUsersData,
  getFilteredSuperUserList: (state, getters) => {
    const filters = getters.getAllActiveFilters;
    return getters.getSuperAdminUsersData.reduce((accSubUsers, user) => {
      const filterTypeToParams = {
        [ADMIN_FILTER_TYPES.USER_TYPE]: filter => [
          filter.data,
          user,
          ["department"]
        ],
        [ADMIN_FILTER_TYPES.SEARCH_STRING]: filter => [
          filter.data,
          user,
          ["department", "email", "full_name"]
        ]
      };
      const isPassedFilter = filters.every(filter =>
        filter.filterFunc(...filterTypeToParams[filter.filterType](filter))
      );
      if (isPassedFilter) {
        accSubUsers.push(user);
      }
      return accSubUsers;
    }, []);
  },
  getFilteredUAMListObj: (state, getters) => {
    return getters.getFilteredSuperUserList.reduce((acc, user) => {
      acc[user._id] = user;
      return acc;
    }, {});
  },
  getAllActiveFilters: function(state, getters) {
    state.filters = getters.getLocalStoreValue;
    return [
      {
        filterType: ADMIN_FILTER_TYPES.USER_TYPE,
        data:
          getters.getLocalStoreValue.length > 0
            ? getters.getLocalStoreValue
            : state.filters[ADMIN_FILTER_TYPES.USER_TYPE],
        filterFunc: (allowedUserTypes, user) => {
          return allowedUserTypes.includes(user.department);
        }
      },
      {
        filterType: ADMIN_FILTER_TYPES.SEARCH_STRING,
        data: state.filters[ADMIN_FILTER_TYPES.SEARCH_STRING],
        filterFunc: (searchString, user, properties) => {
          return properties.some(property => {
            if (Array.isArray(user[property])) {
              return user[property].some(x =>
                lowercase(x).includes(searchString)
              );
            }
            return lowercase(user[property]).includes(searchString);
          });
        }
      }
    ].filter(({ filterType, data }) => {
      return data;
    });
  },
  getLocalStoreValue: function(state, getters) {
    const selectedFiltersData = JSON.parse(
      localStorage.getItem(`SuperUser_userFilterOptions`)
    );
    const filtersData = {
      [ADMIN_FILTER_TYPES.USER_TYPE]: selectedFiltersData,
      [ADMIN_FILTER_TYPES.SEARCH_STRING]: ""
    };
    return filtersData;
  },
  getSidePanelState(state) {
    return state.sidePanel;
  },
  getSidePanelUser(state, getters) {
    return getters.getSidePanelState.userDetails;
  },
  getAdminPermissions(state) {
    return state.updatedPermissions;
  },
  getskrtUserData(state) {
    return state.skrtUserData;
  },
  getFilteredSkrtUserData(state) {
    return state.filteredSkrtUserData;
  },
  getDoSidePanelHasValidUser(state, getters) {
    const sidePanelUser = getters.getSidePanelState.userDetails;
    return Boolean(sidePanelUser);
  },
  getIsSidePanelUserFiltered(state, getters) {
    const sidePanelUser = getters.getSidePanelState.userDetails;
    return (
      !getters.getFilteredSuperUserList[sidePanelUser._id] &&
      getters.getUAMListGroupByUserId[sidePanelUser._id]
    );
  },
  getUAMListGroupByUserId: (state, getters) => {
    return getters.getSuperAdminUsersData.reduce((acc, user) => {
      acc[user._id] = user;
      return acc;
    }, {});
  },
  getAllApplicantsData(state) {
    return state.applicantsData;
  },
  getAllPartsData(state) {
    return state.partsData;
  },
  getAllJobsData(state) {
    return state.jobsData;
  },
  getAllclaimsData(state) {
    return state.claimsData;
  },
  getAllMotorsData(state) {
    return state.motorsData;
  },
  getMappedMotorIds(state) {
    return state.motorsData.reduce((acc, curr) => {
      acc[curr.motor_id] = curr;
      return acc;
    }, {});
  },
  getPmDevicesData(state) {
    return state.pmDevicesData;
  }
  // getPMBatteryVoltageData: state => state.pmBatteryVoltageData
};
const mutations = {
  SET_ACCOUNT_MANAGER_DATA: function(state, accountmanagers) {
    state.accountmanagers = accountmanagers;
  },
  SET_APPEND_ACCOUNT_MANAGER_DATA: function(state, accountmanagers) {
    if (state.accountmanagers.length === 0) {
      state.accountmanagers = accountmanagers;
    } else {
      state.accountmanagers = state.accountmanagers.concat(accountmanagers);
    }
  },
  SET_ACCOUNT_ASSIGNED_USERS: function(state, accountmanagerassignedusers) {
    state.accountmanagerassignedusers = accountmanagerassignedusers;
  },
  SET_ACCOUNT_ASSIGNED_SKRT_USERS: function(
    state,
    accountmanagerassignedSkrtUsers
  ) {
    state.accountmanagerassignedSkrtUsers = accountmanagerassignedSkrtUsers;
  },
  SET_PARTICULAR_DEALER_DATA: function(state, particulardealer) {
    state.particulardealer = particulardealer;
  },
  SET_DEALER_DATA: function(state, dealersData) {
    state.dealers = dealersData;
  },
  SET_GATEWAYS: function(state, gateways) {
    state.gateways = gateways;
  },
  SET_POND_MOTHERS: function(state, pondmothers) {
    state.pondmothers = pondmothers;
  },
  SET_POND_GUARDS: function(state, pondguards) {
    state.pondguards = pondguards;
  },
  SET_SHRIMP_TALKS: function(state, shrimptalks) {
    state.shrimptalks = shrimptalks;
  },
  SET_FEED_BLOWERS: function(state, feedblowers) {
    state.feedblowers = feedblowers;
  },
  SET_SHRIMP_SNAPS: function(state, shrimp_snaps) {
    state.shrimp_snaps = shrimp_snaps;
  },
  SET_PG_SENSOR_DATA: function(state, { data, parameter }) {
    if (!parameter) {
      Object.keys(state.pg_sensor_data).forEach(key => {
        state.pg_sensor_data[key] = data;
      });
    } else {
      state.pg_sensor_data[parameter] = data;
    }
    // state.pg_sensor_data = sensorData;
  },
  SET_PG_SALINITY_DATA: function(state, pg_salinity_Data) {
    state.pg_salinity_Data = pg_salinity_Data;
  },
  SET_GATEWAY_SENSOR_DATA: function(state, { data, parameter }) {
    if (!parameter) {
      Object.keys(state.gateway_sensor_data).forEach(key => {
        state.gateway_sensor_data[key] = data;
      });
    } else {
      state.gateway_sensor_data[parameter] = data;
    }
    // state.pg_sensor_data = sensorData;
  },
  SET_PM_SENSOR_DATA: function(state, { data, parameter }) {
    if (!parameter) {
      Object.keys(state.pm_sensor_data).forEach(key => {
        state.pm_sensor_data[key] = data;
      });
    } else {
      state.pm_sensor_data[parameter] = data;
    }
    // state.pg_sensor_data = sensorData;
  },
  SET_USERS_DATA: function(state, usersData) {
    state.users = usersData;
  },
  SET_DEVICE_USERS_DATA: function(state, usersData) {
    state.device_users = usersData;
  },
  SET_ST_GROUP_SETTINGS_USERS_DATA: function(state, usersData) {
    state.st_group_settings_users = usersData;
  },
  SET_USERS_OF_CLIENT_DATA: function(state, usersData) {
    state.usersOfClient = usersData;
  },
  SET_CLIENTS_DATA: function(state, clientsData) {
    state.clients = clientsData;
  },
  SET_PONDS_DATA: function(state, pondsData) {
    state.ponds = pondsData;
  },
  SET_APPEND_GATEWAYS: function(state, gateways) {
    if (state.gateways.length === 0) {
      state.gateways = gateways;
    } else {
      state.gateways = state.gateways.concat(gateways);
    }
  },
  SET_APPEND_POND_MOTHERS: function(state, pondmothers) {
    if (state.pondmothers.length === 0) {
      state.pondmothers = pondmothers;
    } else {
      state.pondmothers = state.pondmothers.concat(pondmothers);
    }
  },
  SET_APPEND_POND_GUARDS: function(state, pondguards) {
    if (state.pondguards.length === 0) {
      state.pondguards = pondguards;
    } else {
      state.pondguards = state.pondguards.concat(pondguards);
    }
  },
  SET_APPEND_SHRIMP_TALKS: function(state, shrimptalks) {
    if (state.shrimptalks.length === 0) {
      state.shrimptalks = shrimptalks;
    } else {
      state.shrimptalks = state.shrimptalks.concat(shrimptalks);
    }
  },

  SET_APPEND_FEED_BLOWERS: function(state, feedblowers) {
    if (state.feedblowers.length === 0) {
      state.feedblowers = feedblowers;
    } else {
      state.feedblowers = state.feedblowers.concat(feedblowers);
    }
  },
  SET_APPEND_SHRIMP_SNAPS: function(state, shrimp_snaps) {
    if (state.feedblowers.length === 0) {
      state.shrimp_snaps = shrimp_snaps;
    } else {
      state.shrimp_snaps = state.shrimp_snaps.concat(shrimp_snaps);
    }
  },
  SET_APPEND_USERS_DATA: function(state, usersData) {
    if (state.users.length === 0) {
      state.users = usersData;
    } else {
      state.users = state.users.concat(usersData);
    }
  },
  SET_APPEND_USERS_OF_CLIENT_DATA: function(state, usersData) {
    if (state.usersOfClient.length === 0) {
      state.usersOfClient = usersData;
    } else {
      state.usersOfClient = state.users.concat(usersData);
    }
  },
  SET_APPEND_CLIENTS_DATA: function(state, clientsData) {
    if (state.clients.length === 0) {
      state.clients = clientsData;
    } else {
      state.clients = state.clients.concat(clientsData);
    }
  },
  SET_APPEND_DEALERS_DATA: function(state, dealersData) {
    if (state.dealers.length === 0) {
      state.dealers = dealersData;
    } else {
      state.dealers = state.dealers.concat(dealersData);
    }
  },

  SET_APPEND_PONDS_DATA: function(state, pondsData) {
    if (state.ponds.length === 0) {
      state.ponds = pondsData;
    } else {
      state.ponds = state.ponds.concat(pondsData);
    }
  },
  SET_COUNTRIES_LIST(state, countries) {
    state.countries = countries;
  },
  SET_OFFSET_TIME_ZONE(state, useroffsettime) {
    state.useroffsettime = useroffsettime;
  },
  SET_USER_DATA(state, userData) {
    state.superAdminProfile = userData;
  },
  SET_DEALER_USERS(state, dealerUsers) {
    state.dealerUsers = dealerUsers;
  },
  SET_ALL_UNASSIGNED_USERS(state, unassignedUsers) {
    state.unassignedUsers = unassignedUsers;
  },

  SET_UNASSIGNED_GATEWAYS(state, unassignedGateways) {
    state.unassignedGateways = unassignedGateways;
  },
  SET_UNASSIGNED_POND_MOTHERS(state, unassignedPondMothers) {
    state.unassignedPondMothers = unassignedPondMothers;
  },
  SET_UNASSIGNED_POND_GUARDS(state, unassignedPondGuards) {
    state.unassignedPondGuards = unassignedPondGuards;
  },
  SET_UNASSIGNED_SHRIMP_TALKS(state, unassignedShrimpTalks) {
    state.unassignedShrimpTalks = unassignedShrimpTalks;
  },
  SET_UNASSIGNED_FEED_BLOWERS(state, unassignedFeedBlowers) {
    state.unassignedFeedBlowers = unassignedFeedBlowers;
  },
  SET_UNASSIGNED_SHRIMP_SNAPS(state, unassigned_shrimp_snaps) {
    state.unassigned_shrimp_snaps = unassigned_shrimp_snaps;
  },

  SET_DELAER_ASSIGNED_POND_MOTHER(state, dealerAssignedPondMothers) {
    state.dealerAssignedPondMothers = dealerAssignedPondMothers;
  },
  SET_DELAER_ASSIGNED_POND_GAURD(state, dealerAssignedPondGuards) {
    state.dealerAssignedPondGuards = dealerAssignedPondGuards;
  },
  SET_DELAER_ASSIGNED_SHRIMP_TALK(state, dealerAssignedShrimpTalks) {
    state.dealerAssignedShrimpTalks = dealerAssignedShrimpTalks;
  },
  SET_DELAER_ASSIGNED_GATEWAY(state, dealerAssignedGateways) {
    state.dealerAssignedGateways = dealerAssignedGateways;
  },
  SET_POND_GUARDS_SENSOR_DATA(state, pondguardsensorvalues) {
    state.pondguardsensorvalues = pondguardsensorvalues;
  },
  SET_SHRIMP_TALK_ID(state, stId) {
    state.shrimpTalkId = stId;
  },
  SET_POND_GUARD_ID(state, pgId) {
    state.pgId = pgId;
  },
  SET_PM_ID(state, pmId) {
    state.pmId = pmId;
  },
  SET_USERS_ALL_PONDS_DATA(state, userallponds) {
    state.userallponds = userallponds;
  },
  SET_USERS_LOCATIONS(state, userlocations) {
    state.userlocations = userlocations;
  },
  SET_PARTICULAR_USER_PARSING_KEYS(state, userparsingkeys) {
    state.userparsingkeys = userparsingkeys;
  },
  SET_PM_FEED_LOGS_DATA: function(state, { data, parameter }) {
    if (!parameter) {
      Object.keys(state.pm_feed_logs_data).forEach(key => {
        state.pm_feed_logs_data[key] = data[key] || [];
      });
    } else {
      state.pm_feed_logs_data[parameter] = data[parameter];
    }
  },
  SET_ST_FEED_LOGS_RAW_DATA: function(state, data) {
    state.st_feed_logs_data.sound_intensity = data;
  },
  SET_ST_FEED_LOGS_DATA: function(state, { data, parameter }) {
    if (!parameter) {
      Object.keys(state.st_feed_logs_data).forEach(key => {
        state.st_feed_logs_data[key] =
          key !== "sound_intensity" ? data[key] : [];
      });
    } else if (parameter === "sound_intensity") {
      state.st_feed_logs_data.sound_intensity = data;
    } else if (parameter === "signature") {
      state.st_feed_logs_data.st_signature_data = data;
    } else {
      state.st_feed_logs_data[parameter] = data[parameter];
    }
  },
  SET_ST_FEED_LOGS_DATA_LOADING: function(state, loading) {
    state.stFeedLogsDataLoading = loading;
  },
  SET_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerSubscriptionReceivables = subscriptionReceivables;
  },
  SET_ACCOUNT_MANAGER_USERS_DEVICES_RAWDATA(
    state,
    accountManagerUsersAndDevicesRawData
  ) {
    state.accountManagerUsersAndDevicesRawData = accountManagerUsersAndDevicesRawData;
  },
  async SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerCustomerSubscriptionReceivables = await subscriptionReceivables;
  },
  SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT(state, count) {
    state.dealerCustomerSubscriptionUnassignedCount = count;
  },

  SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS(state, count) {
    state.dealerCustomerSubscriptionUnassignedDetails = count;
  },
  SET_DEALER_GROUP_DETAILS(state, costumerDealerAndGroupDetails) {
    state.costumerDealerAndGroupDetails = costumerDealerAndGroupDetails;
  },
  SET_CUSTOMER_POND_SHRIMPTALKS(state, costumerPondSTs) {
    state.dealerCustomerPondShrmiptalks = costumerPondSTs;
  },
  SET_EXISTING_REMOVED_SHRIMPTALKS(state, pondShrimptalks) {
    state.accountManagerExistingRemovedStCounts = pondShrimptalks;
  },
  SET_CUSTOMER_POND_SHRIMPTALKS_BLOCK_DETAILS(state, pondShrimptalksBlockData) {
    state.pondShrimptalksBlockData = pondShrimptalksBlockData;
  },
  SET_USERS_LOCATIONS_DATA(state, userLocationsData) {
    state.userLocationsData = userLocationsData;
  },
  SET_SUPER_ADMIN_USERS_DATA(state, superAdminUsersData) {
    state.superAdminUsersData = superAdminUsersData;
  },
  SET_ADMIN_USER_FILTERS(state, superAdminFiltesData) {
    state.filters[superAdminFiltesData.filter_type] = superAdminFiltesData.data;
    state.filters = Object.assign({}, state.filters);
  },
  SET_SIDE_PANEL_UI_STATE(state, sidePanelUIState) {
    state.sidePanel.UI_STATE = sidePanelUIState;
  },
  SET_SIDE_PANEL_USER_DETAILS(state, userDetails) {
    state.sidePanel.userDetails = userDetails;
  },
  INIT_STORE_STATE: function(state) {
    Object.assign(state.filters, {
      [ADMIN_FILTER_TYPES.USER_TYPE]: [],
      [ADMIN_FILTER_TYPES.SEARCH_STRING]: ""
    });
    Object.assign(state.sidePanel, {
      UI_STATE: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS,
      userDetails: new User()
    });
  },
  SET_PERMISSION: function(state, permissionsData) {
    state.updatedPermissions = permissionsData;
  },
  SET_APPLICANTS_DATA: function(state, applicantsData) {
    state.applicantsData = applicantsData;
  },
  SET_PARTS_DATA: function(state, partsData) {
    state.partsData = partsData;
  },
  SET_JOBS_DATA: function(state, jobsData) {
    state.jobsData = jobsData;
  },
  SET_CLAIM_DATA: function(state, claimsData) {
    state.claimsData = claimsData;
  },
  SET_MOTOR_DATA: function(state, motorsData) {
    state.motorsData = motorsData;
  },
  SET_POND_MOTHER_DATA: function(state, pmDevicesData) {
    state.pmDevicesData = pmDevicesData;
  },
  SET_SKRETTING_USERS_DATA: function(state, skrtUserData) {
    state.skrtUserData = skrtUserData;
  },
  SET_FILTERED_SKRETTING_USERS_DATA: function(state, filteredSkrtUserData) {
    state.filteredSkrtUserData = filteredSkrtUserData;
  }
};

const actions = {
  async enableFeature(context, { user_id, payload }) {
    const response = await $superAdminAxios.patch(
      `/admin/users/${user_id}/device_config`,
      payload
    );
    return response;
  },
  async adminNotifyBackendOnLogout() {
    await $superAdminAxios.post(`/admin/users/logout`);
  },
  async confirmEmail(context, { user_id }) {
    const response = await $superAdminAxios.get(
      `/admin/users/${user_id}/confirm-email`
    );
    return response;
  },
  async resendEmail(context, { user_id }) {
    const response = await $superAdminAxios.get(
      `/admin/users/${user_id}/resend-verification-email`
    );
    return response;
  },
  async removeUser(context, { user_id }) {
    const response = await $superAdminAxios.delete(`/admin/users/${user_id}`);
    return response;
  },
  async addDevicesToUser(context, { user_id, payload, device_type }) {
    const apiToDeviceMap = {
      POND_MOTHER: {
        url: "/admin/pond-mothers/assign-to-user",
        bk_key: "pond_mother_ids"
      },
      POND_GUARD: {
        url: "/admin/pond-guards/assign-to-user",
        bk_key: "pond_guard_ids"
      },
      SHRIMP_TALK: {
        url: "/admin/shrimp-talks/assign-to-user",
        bk_key: "shrimp_talk_ids"
      },
      GATEWAY: { url: "/admin/gateways/assign-to-user", bk_key: "gateway_ids" },
      FEED_BLOWER: {
        url: `/admin/users/${user_id}/feed-blowers`,
        bk_key: "feed_blower_ids"
      },
      SHRIMP_SNAP: {
        url: `/admin/shrimp-snaps/assign-to-user`,
        bk_key: "shrimp_snap_ids"
      }
    };
    const apiObj = apiToDeviceMap[device_type];
    const response = await $superAdminAxios.post(`${apiObj.url}`, {
      [apiObj.bk_key]: payload,
      user_id
    });
    return response;
  },
  async deleteDevicesToUser(context, { user_id, payload, device_type }) {
    const apiToDeviceMap = {
      POND_MOTHER: {
        url: "/admin/pond-mothers/remove-from-user",
        bk_key: "pond_mother_ids"
      },
      POND_GUARD: {
        url: "/admin/pond-guards/remove-from-user",
        bk_key: "pond_guard_ids"
      },
      SHRIMP_TALK: {
        url: "/admin/shrimp-talks/remove-from-user",
        bk_key: "shrimp_talk_ids"
      },
      GATEWAY: {
        url: "/admin/gateways/remove-from-user",
        bk_key: "gateway_ids"
      },
      FEED_BLOWER: {
        url: `/admin/users/${user_id}/feed-blowers`,
        bk_key: "feed_blower_ids"
      },
      SHRIMP_SNAP: {
        url: `/admin/shrimp-snaps/remove-from-user`,
        bk_key: "shrimp_snap_ids"
      }
    };

    const apiObj = apiToDeviceMap[device_type];
    if (device_type === "FEED_BLOWER") {
      const apiObj = apiToDeviceMap[device_type];
      const response = await $superAdminAxios.delete(`${apiObj.url}`, {
        data: { [apiObj.bk_key]: payload }
      });
      return response;
    }
    const response = await $superAdminAxios.post(`${apiObj.url}`, {
      [apiObj.bk_key]: payload,
      user_id
    });
    return response;
  },
  fetchAllUnassignedDeviceDetails: async function(
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) {
    const urlForDevice = {
      gateway: "/admin/un-assigned-gateways",
      pond_mother: "/admin/un-assigned-pond-mothers",
      pond_guard: "/admin/un-assigned-pond-guards",
      shrimp_talk: "/admin/un-assigned-shrimp-talks",
      feed_blower: "admin/feed-blowers",
      shrimp_snap: "/admin/un-assigned-shrimp-snaps"
    };
    const settersStr = {
      gateway: "SET_UNASSIGNED_GATEWAYS",
      pond_mother: "SET_UNASSIGNED_POND_MOTHERS",
      pond_guard: "SET_UNASSIGNED_POND_GUARDS",
      shrimp_talk: "SET_UNASSIGNED_SHRIMP_TALKS",
      feed_blower: "SET_UNASSIGNED_FEED_BLOWERS",
      shrimp_snap: "SET_UNASSIGNED_SHRIMP_SNAPS"
    };

    const responseDeviceKey = {
      gateway: "gateways",
      pond_mother: "pond_mothers",
      pond_guard: "pond_guards",
      shrimp_talk: "shrimp_talks",
      feed_blower: "feed_blowers",
      shrimp_snap: "shrimp_snaps"
    };

    if (!params.order_by) {
      params.include = ["code", "v1_code"];
    }
    const response = await $superAdminAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = settersStr[device_type];
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  },
  fetchAllSensorDeviceDetails: async function(
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) {
    const urlForDevice = {
      pond_guard: "/admin/pond-guards"
    };
    const settersStr = {
      pond_guard: "SET_POND_GUARDS_SENSOR_DATA"
    };
    const setterAppendStr = {
      pond_guard: "SET_APPEND_POND_GUARDS_SENSOR_DATA"
    };
    const responseDeviceKey = {
      pond_guard: "pond_guards"
    };
    if (!params.order_by) {
      params.order_by = "utc_last_communicated_at";
      params.order_type = "desc";
      params.include = [
        "sensor_values",
        "code",
        "first_name",
        "title",
        "user_id",
        "v1_code",
        "_id",
        "settings",
        "dealer_id",
        "on_boarded_at",
        "utc_last_communicated_at",
        "pond_id"
      ];
    }
    const response = await $superAdminAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = infinite_scroll
      ? setterAppendStr[device_type]
      : settersStr[device_type];
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    /* update device communication status based utc last communicated at */
    const deviceData = response.data[responseDeviceKey[device_type]];
    if (deviceData && deviceData.length) {
      for (let i = 0; i < deviceData.length; i++) {
        deviceData[i].utc_last_communicated_at_status = getStatusBasedOnDate(
          deviceData[i],
          device_type
        );
      }
    }
    context.commit(
      setter,
      response.data[responseDeviceKey[device_type]].sensor_values
    );
    return response.data;
  },
  setShrimpTalkId(context, stId) {
    context.commit("SET_SHRIMP_TALK_ID", stId);
  },
  setPgId(context, pgId) {
    context.commit("SET_POND_GUARD_ID", pgId);
  },
  setPmId(context, pmId) {
    context.commit("SET_PM_ID", pmId);
  },
  fetchAllLessDoValues: async function(
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) {
    const urlForDevice = {
      pond_guard: "/admin/pond-guards"
    };
    const settersStr = {
      pond_guard: "SET_POND_GUARDS_SENSOR_DATA"
    };
    const setterAppendStr = {
      pond_guard: "SET_APPEND_POND_GUARDS_SENSOR_DATA"
    };
    const responseDeviceKey = {
      pond_guard: "pond_guards"
    };

    if (!params.order_by) {
      params.order_by = "utc_last_communicated_at";
      params.order_type = "desc";
      params.include = ["sensor_values", "code", "first_name"];
    }

    const response = await $superAdminAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = infinite_scroll
      ? setterAppendStr[device_type]
      : settersStr[device_type];
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }

    /* update device communication status based utc last communicated at */
    const deviceData = response.data[responseDeviceKey[device_type]];
    if (deviceData && deviceData.length) {
      for (let i = 0; i < deviceData.length; i++) {
        deviceData[i].utc_last_communicated_at_status = getStatusBasedOnDate(
          deviceData[i],
          device_type
        );
      }
    }
    context.commit(
      setter,
      response.data[responseDeviceKey[device_type]].sensor_values
    );
    return response.data;
  },
  saveFeedBlower: async function(context, payload) {
    const response = await $superAdminAxios.post("admin/feed-blowers", payload);
    return response;
  },
  fetchAllDeviceDetails: async function(
    context,
    {
      device_type,
      infinite_scroll = false,
      params = {},
      preventApiParms = false
    }
  ) {
    const urlForDevice = {
      gateway: "/admin/gateways",
      pond_mother: "/admin/pond-mothers",
      pond_guard: "/admin/pond-guards",
      shrimp_talk: "/admin/shrimp-talks",
      feed_blower: "admin/feed-blowers",
      shrimp_snap: "admin/shrimp-snaps"
    };
    const settersStr = {
      gateway: "SET_GATEWAYS",
      pond_mother: "SET_POND_MOTHERS",
      pond_guard: "SET_POND_GUARDS",
      shrimp_talk: "SET_SHRIMP_TALKS",
      feed_blower: "SET_FEED_BLOWERS",
      shrimp_snap: "SET_SHRIMP_SNAPS"
    };
    const setterAppendStr = {
      gateway: "SET_APPEND_GATEWAYS",
      pond_mother: "SET_APPEND_POND_MOTHERS",
      pond_guard: "SET_APPEND_POND_GUARDS",
      shrimp_talk: "SET_APPEND_SHRIMP_TALKS",
      feed_blower: "SET_APPEND_FEED_BLOWERS",
      shrimp_snap: "SET_APPEND_SHRIMP_SNAPS"
    };
    const responseDeviceKey = {
      gateway: "gateways",
      pond_mother: "pond_mothers",
      pond_guard: "pond_guards",
      shrimp_talk: "shrimp_talks",
      feed_blower: "feed_blowers",
      shrimp_snap: "shrimp_snaps"
    };
    if (!params.order_by && !preventApiParms) {
      params.order_by = "utc_last_communicated_at";
      params.order_type = "desc";
    }
    const response = await $superAdminAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = infinite_scroll
      ? setterAppendStr[device_type]
      : settersStr[device_type];
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    /* update device communication status based utc last communicated at */
    const deviceData = response.data[responseDeviceKey[device_type]];
    if (deviceData && deviceData.length) {
      for (let i = 0; i < deviceData.length; i++) {
        deviceData[i].utc_last_communicated_at_status = getStatusBasedOnDate(
          deviceData[i],
          device_type
        );
      }
    }
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  },
  clearDeviceDetails(context, { device_type }) {
    const settersStr = {
      gateway: "SET_GATEWAYS",
      pond_mother: "SET_POND_MOTHERS",
      pond_guard: "SET_POND_GUARDS",
      shrimp_talk: "SET_SHRIMP_TALKS",
      feed_blower: "SET_FEED_BLOWERS",
      shrimp_snap: "SET_SHRIMP_SNAPS"
    };
    context.commit(settersStr[device_type], []);
  },
  clearCustomerDetails(context, { device_type }) {
    context.commit("SET_USERS_DATA", []);
  },
  clearClientDetails(context) {
    context.commit("SET_CLIENTS_DATA", []);
  },
  clearDealerDetails(context) {
    context.commit("SET_DEALER_DATA", []);
  },
  fetchStAndPmLogs: async (
    context,
    { stId, from_date, to_date, queryParams }
  ) => {
    const params = {
      from_date,
      to_date,
      ...queryParams
    };
    // const response = await PondsService.fetchShrimpTalkAndPondMotherLog(stId, {
    //   from_date,
    //   to_date
    // });
    const response = await $superAdminAxios.get(
      `/admin/ponds/shrimp-talks/${stId}/shrimp-talk-and-pond-mother-log`,
      {
        params: { ...params }
      }
    );
    return response?.data;
  },
  fetchPmAndStLogs: async (
    context,
    { pmId, from_date, to_date, queryParams }
  ) => {
    const params = {
      from_date,
      to_date,
      ...queryParams
    };
    // const response = await PondsService.fetchShrimpTalkAndPondMotherLog(stId, {
    //   from_date,
    //   to_date
    // });
    const response = await $superAdminAxios.get(
      `/admin/ponds/pond-mothers/${pmId}/shrimp-talk-and-pond-mother-log`,
      {
        params: { ...params }
      }
    );
    // console.log("response superadmin", response);
    return response?.data;
  },
  fetchADeviceData: async function(context, { device_type, device_id }) {
    const urlForDevice = {
      gateway: "/admin/gateways",
      pond_mother: "/admin/pond-mothers",
      pond_guard: "/admin/pond-guards",
      shrimp_talk: "/admin/shrimp-talks",
      shrimp_snap: "/admin/shrimp-snaps"
    };
    const responseDeviceKey = {
      gateway: "gateway_data",
      pond_mother: "pond_mother_data",
      pond_guard: "pond_guard_data",
      shrimp_talk: "shrimp_talk_data",
      shrimp_snap: "shrimp_snap_data"
    };
    const response = await $superAdminAxios.get(
      `${urlForDevice[device_type]}/${device_id}`
    );
    // console.log("response", response);
    return response.data[responseDeviceKey[device_type]];
  },
  assignUsersToDealer: async function(context, assignedUsers) {
    const response = await $superAdminAxios.post(
      "admin/dealers/users",
      assignedUsers
    );
    return response.data;
  },
  addPondMotherToDealer: async function(context, payload) {
    const response = await $superAdminAxios.post(
      "/admin/dealers/pond-mothers",
      payload
    );
    return response.data;
  },
  addPondGuardToDealer: async function(context, payload) {
    const response = await $superAdminAxios.post(
      "/admin/dealers/pond-guards",
      payload
    );
    return response.data;
  },
  addShrimpTalkToDealer: async function(context, payload) {
    const response = await $superAdminAxios.post(
      "/admin/dealers/shrimp-talks",
      payload
    );
    return response.data;
  },
  addGatewayToDealer: async function(context, payload) {
    const response = await $superAdminAxios.post(
      "/admin/dealers/gateways",
      payload
    );
    return response.data;
  },
  fetchAllDealerDeviceDetails: async function(
    context,
    { device_type, dealer_id, infinite_scroll = false, params = {} }
  ) {
    const urlForDevice = {
      gateways: `admin/dealers/${dealer_id}/gateways`,
      pond_mothers: `admin/dealers/${dealer_id}/pond-mothers`,
      pond_guards: `/admin/dealers/${dealer_id}/pond-guards`,
      shrimp_talks: `/admin/dealers/${dealer_id}/shrimp-talks`
    };
    const settersStr = {
      gateways: "SET_GATEWAYS",
      pond_mothers: "SET_DELAER_ASSIGNED_POND_MOTHER",
      pond_guards: "SET_POND_GUARDS",
      shrimp_talks: "SET_SHRIMP_TALKS"
    };
    const setterAppendStr = {
      gateways: "SET_APPEND_GATEWAYS",
      pond_mothers: "SET_APPEND_POND_MOTHERS",
      pond_guards: "SET_APPEND_POND_GUARDS",
      shrimp_talks: "SET_APPEND_SHRIMP_TALKS"
    };
    const responseDeviceKey = {
      gateways: "gateways",
      pond_mothers: "pond_mothers",
      pond_guards: "pond_guards",
      shrimp_talks: "shrimp_talks"
    };

    if (!params.order_by) {
      params.order_by = "utc_last_communicated_at";
      params.order_type = "desc";
      params.include = ["status", "code", "v1_code"];
    }

    const response = await $superAdminAxios.get(urlForDevice[device_type], {
      params
    });

    const setter = infinite_scroll
      ? setterAppendStr[device_type]
      : settersStr[device_type];
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }

    /* update device communication status based utc last communicated at */
    const deviceData = response.data[responseDeviceKey[device_type]];
    if (deviceData && deviceData.length) {
      for (let i = 0; i < deviceData.length; i++) {
        deviceData[i].utc_last_communicated_at_status = getStatusBasedOnDate(
          deviceData[i],
          device_type
        );
      }
    }
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  },
  fetchDealer_PondMothers: async (context, { dealer_id, params = {} }) => {
    const response = await $superAdminAxios.get(
      `admin/dealers/${dealer_id}/pond-mothers`,
      { params }
    );
    context.commit("SET_DELAER_ASSIGNED_POND_MOTHER", response.data);
    return response.data;
  },
  fetchDealer_PondGaurds: async (context, { dealer_id, params = {} }) => {
    const response = await $superAdminAxios.get(
      `admin/dealers/${dealer_id}/pond-guards`,
      { params }
    );
    context.commit("SET_DELAER_ASSIGNED_POND_GAURD", response.data.pond_guards);
    return response.data;
  },
  fetchDealer_ShrimpTalks: async (context, dealer_id) => {
    const response = await $superAdminAxios.get(
      `admin/dealers/${dealer_id}/shrimp-talks`
    );
    context.commit(
      "SET_DELAER_ASSIGNED_SHRIMP_TALK",
      response.data.shrimp_talks
    );
    return response.data;
  },
  fetchDealer_Gateways: async (context, dealer_id) => {
    const response = await $superAdminAxios.get(
      `admin/dealers/${dealer_id}/gateways`
    );
    context.commit("SET_DELAER_ASSIGNED_GATEWAY", response.data.gateways);
    return response.data;
  },
  fetchAllUsers: async function(context, { infinite_scroll, params = {} }) {
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("/admin/users", { params });
    const setter = infinite_scroll ? "SET_APPEND_USERS_DATA" : "SET_USERS_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.users);
    return response.data;
  },
  fetchAllDeviceUsers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("/admin/users", { params });
    const setter =
      infinite_scroll === "grp_settings"
        ? "SET_ST_GROUP_SETTINGS_USERS_DATA"
        : "SET_DEVICE_USERS_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.users);
    return response.data;
  },
  alreadyAssignedUsersToDealer: async (context, { dealer_id, params = {} }) => {
    const response = await $superAdminAxios.get(
      `admin/dealers/${dealer_id}/users`,
      { params }
    );
    context.commit("SET_DEALER_USERS", response.data.users);
    return response.data;
  },
  deletePmSchedules: async function(context, { pmIds, params }) {
    const payload = {
      pond_mothers: pmIds
    };
    const response = await $superAdminAxios.delete("/pond-mothers/schedules", {
      params,
      data: payload
    });
    return response.data;
  },
  fetchAllUnAssignedUsers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    const response = await $superAdminAxios.get("admin/un-assigned-users", {
      params
    });

    context.commit("SET_ALL_UNASSIGNED_USERS", response.data.users);
    return response.data;
  },
  removeAssignedUsersToDealer: async (context, users) => {
    const payload = users;
    const response = await $superAdminAxios.delete("admin/dealers/users", {
      data: payload
    });
    return response.data;
  },
  fetchCountryList(context) {
    return new Promise((resolve, reject) => {
      $superAdminAxios
        .get("/countries")
        .then(response => {
          context.commit("SET_COUNTRIES_LIST", response.data.countries);
          resolve(response.data.message);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTimeZoneInfo: async (context, countrycode) => {
    const response = await $superAdminAxios.get(
      `countries/${countrycode}/timezones`
    );
    context.commit("SET_OFFSET_TIME_ZONE", response.data.timezones);
    return response.data;
  },
  AddAccountManager: async (context, accountmanager) => {
    const response = await $superAdminAxios.post(
      "admin/account-managers",
      accountmanager
    );
    return response.data;
  },
  EditAccountManager: async (context, accountmanager) => {
    const response = await $superAdminAxios.patch(
      `admin/account-managers/${accountmanager._id}`,
      accountmanager
    );
    return response.data;
  },
  AddDealer: async (context, dealer) => {
    const response = await $superAdminAxios.post("admin/dealers", dealer);
    return response.data;
  },
  EditDealer: async (context, dealer) => {
    const response = await $superAdminAxios.patch(
      `admin/dealers/${dealer._id}`,
      dealer
    );
    return response.data;
  },
  EditSkrettingTechnicians: async (context, skrt) => {
    const response = await $superAdminAxios.patch(
      `admin/skretting-technicians/${skrt._id}`,
      skrt
    );
    return response.data;
  },
  addUserToAccountManger: async (context, payload) => {
    const response = await $superAdminAxios.post(
      "/admin/account-managers/users",
      payload
    );
    return response;
  },
  addSkrtUserToAccountManger: async (context, payload) => {
    const response = await $superAdminAxios.patch(
      `/admin/account-managers/${payload.account_manager_id}/skretting-technicians`,
      payload
    );
    return response;
  },
  removeAssignedDevicesFromDealer: async (
    context,
    { payload, device_type }
  ) => {
    const apiToDeviceMap = {
      POND_MOTHER: {
        url: "/admin/dealers/pond-mothers",
        bk_key: "pond_mother_ids"
      },
      POND_GUARD: {
        url: "/admin/dealers/pond-guards",
        bk_key: "pond_guard_ids"
      },
      SHRIMP_TALK: {
        url: "/admin/dealers/shrimp-talks",
        bk_key: "shrimp_talk_ids"
      },
      GATEWAY: {
        url: "/admin/dealers/gateways",
        bk_key: "gateway_ids"
      }
    };
    const apiObj = apiToDeviceMap[device_type];
    const response = await $superAdminAxios.delete(`${apiObj.url}`, {
      data: { [apiObj.bk_key]: payload }
    });
    return response;
  },
  removeAssignedUsersFromAccountManager: async (context, payload) => {
    const response = await $superAdminAxios.delete(
      "/admin/account-managers/users",
      {
        data: payload
      }
    );
    return response;
  },
  removeAssignedSkrtUsersFromAccountManager: async (context, payload) => {
    const response = await $superAdminAxios.delete(
      `/admin/account-managers/skretting-technicians/${payload.skrt_id}`
    );
    return response;
  },
  fetchAllAccountManagers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "email";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("admin/account-managers", {
      params
    });
    const setter = infinite_scroll
      ? "SET_APPEND_ACCOUNT_MANAGER_DATA"
      : "SET_ACCOUNT_MANAGER_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.account_managers);
    return response.data;
  },
  fetchAllSkrettingUsers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "email";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("admin/skretting-technicians", {
      params
    });
    context.commit("SET_SKRETTING_USERS_DATA", response.data.account_managers);
    return response.data;
  },
  fetchAllFilteredSkrettingUsers: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    const response = await $superAdminAxios.get("admin/skretting-technicians", {
      params
    });
    context.commit(
      "SET_FILTERED_SKRETTING_USERS_DATA",
      response.data.account_managers
    );
    return response.data;
  },
  fetchAlreadyAssingedAccountUsers: async (
    context,
    { accountmanager_id, params = {} }
  ) => {
    const response = await $superAdminAxios.get(
      `admin/account-managers/${accountmanager_id}/users`,
      { params }
    );
    context.commit("SET_ACCOUNT_ASSIGNED_USERS", response.data.users);
    return response.data;
  },
  fetchAlreadyAssingedAccountSkrtUsers: async (
    context,
    { accountmanager_id, params = {} }
  ) => {
    const response = await $superAdminAxios.get(
      `admin/account-managers/${accountmanager_id}/skretting-technicians`,
      { params }
    );
    context.commit("SET_ACCOUNT_ASSIGNED_SKRT_USERS", response.data.users);
    return response.data;
  },
  fetchAllDealers: async function(context, { infinite_scroll, params = {} }) {
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("admin/dealers", { params });
    const setter = infinite_scroll
      ? "SET_APPEND_DEALERS_DATA"
      : "SET_DEALER_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.dealers);
    return response.data;
  },
  fetchDealerById: async function(context, dealer_id) {
    const response = await $superAdminAxios.get(`admin/dealers/${dealer_id}`);
    context.commit("SET_PARTICULAR_DEALER_DATA", response.data.dealer);
    return response.data;
  },
  fetchAUserDetails: async function(context, { user_id, params = {} }) {
    const response = await $superAdminAxios.get(`admin/users/${user_id}`, {
      params
    });
    return response.data.user;
  },
  fetchACustomerParsingKeys: async function(context, { user_id, params = {} }) {
    const response = await $superAdminAxios.get(
      `admin/users/${user_id}/device-counts`
    );
    context.commit("SET_PARTICULAR_USER_PARSING_KEYS", response.data.data);

    return response;
  },
  fetchAUserDetails_FeedBlower: async function(context, { user_id }) {
    const response = await $superAdminAxios.get(
      `admin/users/${user_id}/feed-blowers`
    );
    return response.data;
  },
  clearUserData: async function(context) {
    context.commit("SET_USERS_DATA", []);
  },
  fetchAllPondsofUser: async function(context, { params = {} }) {
    const response = await $superAdminAxios.get("admin/ponds", {
      params
    });
    context.commit("SET_USERS_ALL_PONDS_DATA", response.data.ponds);
    return response.ponds;
  },
  fetchAllLocationsofUser: async function(context, { user_id, params = {} }) {
    const response = await $superAdminAxios.get(
      `admin/users/${user_id}/locations`,
      { params }
    );
    context.commit("SET_USERS_LOCATIONS", response.data.user_locations);
    return response.data;
  },
  fetchAllPonds: async function(context, params = {}) {
    const response = await $superAdminAxios.get("/admin/ponds", { params });
    context.commit("SET_PONDS_DATA", response.data.ponds);
    return response.data;
  },
  fetchAPondGuardLatestSensorData: async function(
    context,
    { pond_guard_id, params, parameter = null }
  ) {
    const response = await $superAdminAxios.get(
      `/admin/pond-guards/${pond_guard_id}/sensor-data`,
      { params }
    );
    if (parameter === null) {
      context.commit("SET_PG_SENSOR_DATA", {
        data: response.data.pond_guard_data,
        parameter: null
      });
    } else {
      context.commit("SET_PG_SENSOR_DATA", {
        data: response.data.pond_guard_data,
        parameter
      });
    }
    return response.data.pond_guard_data;
  },
  fetchAPondGuardLatestSalinityData: async function(
    context,
    { pond_guard_id, params }
  ) {
    const salinityResponse = await $superAdminAxios.get(
      `/admin/pond-guards/${pond_guard_id}/salinity-logs`,
      { params }
    );
    context.commit(
      "SET_PG_SALINITY_DATA",
      salinityResponse.data.pond_guard_data
    );
    return salinityResponse.data.pond_guard_data;
  },
  fetchAGatewayLatestSensorData: async function(
    context,
    { gateway_id, params, parameter = null }
  ) {
    const response = await $superAdminAxios.get(
      `/admin/gateways/${gateway_id}/sensor-data`,
      { params }
    );
    if (parameter === null) {
      context.commit("SET_GATEWAY_SENSOR_DATA", {
        data: response.data.gateway_data,
        parameter: null
      });
    } else {
      context.commit("SET_GATEWAY_SENSOR_DATA", {
        data: response.data.gateway_data,
        parameter
      });
    }
    return response.data.gateway_data;
  },
  fetchAPMHourlyFeedData: async function(
    context,
    { pm_id, pm_code, params, graphParameter = null }
  ) {
    const responseHourly = await $superAdminAxios.get(
      `/admin/pond-mothers/${pm_id}/hourly-feed`,
      {
        params
      }
    );
    const responseData = responseHourly.data.pond_hourly_feed_logs;
    context.commit("SET_PM_SENSOR_DATA", {
      data: pm_code,
      parameter: "pm_code"
    });
    if (graphParameter === null) {
      context.commit("SET_PM_SENSOR_DATA", {
        data: responseData,
        parameter: null
      });
    } else {
      context.commit("SET_PM_SENSOR_DATA", {
        data: responseData,
        parameter: "hourly_feed"
      });
    }
    return responseData;
  },
  async fetchAPMSlotWiseFeedData(
    context,
    { pm_id, pm_code, params, graphParameter = null }
  ) {
    const responseSlotWise = await $superAdminAxios.get(
      `/admin/pond-mothers/${pm_id}/slotwise-feed`,
      {
        params
      }
    );
    const responseData = responseSlotWise.data.pond_slotwise_feed_logs;

    context.commit("SET_PM_SENSOR_DATA", {
      data: pm_code,
      parameter: "pm_code"
    });
    if (graphParameter === null) {
      context.commit("SET_PM_SENSOR_DATA", {
        data: responseData,
        parameter: null
      });
    } else {
      context.commit("SET_PM_SENSOR_DATA", {
        data: responseData,
        parameter: "slot_wise_feed"
      });
    }
    return responseData;
  },
  clearPMSensorData(context) {
    context.commit("SET_PM_SENSOR_DATA", {
      data: [],
      parameter: "slot_wise_feed"
    });
    context.commit("SET_PM_SENSOR_DATA", {
      data: [],
      parameter: "hourly_feed"
    });
  },

  clearPMFeedData(context) {
    context.commit("SET_ST_FEED_LOGS_DATA", {
      data: { ...DEFAULT_ST_FEED_DATA },
      parameter: ""
    });

    context.commit("SET_PM_FEED_LOGS_DATA", {
      data: { ...DEFAULT_PM_FEED_DATA },
      parameter: ""
    });
  },

  fetchAPondGuardData: async function(context, pond_guard_id) {
    const response = await $superAdminAxios.get(
      `/admin/pond-guards/${pond_guard_id}`
    );
    return response.data.pond_guard_data;
  },
  fetchGatewayCommunicationStats: async function(
    context,
    { gateway_id, params }
  ) {
    // params.order_by = "last_communicated_at";
    // params.order_type = "desc";
    const response = await $superAdminAxios.get(
      `/admin/gateways/${gateway_id}/communication-stats`,
      { params }
    );
    return response.data;
  },
  fetchUserToken: async function(context, { userId, params }) {
    const response = await $superAdminAxios.get(
      `/admin/users/${userId}/signin`,
      {
        params
      }
    );
    // saving accesstoken and refresh in the local storage
    const { user_details, access_token, refresh_token } = response.data;
    saveUserDetailsToLocalStorage(user_details);
    saveAccessTokenToLocalStorage({
      access_token,
      refresh_token
    });
    // saving the user data from the response
    context.commit("user/SET_USER_DATA", response.data.user_details, {
      root: true
    });
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
    return response.data;
  },
  async loginAsSuperAdmin(context) {
    const userId = localStore.getItem(`SUPER_ADMIN-id`);
    const credentials = localStore.getItem(`SUPER_ADMIN-${userId}`);
    console.log(credentials);
    if (credentials === undefined) {
      throw new Error("No Keys Available");
    }
    $superAdminAxios.defaults.headers.common.Authorization =
      credentials.access_token;
    await context.dispatch("fetchUserProfile");
    const userProfile = context.getters.getSuperAdminProfile;
    saveUserDetailsToLocalStorage(userProfile);
    saveAccessTokenToLocalStorage(credentials);
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
  },
  fetchUserProfile: async context => {
    const response = await $superAdminAxios.get("/profile");
    context.commit("SET_USER_DATA", response.data.user_details);
    context.commit("user/SET_USER_DATA", response.data.user_details, {
      root: true
    });
    // localStore.setItem("USER_DATA_ACCESS", response.data.user_details);
    context.commit("user/SET_USER_DEVICE_DATA", null, {
      root: true
    });
    const userDetails = response.data.user_details;
    return userDetails;
  },
  async loginAsCustomer(context, userId) {
    setImpersonation(true);
    setImpersonationBy("SUPER_ADMIN");
    await context.dispatch("fetchUserToken", { userId, params: {} });
  },
  async saveSingleDeviceSettingsData(
    context,
    { device_code, settingsType, payload }
  ) {
    const urls = {
      POND_MOTHER: `/admin/pond-mothers/${device_code}/settings`,
      POND_GUARD: `/admin/pond-guards/${device_code}/settings`,
      SHRIMP_TALK: `/admin/shrimp-talks/${device_code}/settings`,
      GATEWAY: `/admin/gateways/${device_code}/settings`,
      SHRIMP_SNAP_DEVICE: `/admin/shrimp-snaps/${device_code}/settings`,
      SHRIMP_SNAP_IMAGE: `/admin/shrimp-snaps/${device_code}/image-settings`
    };
    const response = await $superAdminAxios.post(urls[settingsType], payload);
    return response;
  },
  async saveListDevicesData(context, { device_type, payload }) {
    const deviceToApiMap = {
      POND_MOTHER: "/admin/pond-mothers",
      POND_GUARD: "/admin/pond-guards",
      SHRIMP_TALK: "/admin/shrimp-talks",
      GATEWAY: "/admin/gateways",
      SHRIMP_SNAP: "/admin/shrimp-snaps"
    };
    const response = await $superAdminAxios.post(
      `${deviceToApiMap[device_type]}`,
      payload
    );
    return response;
  },
  fetchAllClients: async function(context, { infinite_scroll, params = {} }) {
    params.order_by = "title";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("/admin/clients", { params });

    const setter = infinite_scroll
      ? "SET_APPEND_CLIENTS_DATA"
      : "SET_CLIENTS_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.clients);
    return response.data;
  },
  addUsersToClient: async function(context, payload) {
    const response = await $superAdminAxios.post(
      "/admin/clients/users",
      payload
    );
    return response;
  },
  deleteUsersToClient: async function(context, payload) {
    const response = await $superAdminAxios.delete("/admin/clients/users", {
      data: payload
    });
    return response;
  },
  createClient: async function(context, payload) {
    const response = await $superAdminAxios.post("/admin/clients", payload);
    return response;
  },
  updateClient: async function(context, { client_id, title }) {
    const response = await $superAdminAxios.patch(
      `/admin/clients/${client_id}`,
      {
        title
      }
    );
    return response;
  },
  fetchUsersOfClient: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superAdminAxios.get("/admin/users", { params });
    // console.log(response);
    const setter = infinite_scroll
      ? "SET_APPEND_USERS_OF_CLIENT_DATA"
      : "SET_USERS_OF_CLIENT_DATA";
    // console.log(infinite_scroll);
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.users);
    return response.data;
  },
  updateASettingKey: async function(
    context,
    { payload, device_type, setting_key }
  ) {
    const { code } = payload;
    const apiToDeviceMap = {
      shrimp_talk: {
        url: `admin/shrimp-talks/${code}/settings`
      },
      gateway: {
        url: `admin/gateways/${code}/settings`
      }
    };
    const api = apiToDeviceMap[device_type];

    const response = await $superAdminAxios.post(`${api.url}`, {
      [setting_key]: payload[setting_key]
    });
    return response.data;
  },
  fetchPMFeedLogsGraphData: async function(
    context,
    { pm_id, pm_code, params, graphParameter = null }
  ) {
    const responseHourly = await $superAdminAxios.get(
      `pond-mother/${pm_code}/feed-logs/graphs-data`,
      {
        params
      }
    );
    const responseData = responseHourly.data.data;

    context.commit("SET_PM_FEED_LOGS_DATA", {
      data: responseData,
      parameter: ""
    });

    return responseHourly;
  },

  fetchSTFeedLogsGraphData: async function(
    context,
    { shrimp_talk_code, params, graphParameter = null }
  ) {
    const responseTimewise = await $superAdminAxios.get(
      `shrimp-talk/${shrimp_talk_code}/feed-logs/graphs-data`,
      {
        params
      }
    );
    const responseData = responseTimewise.data.data;
    context.commit("SET_ST_FEED_LOGS_DATA", {
      data: responseData,
      parameter: ""
    });
    return responseTimewise;
  },
  fetchSTRawDataGraphData: async function(context, { params }) {
    const limit = params.limit;
    let offset = params.offset || 0;
    let totalRecords = 0;
    let allData = [];
    let count = 0;
    context.commit("SET_ST_FEED_LOGS_DATA_LOADING", true);
    async function fetchData() {
      try {
        params.offset = offset;
        const response = await $superAdminAxios.get(`admin/st-raw-data`, {
          params
        });
        totalRecords = response.data.count;
        offset += limit;
        allData = [...allData, ...response.data.data];
        context.commit("SET_ST_FEED_LOGS_RAW_DATA", allData);
        if (count === 0) {
          context.commit("SET_ST_FEED_LOGS_DATA_LOADING", false);
        }
        if (totalRecords > offset) {
          count++;
          await fetchData();
        }
      } catch (error) {
        context.commit("SET_ST_FEED_LOGS_DATA_LOADING", false);
      }
    }
    await fetchData();
  },
  fetchPMFeedLogsDownloadCSV: async function(
    context,
    { pm_id, pm_code, params, responseType }
  ) {
    const response = await $superAdminAxios.get(
      `pond-mother/${pm_code}/feed-logs/download-csv`,
      {
        params,
        responseType
      }
    );
    return response;
  },

  fetchSTFeedLogsDownloadCSV: async function(
    context,
    { shrimp_talk_code, params, responseType }
  ) {
    const response = await $superAdminAxios.get(
      `shrimp-talk/${shrimp_talk_code}/feed-logs/download-csv`,
      {
        params,
        responseType
      }
    );
    return response;
  },
  fetchPGSensorDataDownloadCSV: async function(
    context,
    { pg_code, params, responseType }
  ) {
    const response = await $superAdminAxios.get(
      `admin/pond-guards/${pg_code}/sensor-data-logs/download-csv`,
      {
        params,
        responseType
      }
    );
    return response;
  },

  updateBlockStStatus: async function(context, payload) {
    const api = "admin/shrimp-talks/update-block-status";

    const response = await $superAdminAxios.post(api, payload);
    return response.data;
  },
  fetchAllSubscriptionReceivables: async function(context, params) {
    const response = await $superAdminAxios.get(
      `/dealer/users-shrimp-talk-subscription-receivables`,
      {
        params
      }
    );
    context.commit(
      "SET_SUBSCRIPTION_RECEIVABLES",
      response.data.shrimptalk_subscription_receivables
    );
    return response;
  },
  fetchAccountManagerUsersAndDevicesRawData: async (context, params) => {
    const response = await $superAdminAxios.get(
      "dealer/download-user-shrimp-talk-subscription-receivables",
      {
        params,
        responseType: "blob"
      }
    );
    context.commit("SET_ACCOUNT_MANAGER_USERS_DEVICES_RAWDATA", response.data);
    return response;
  },
  fetchCustomerSubscriptionReceivables: async (context, { id, params }) => {
    const response = await $superAdminAxios.get(
      `/dealer/user-shrimp-talk-subscription-receivables/${id}`,
      {
        params
      }
    );
    context.commit("SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES", response.data.data);
    context.commit("SET_DEALER_GROUP_DETAILS", {
      dealer_name: response.data.dealer_name,
      group_name: response.data.group_name
    });
    context.commit(
      "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT",
      response.data.unassigned_devices_with_out_subscription_count +
        response.data.assigned_devices_with_out_subscription_count
    );

    context.commit(
      "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS",
      response.data.unassigned_devices_with_subscription
    );

    return response;
  },
  fetchCustomerPondShrimptalks: async (context, { id, params }) => {
    try {
      const response = await $superAdminAxios.get(
        `/dealer/pond-subscription-shrimp-talks/${id}`,
        {
          params
        }
      );
      context.commit("SET_CUSTOMER_POND_SHRIMPTALKS", response.data.data);
      context.commit("SET_EXISTING_REMOVED_SHRIMPTALKS", response.data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchCustomerPondShrimptalksBlockData: async (context, params) => {
    try {
      const response = await $superAdminAxios.get(`admin/shrimp-talks`, {
        params
      });
      if (!params.get_all) {
        const BlockSTData = response.data.shrimp_talks.reduce((acc, curr) => {
          const obj = {
            location: {
              name: curr?.location_details?.name || "",
              _id: curr?.location_details?._id
            },
            pond: {
              name: curr?.pond_details?.title || "",
              _id: curr?.pond_details?._id
            },
            alias_name: curr?.title || curr?.code,
            St_id: curr?.display_code,
            block_St: curr?.block_shrimp_talk,
            start_date: curr?.subscription_start_date,
            end_date: curr?.subscription_end_date,
            _id: curr?._id
          };
          acc.push(obj);
          return acc;
        }, []);
        context.commit(
          "SET_CUSTOMER_POND_SHRIMPTALKS_BLOCK_DETAILS",
          BlockSTData
        );
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchCustomerPondShrimptalksBlockDataForDownload: async (context, params) => {
    try {
      const response = await $superAdminAxios.get(`admin/shrimp-talks`, {
        params
      });
      const BlockSTData = response.data.shrimp_talks.reduce((acc, curr) => {
        const obj = {
          location: {
            name: curr?.location_details?.name || "",
            _id: curr?.location_details?._id
          },
          pond: {
            name: curr?.pond_details?.title || "",
            _id: curr?.pond_details?._id
          },
          alias_name: curr?.title || curr?.code,
          St_id: curr?.display_code,
          block_St: curr?.block_shrimp_talk,
          start_date: curr?.subscription_start_date,
          end_date: curr?.subscription_end_date,
          _id: curr?._id
        };
        acc.push(obj);
        return acc;
      }, []);
      // context.commit("SET_CUSTOMER_POND_SHRIMPTALKS_BLOCK_DETAILS", BlockSTData);
      return BlockSTData;
    } catch (err) {
      console.log(err);
    }
  },
  fetchUserLocations: async function(context, params) {
    const response = await $superAdminAxios.get(
      `/admin/users/${params.userId}/locations`
    );
    // const setter = infinite_scroll ? "SET_APPEND_USERS_DATA" : "SET_USERS_DATA";
    // if (params.page === 1 && infinite_scroll) {
    //   context.commit(setter, []);
    // }
    const locationsData = params.noLocation
      ? [
          { name: "No Location", _id: "unAssigned" },
          ...response.data.user_locations
        ]
      : response.data.user_locations;
    context.commit("SET_USERS_LOCATIONS_DATA", locationsData);
    return locationsData;
  },
  fetchSuperAdminUsersList: async function(context) {
    const params = {};
    params.order_by = "first_name";
    params.order_type = "asc";
    params.get_all = true;
    const response = await $superAdminAxios.get(`/admin/super-admin`, {
      params
    });
    context.commit("SET_SUPER_ADMIN_USERS_DATA", response.data.super_admins);
    // return locationsData;
  },
  changeFilters(context, newFilter) {
    context.commit("SET_ADMIN_USER_FILTERS", newFilter);
    if (
      context.getters.getDoSidePanelHasValidUser &&
      context.getters.getIsSidePanelUserFiltered
    ) {
      context.dispatch("changeAdminUserSidePanelState", {
        ui_state: ADMIN_USER_UI_STATES.NO_USER_SELECTED
      });
    }
    context.dispatch("changeAdminUserSidePanelState", {
      ui_state: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS
    });
  },
  changeAdminUserSidePanelState(
    { dispatch, commit, getters },
    { ui_state, user }
  ) {
    const uiStateToDefaultUser = {
      [ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS]:
        getters.getFilteredSuperUserList[0],
      [ADMIN_USER_UI_STATES.CREATE_NEW_SUB_USER_DETAILS]: new User(),
      [ADMIN_USER_UI_STATES.NO_USER_SELECTED]: new User(),
      [ADMIN_USER_UI_STATES.EDIT_SUB_USER_DETAILS]: getters.getSidePanelUser,
      [ADMIN_USER_UI_STATES.UPDATE_SUB_USER_PASSWORD]: getters.getSidePanelUser
    };
    const isUsersListEmpty = !getters.getSuperAdminUsersData.length;
    if (isUsersListEmpty) {
      ui_state = ADMIN_USER_UI_STATES.NO_USER_SELECTED;
    }
    switch (ui_state) {
      case ADMIN_USER_UI_STATES.NO_USER_SELECTED:
        dispatch("changeSidePanelUser", uiStateToDefaultUser[ui_state]);
        dispatch(
          "changeSidePanelUIState",
          ADMIN_USER_UI_STATES.NO_USER_SELECTED
        );
        break;
      case ADMIN_USER_UI_STATES.CREATE_NEW_SUB_USER_DETAILS:
        dispatch("changeSidePanelUser", uiStateToDefaultUser[ui_state]);
        dispatch(
          "changeSidePanelUIState",
          ADMIN_USER_UI_STATES.CREATE_NEW_SUB_USER_DETAILS
        );
        break;
      default:
        dispatch("changeSidePanelUser", user || uiStateToDefaultUser[ui_state]);
        dispatch("changeSidePanelUIState", ui_state);
    }
  },
  changeSidePanelUIState(context, sidePanelUIState) {
    context.commit("SET_SIDE_PANEL_UI_STATE", sidePanelUIState);
    context.dispatch("setPermissionsArray", true);
  },
  changeSidePanelUser(context, user) {
    // console.log("user---", user);
    context.commit("SET_SIDE_PANEL_USER_DETAILS", user);
  },
  async restoreToIntialState(
    { getters, dispatch, commit },
    { params, sidePanelUIState, sidePanelUserId = "", shallFetchData = true }
  ) {
    await dispatch("fetchSuperAdminUsersList");
    dispatch("changeAdminUserSidePanelState", {
      ui_state: sidePanelUIState,
      user: getters.getFilteredUAMListObj[sidePanelUserId]
    });
  },
  async createSuperUser(context, userData) {
    const response = await $superAdminAxios.post(
      `/admin/super-admin`,
      userData
    );
    return response.data;
  },
  async updateSuperUser(context, { userId, payload }) {
    const response = await $superAdminAxios.patch(
      `/admin/super-admin/${userId}`,
      payload
    );
    return response.data;
  },
  clearStoreState: function(context) {
    context.commit("INIT_STORE_STATE");
  },
  async setPermissionsArray(context, data) {
    // console.log("context.state.permissions", context.state.permissions);
    var permissionsListCopy = [...context.state.permissions];
    permissionsListCopy = await permissionsListCopy.reduce((acc, curr) => {
      if (
        context.getters.getSidePanelState.userDetails.permissions.includes(
          curr.key
        )
      ) {
        curr.value = true;
      } else {
        curr.value = false;
      }
      acc.push(curr);
      return acc;
    }, []);
    context.commit("SET_PERMISSION", permissionsListCopy);
  },
  async getClaimData(context, params) {
    const response = await $superAdminAxios.get(`/warranty/claim-request`, {
      params
    });
    context.commit("SET_CLAIM_DATA", response.data.data);
    return response.data;
  },
  async fetchPartDetails(context, params) {
    const response = await $superAdminAxios.get(`/warranty/part-details`, {
      params
    });
    context.commit("SET_PARTS_DATA", response.data.data);
  },
  async handleSaveClaim(context, payload) {
    const response = await $superAdminAxios.post(
      `/warranty/claim-request`,
      payload
    );
    return response.data;
  },
  async handleUpdateClaim(context, { payload, claimId }) {
    const response = await $superAdminAxios.patch(
      `/warranty/claim-request/${claimId}`,
      payload
    );
    return response.data;
  },
  async fetchPondMotherDetails(context) {
    const response = await $superAdminAxios.get(`/admin/pond-mothers`, {
      params: {
        include: ["title", "code", "v1_code", "managed_by"],
        un_assigned_only: true,
        order_by: "utc_last_communicated_at",
        order_type: "desc"
      }
    });
    context.commit("SET_POND_MOTHER_DATA", response.data.pond_mothers);
  },
  async fetchMotorDetails(context) {
    const response = await $superAdminAxios.get(`/warranty/motor-ids`, {
      params: { un_assigned_only: true }
    });
    context.commit("SET_MOTOR_DATA", response.data.data);
  },
  async getApplicantsData(context) {
    const response = await $superAdminAxios.get(`admin/job/applicants`);
    context.commit("SET_APPLICANTS_DATA", response.data.jobs);
  },
  async getJobsData(context) {
    const response = await $superAdminAxios.get(`admin/jobs`);
    context.commit("SET_JOBS_DATA", response.data.jobs);
  },
  async careerDataAddAnsEdit(context, careerData) {
    if (careerData.action === "ADD") {
      const response = await $superAdminAxios.post(
        `admin/job`,
        careerData.payload
      );
      return response.data;
    } else {
      const response = await $superAdminAxios.patch(
        `admin/jobs/${careerData.career_id}`,
        careerData.payload
      );
      return response.data;
    }
  }
};
function getStatusBasedOnDate(device, device_type) {
  const date = device.utc_last_communicated_at;
  if (!date) {
    return false;
  }

  const oldDate = new Date(date);
  const currentDate = new Date();
  return (
    (currentDate - oldDate) / 1000 < getInactiveTimeToCheck(device, device_type)
  );
}
function getInactiveTimeToCheck(device, device_type) {
  let time = 1800;
  switch (device_type) {
    case "pond_mother":
      time = 1800;
      break;
    case "pond_guard":
      time = 1800;
      break;
    case "shrimp_talk":
      time = 900;
      break;
    case "gateway":
      time = 900 + (device.settings.device_stats_interval || 900);
      break;
    default:
      time = 900;
  }
  return time;
}
export default {
  namespaced: true,
  modules: { activityLog, newRelease, adminReleaseNotes, pmStats, inquiryLog },
  state,
  getters,
  mutations,
  actions
};
