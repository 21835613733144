<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erHighlightSearchComponent.vue
Description: This file contains component which is the useful for highlighting the text in the div tags based on the results generated by the fuse library
-->
<template>
  <div class="highlightSearchComponent">
    <template v-if="isKeyExists(highlightStrings, computedKey)">
      <span style="display: flex; width: max-content">
        <div
          v-for="(substr, index) in highlightStrings[computedKey][0]"
          :key="index"
          :class="{ highlight: substr.highlight }"
          v-html="substr.value"
        ></div>
      </span>
    </template>
    <template v-else class="withouthighlight_initial_letter">{{
      withoutHighlightStrings
    }}</template>
  </div>
</template>

<script>
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  name: "erHighlightSearchComponent",
  mixins: [userPreferenceMixin],
  props: {
    highlightStrings: {
      required: false,
      default: () => []
    },
    individualRowData: {
      required: false,
      default: () => {}
    },
    keyName: { default: "" },
    withoutHighlightStrings: {
      default: ""
    }
  },
  computed: {
    computedKey() {
      return this.keyName + "_" + this.withoutHighlightStrings;
    }
  },
  methods: {
    isKeyExists(item, value) {
      return this.$commonUtils.isKeyExists(item, value);
    }
  }
};
</script>

<style lang ="scss">
.highlightSearchComponent {
  width: max-content;
  white-space: break-spaces;
  .highlight {
    /* color: $primary-color; */
    /* background: #ddd; */
    display: inline-flex;
  }
  .withouthighlight_initial_letter {
    /* text-transform: capitalize; */
  }
}
</style>
