/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: feedBlower.js
Description: This file contains all the vuex store functions used in Feed Blower page
*/
import { cloneDeep } from "lodash";
import FeedBlowerService from "@/services/FeedBlowerService";
const state = {
  feedblowers: [],
  mapPmidPm: null
};
const getters = {
  getFeedBlowers: state => {
    return cloneDeep(state.feedblowers);
  },
  mapFeedBlowerIdToFeedBlowers: (state, getters) => {
    return getters.getFeedBlowers.reduce((accFb, fb) => {
      accFb[fb._id] = fb;
      return accFb;
    }, {});
  }
};

const mutations = {
  SET_FEED_BLOWER_DATA: function(state, feedblowers) {
    state.feedblowers = feedblowers;
  }
};
const actions = {
  fetchAllFeedBlowers: async function(context, queryParams) {
    const response = await FeedBlowerService.fetchAllFeedBlowers(queryParams);
    context.commit("SET_FEED_BLOWER_DATA", response.data.feed_blowers);
    return {
      message: response.data.message,
      totalPMs: response.data.total,
      page: response.data.page
    };
  },
  assignFeedBlowersToPond: async function(context, payload) {
    const { data, pond_id } = payload;
    const response = await FeedBlowerService.assignFeedBlowersToPond(
      pond_id,
      data
    );
    return response;
  },
  unAssignFeedBlowersFromPond: async function(context, payload) {
    const { devicedetails, pond_id } = payload;
    const data = devicedetails;
    const response = await FeedBlowerService.unAssignFeedBlowersFromPond(
      pond_id,
      data
    );
    return response;
  },
  addPmsToFeedBlower: async function(context, payload) {
    const { pondmother_ids, feed_blower_id } = payload;
    const data = pondmother_ids;
    const response = await FeedBlowerService.addPondMothersToFeedBlower(
      feed_blower_id,
      data
    );
    return response;
  },
  updateFeedBlowerPms: async function(context, payload) {
    const { pondmother_ids, feed_blower_id } = payload;
    const data = pondmother_ids;
    const response = await FeedBlowerService.updateFeedBlowerPms(
      feed_blower_id,
      data
    );
    return response;
  },
  removePmsFromFeedBlower: async function(context, payload) {
    const { pond_mother_ids, feed_blower_id } = payload;
    const data = pond_mother_ids;
    const response = await FeedBlowerService.removePondMothersFromFeedBlower(
      feed_blower_id,
      data
    );
    return response;
  },
  updateFeedBlowerDetails: async function(context, payload) {
    const response = await FeedBlowerService.updateFeedBlowerDetails(payload);
    return response;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
