/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: accountManager.js
Description: Contains all vuex store functions of accountManager
*/
import { $superAccountAxios } from "../../config/axios";
import {
  saveAccessTokenToLocalStorage,
  saveUserDetailsToLocalStorage,
  setImpersonation,
  setImpersonationBy
} from "@/middleware/auth";
import localStore from "@/utils/localStore";
import accountManagerService from "../../services/accountManagerService";
const state = {
  accountManagerProfile: {},
  accountManagerUsers: [],
  accountManagerSkrettingTechnician: [],
  skrettingTechnicianUsers: [],
  dealerSubscriptions: [],
  dealerSubscriptionReceivables: [],
  dealerCustomerSubscriptionReceivables: [],
  dealerCustomerPondShrmiptalks: [],
  dealerUsersAndDevices: [],
  dealerCustomerSubscriptionUnassignedCount: "",
  dealerCustomerSubscriptionUnassignedDetails: "",
  accountManagerExistingRemovedStCounts: {},
  accountManagerUsersAndDevicesRawData: [],
  accountManagerPms: [],
  accountManagerGws: [],
  accountManagerPgs: [],
  accountManagerSts: [],
  costumerDealerAndGroupDetails: {}
};
const getters = {
  getAccountManagerProfile: state => state.accountManagerProfile,
  getAccountManagerUsers: state => state.accountManagerUsers,
  getSkrettingTechnicians: state => state.accountManagerSkrettingTechnician,
  getSkrettingTechniciansUsers: state => state.skrettingTechnicianUsers,
  getDealerSubscriptions: state => state.dealerSubscriptions,
  getDealerSubscriptionReceivables: state =>
    state.dealerSubscriptionReceivables,
  getDealerCustomerSubscriptionReceivables: state =>
    state.dealerCustomerSubscriptionReceivables,
  getDealerCustomerSubscriptionUnassignedCount: state =>
    state.dealerCustomerSubscriptionUnassignedCount,
  getDealerCustomerSubscriptionUnassignedDetails: state =>
    state.dealerCustomerSubscriptionUnassignedDetails,
  getCustomerPondShrimptalks: state => state.dealerCustomerPondShrmiptalks,
  getCustomerPondExistingRemovedShrimptalks: state =>
    state.accountManagerExistingRemovedStCounts,
  getDealerUsersDevices: state => state.dealerUsersAndDevices,
  getAccountManagerUsersDevicesRawData: state =>
    state.accountManagerUsersAndDevicesRawData,
  getAccountManagerPms: state => state.accountManagerPms,
  getAccountManagerGws: state => state.accountManagerGws,
  getAccountManagerPgs: state => state.accountManagerPgs,
  getAccountManagerSts: state => state.accountManagerSts,
  getCustomersDealerAndGroupDetails: state =>
    state.costumerDealerAndGroupDetails
};
const mutations = {
  SET_USER_DATA(state, userData) {
    state.accountManagerProfile = userData;
  },
  SET_ALL_ASSIGNED_USERS_OF_ACCOUNT_MANAGER(state, accountManagerUsers) {
    state.accountManagerUsers = accountManagerUsers;
  },
  SET_ALL_TECHNICIANS_TO_ACCOUNT_MANAGER(state, skrettingTechnicians) {
    state.accountManagerSkrettingTechnician = skrettingTechnicians;
  },
  SET_ALL_USERS_TO_SKRETTING_TECHNICIAN(state, skrettingTechnicianUsers) {
    state.skrettingTechnicianUsers = skrettingTechnicianUsers;
  },
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.dealerSubscriptions = subscriptions;
  },
  SET_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerSubscriptionReceivables = subscriptionReceivables;
  },
  SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerCustomerSubscriptionReceivables = subscriptionReceivables;
  },
  SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT(state, count) {
    state.dealerCustomerSubscriptionUnassignedCount = count;
  },

  SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS(state, count) {
    state.dealerCustomerSubscriptionUnassignedDetails = count;
  },

  SET_CUSTOMER_POND_SHRIMPTALKS(state, pondShrimptalks) {
    state.dealerCustomerPondShrmiptalks = pondShrimptalks;
  },
  SET_EXISTING_REMOVED_SHRIMPTALKS(state, pondShrimptalks) {
    state.accountManagerExistingRemovedStCounts = pondShrimptalks;
  },
  SET_DEALER_USERS_DEVICES(state, dealerUsersAndDevices) {
    state.dealerUsersAndDevices = dealerUsersAndDevices;
  },
  SET_ACCOUNT_MANAGER_USERS_DEVICES_RAWDATA(
    state,
    accountManagerUsersAndDevicesRawData
  ) {
    state.accountManagerUsersAndDevicesRawData = accountManagerUsersAndDevicesRawData;
  },
  SET_ACCOUNT_MANAGER_GATEWAYS(state, accountManagerGws) {
    state.accountManagerGws = accountManagerGws;
  },
  SET_ACCOUNT_MANAGER_POND_MOTHERS(state, accountManagerPms) {
    state.accountManagerPms = accountManagerPms;
  },
  SET_ACCOUNT_MANAGER_POND_GUARDS(state, accountManagerPgs) {
    state.accountManagerPgs = accountManagerPgs;
  },
  SET_ACCOUNT_MANAGER_SHRIMP_TALKS(state, accountManagerSts) {
    state.accountManagerSts = accountManagerSts;
  },
  SET_DEALER_GROUP_DETAILS(state, costumerDealerAndGroupDetails) {
    state.costumerDealerAndGroupDetails = costumerDealerAndGroupDetails;
  }
};
const actions = {
  async accountManagerNotifyBackendOnLogout() {
    await $superAccountAxios.post(`/admin/users/logout`);
  },
  async loginAsAccountManager(context) {
    const userId = localStore.getItem(`ACCOUNT_MANAGER-id`);
    const credentials = localStore.getItem(`ACCOUNT_MANAGER-${userId}`);
    if (credentials === undefined) {
      throw new Error("No Keys Available");
    }
    $superAccountAxios.defaults.headers.common.Authorization =
      credentials.access_token;
    await context.dispatch("fetchUserProfile");
    const userProfile = context.getters.getAccountManagerProfile;
    saveUserDetailsToLocalStorage(userProfile);
    saveAccessTokenToLocalStorage(credentials);
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
  },
  fetchUserToken: async function(context, { userId, params }) {
    const response = await $superAccountAxios.get(
      `/account-manager/users/${userId}/signin`,
      {
        params
      }
    );
    // saving accesstoken and refresh in the local storage
    const { user_details, access_token, refresh_token } = response.data;
    saveUserDetailsToLocalStorage(user_details);
    saveAccessTokenToLocalStorage({
      access_token,
      refresh_token
    });
    // saving the user data from the response
    context.commit("user/SET_USER_DATA", response.data.user_details, {
      root: true
    });
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
    return response.data;
  },

  fetchDealerUsersAndDevices: async (context, { params }) => {
    // params.get_all=true,
    params.include_device_counts = true;
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superAccountAxios.get("account-manager/users", {
      params
    });
    context.commit("SET_DEALER_USERS_DEVICES", response.data.users);
    return response.data;
  },

  LoginAsUser: async (context, userId) => {
    setImpersonation(true);
    setImpersonationBy("ACCOUNT_MANAGER");
    await context.dispatch("fetchUserToken", { userId, params: {} });
  },
  async enableFeature(context, { user_id, payload }) {
    const response = await $superAccountAxios.patch(
      `/account-manager/users/${user_id}/device_config`,
      payload
    );
    return response;
  },
  fetchAllSkrettingTechnicians: async (context, params = {}) => {
    try {
      const response = await $superAccountAxios.get(
        `/account-manager/sub-users`,
        params
      );
      context.commit(
        "SET_ALL_TECHNICIANS_TO_ACCOUNT_MANAGER",
        response.data.sub_users
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  addSkrettingTechnicians: async (context, skrettingTechnician) => {
    console.log("skrettingTechnician", skrettingTechnician);
    const response = await $superAccountAxios.post(
      "account-manager/sub-users",
      skrettingTechnician
    );
    return response.data;
  },
  editSkrettingTechnicians: async (context, skrettingTechnician) => {
    const response = await $superAccountAxios.patch(
      `account-manager/sub-users/${skrettingTechnician._id}`,
      skrettingTechnician
    );
    return response.data;
  },
  deActivateSkrettingTechnician: async (context, skrettingTechnician) => {
    const response = await $superAccountAxios.patch(
      `account-manager/sub-users/${skrettingTechnician._id}`,
      skrettingTechnician
    );
    return response.data;
  },
  fetchUserProfile: async context => {
    const response = await $superAccountAxios.get("/profile");
    context.commit("SET_USER_DATA", response.data.user_details);
    const userDetails = response.data.profile;
    return userDetails;
  },
  fetchAllAssingedAccountUsers: async (context, params = {}) => {
    try {
      const response = await $superAccountAxios.get(
        "account-manager/users",
        params
      );
      context.commit(
        "SET_ALL_ASSIGNED_USERS_OF_ACCOUNT_MANAGER",
        response.data.users
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchAlreadyAssingedUsersFromSubuser: async (context, params = {}) => {
    const query = { get_all: true };
    try {
      const response = await $superAccountAxios.get(
        `account-manager/sub-user/${params.subuser_id}/users`,
        { params: query }
      );
      context.commit(
        "SET_ALL_USERS_TO_SKRETTING_TECHNICIAN",
        response.data.users
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  addUsersToSkrettingTechnician: async (context, payload) => {
    try {
      const response = await $superAccountAxios.post(
        "/account-manager/sub-user/users",
        payload
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  removeAssignedUsersFromSkrettingTechnician: async (context, payload) => {
    try {
      const response = await $superAccountAxios.delete(
        "/account-manager/sub-user/users",
        { data: payload }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchAllSubscriptions: async (context, query) => {
    const response = await accountManagerService.fetchAllSubscriptions(
      "/dealer/shrimp-talk-subscription-plans",
      {
        query
      }
    );
    context.commit("SET_SUBSCRIPTIONS", response.data.subscription_plans);
    return response;
  },
  fetchAllSubscriptionReceivables: async (context, query) => {
    const response = await accountManagerService.fetchSubscriptionReceivables(
      query
    );

    context.commit(
      "SET_SUBSCRIPTION_RECEIVABLES",
      response.data.shrimptalk_subscription_receivables
    );
    return response;
  },
  fetchCustomerSubscriptionReceivables: async (context, params) => {
    try {
      const response = await accountManagerService.fetchCustomerSubscriptionReceivables(
        params.id,
        params.query
      );

      context.commit(
        "SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES",
        response.data.data
      );
      context.commit("SET_DEALER_GROUP_DETAILS", {
        dealer_name: response.data.dealer_name,
        group_name: response.data.group_name
      });

      context.commit(
        "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_COUNT",
        response.data.unassigned_devices_with_out_subscription_count +
          response.data.assigned_devices_with_out_subscription_count
      );

      context.commit(
        "SET_CUSTOMER_SUBSCRIPTION_UNASSINED_DETAILS",
        response.data.unassigned_devices_with_subscription
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchCustomerPondShrimptalks: async (context, params) => {
    try {
      const response = await accountManagerService.fetchCustomerPondShrimptalks(
        params.id,
        params.query
      );

      context.commit("SET_CUSTOMER_POND_SHRIMPTALKS", response.data.data);
      context.commit("SET_EXISTING_REMOVED_SHRIMPTALKS", response.data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchAccountManagerUsersAndDevicesRawData: async (context, params) => {
    const response = await $superAccountAxios.get(
      "dealer/download-user-shrimp-talk-subscription-receivables",
      {
        params,
        responseType: "blob"
      }
    );
    context.commit("SET_ACCOUNT_MANAGER_USERS_DEVICES_RAWDATA", response.data);
    return response;
  },
  fetchAllManagerDevices: async (
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) => {
    const urlForDevice = {
      gateway: "/account-manager/gateways",
      pond_mother: "/account-manager/pond-mothers",
      pond_guard: "/account-manager/pond-guards",
      shrimp_talk: "/account-manager/shrimp-talks"
    };
    const settersStr = {
      gateway: "SET_ACCOUNT_MANAGER_GATEWAYS",
      pond_mother: "SET_ACCOUNT_MANAGER_POND_MOTHERS",
      pond_guard: "SET_ACCOUNT_MANAGER_POND_GUARDS",
      shrimp_talk: "SET_ACCOUNT_MANAGER_SHRIMP_TALKS"
    };

    const responseDeviceKey = {
      gateway: "gateways",
      pond_mother: "pond_mothers",
      pond_guard: "pond_guards",
      shrimp_talk: "shrimp_talks"
    };
    params.include_user_details = true;
    params.order_by = "code";
    params.order_type = "asc";
    // params.order_type = "asc";
    // params.un_assigned_only = true;
    // params.get_all = true;

    // const response = await $superDealerAxios.get(urlForDevice[device_type], {
    //   params
    // });
    const response = await accountManagerService.fetchAllManagerDevicesService(
      urlForDevice[device_type],
      params
    );
    const setter = settersStr[device_type];
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
